import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CompanyId } from '../../../models/company-id'
import { CountryId } from '../../../models/country-id'
import { TENANT_ID } from '../tenantId'

export const getAvonMexico = () => ({
  tenantId: TENANT_ID.AVON_MEXICO,
  country: {
    alpha2Code: CountryId.MEXICO,
    alpha3Code: 'MEX',
    language: 'es',
  },
  company: {
    name: 'AVON',
    id: CompanyId.AVON,
    logout: {
      buttonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/logout`,
      externalLink: true,
    },
  },
  header: {
    hasRegisterButtonLink: true,
    registerButtonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/mx/auto`,
    hasLogout: true,
  },
  profile: {
    hasNationalityState: true,
    shouldDisplayThreeNames: true,
    shouldDisplaySendLinkButton: true,
  },
  digitalStore: {
    shouldDisplayRFC: true,
  },
  shouldDisplayStructureCodeLevels: true,
  shouldDisplayDocumentAttachments: false,
  showEditButtonDetails: true,
  showColumnsRegistrantAndIntroducerSeller: true,
  structureCodeLevels: [
    {
      level: 2,
      label: 'division',
      defaultLabel: 'Division',
    },
    {
      level: 3,
      label: 'zone',
      defaultLabel: 'Zone',
    },
    {
      level: 4,
      label: 'unitCode',
      defaultLabel: 'Unit/Group Code',
    },
  ],
  redirectUrlToLogin: {
    url: async () => getConfigsMFEFirebase('HOSTED_UI_AUTHENTICATION_URL'),
    clientId: async () => getConfigsMFEFirebase('CLIENT_ID_COGNITO'),
  },
})
