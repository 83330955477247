export default (theme) => ({
  prospectTableListLoadingContainer: {
    position: 'absolute',
    top: '55%',
    '@media (max-width: 769px)': {
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '@media (min-width: 770px)': {
      right: 'calc(50% - 120px)',
    },
  },
  prospectTableListEmptyListContainer: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    '@media (max-width: 769px)': {
      transform: 'translate(-50%, -50%)',
    },
    color: theme.palette.grey[400],
  },
  prospectTableListError: {
    position: 'absolute',
    '@media (max-width: 769px)': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '@media (min-width: 770px)': {
      top: '45%',
      left: '50%',
    },
  },
})
