export default {
  nationalityAfghan: 'Afgano',
  nationalityAlbanian: 'Albanés',
  nationalityAmerican: 'Norteamericano',
  nationalityAndorran: 'Andorrano',
  nationalityAngolan: 'Angoleño',
  nationalityAnguillan: 'Anguillan',
  nationalityAntartic: 'Antártico',
  nationalityAntiguans: 'Antillano',
  nationalityArab: 'Árabe',
  nationalityArubean: 'Arubiano',
  nationalityAlgerian: 'Argelino',
  nationalityArgentinean: 'Argentino',
  nationalityArmenian: 'Armenio',
  nationalityAustralian: 'Australiano',
  nationalityAustrian: 'Austriaco',
  nationalityAzerbaijani: 'Azerbaiyano',
  nationalityBahamian: 'Las Bahamas',
  nationalityBahraini: 'Bahrein',
  nationalityBangladeshi: 'Bangladesh',
  nationalityBarbadian: 'Barbandense',
  nationalityBatswana: 'Betchuano',
  nationalityBelarusian: 'Bielo ruso',
  nationalityBelgian: 'Belga',
  nationalityBelizean: 'Beliceño',
  nationalityBeninese: 'Beninés',
  nationalityBermudian: 'Bermudés',
  nationalityBhutanese: 'Butanés',
  nationalityBolivian: 'Boliviano',
  nationalityBosnian: 'Bosnio',
  nationalityBouvetIsland: 'Ilhas Bouvet',
  nationalityBrazilian: 'Brasileño',
  nationalityBritish: 'Británico',
  nationalityBruneian: 'Bruneano',
  nationalityBulgarian: 'Búlgaro',
  nationalityBurkinabe: 'Burkina Faso',
  nationalityBurmese: 'Myanmarense',
  nationalityBurundian: 'Burundiano',
  nationalityCambodian: 'Camboyano',
  nationalityCameroonian: 'Camerunés',
  nationalityCanadian: 'Canadiense',
  nationalityCapeVerdean: 'Caboverdiano',
  nationalityCentralAfrican: 'Centrafricano',
  nationalityChadian: 'Chadiano',
  nationalityChilean: 'Chileno',
  nationalityChinese: 'Chino',
  nationalityColombian: 'Colombiano',
  nationalityComoran: 'Comorano',
  nationalityCongolese: 'Congolés',
  nationalityCookIsland: 'Cookiano',
  nationalityCostaRican: 'Costarricense',
  nationalityCorean: 'Coreano',
  nationalityCroatian: 'Croata',
  nationalityCuban: 'Cubano',
  nationalityCypriot: 'Cipriano',
  nationalityCzech: 'Checo',
  nationalityDanish: 'Danés',
  nationalityDjibouti: 'Djibutiano',
  nationalityDominican: 'Dominicano',
  nationalityDutch: 'Holandés',
  nationalityEastTimorese: 'Timor Oriental',
  nationalityEcuadorean: 'Ecuatoriano',
  nationalityEgyptian: 'Egipcio',
  nationalityEquatorialGuinean: 'Guineano',
  nationalityEritrean: 'Eritreu',
  nationalityEstonian: 'Estonio',
  nationalityEthiopian: 'Etíope',
  nationalityFijian: 'Fiyiano',
  nationalityFilipino: 'Filipino',
  nationalityFinnish: 'Finlandés',
  nationalityFrench: 'Francés',
  nationalityGabonese: 'Gabonés',
  nationalityGambian: 'Gambiano',
  nationalityGeorgian: 'Georgiano',
  nationalityGerman: 'Alemán',
  nationalityGibraltar: 'Gibraltarian',
  nationalityGhanaian: 'Ghanés',
  nationalityGreek: 'Griego',
  nationalityGrenadian: 'Granadino',
  nationalityGuatemalan: 'Guatemalteco',
  nationalityGuineaBissauan: 'Guiné-Bissau',
  nationalityGuinean: 'Guineano',
  nationalityGuyanese: 'Guayanés',
  nationalityHaitian: 'Haitiano',
  nationalityHonduran: 'Hondureño',
  nationalityHongKong: 'Hong konguês',
  nationalityHungarian: 'Húngaro',
  nationalityIKiribati: 'Kiribatiano',
  nationalityIcelander: 'Islandés',
  nationalityIndian: 'Indú',
  nationalityIndonesian: 'Indonesio',
  nationalityIranian: 'Iraní',
  nationalityIraqi: 'Iraquí',
  nationalityIrish: 'Irlandés',
  nationalityIsraeli: 'Israelita',
  nationalityItalian: 'Italiano',
  nationalityIvorian: 'Costa de Marfil',
  nationalityJamaican: 'Jamaicano',
  nationalityJapanese: 'Japonés',
  nationalityJordanian: 'Jordano',
  nationalityKazakhstani: 'Kazaja',
  nationalityKenyan: 'De Kenia',
  nationalityKittianAndNevisian: 'De São Cristovão e Nevis',
  nationalityKuwaiti: 'Kuwaitiano',
  nationalityKyrgyz: 'Kirguises',
  nationalityLaotian: 'Laosiano',
  nationalityLatvian: 'Letón',
  nationalityLebanese: 'Libanés',
  nationalityLiberian: 'Liberiano',
  nationalityLibyan: 'Libio',
  nationalityLiechtensteiner: 'Liechtensteiniano',
  nationalityLithuanian: 'Lituano',
  nationalityLuxembourger: 'Luxemburgués',
  nationalityMacedonian: 'Macedonio',
  nationalityMalagasy: 'Madagascar',
  nationalityMalawian: 'Malawiano',
  nationalityMalaysian: 'Malayo',
  nationalityMaldivian: 'Maldivo',
  nationalityMalian: 'Malí',
  nationalityMaltese: 'Maltés',
  nationalityMarshallese: 'Marshallino',
  nationalityMauritanian: 'Mauritano',
  nationalityMauritian: 'Mauriciano',
  nationalityMexican: 'Mexicano',
  nationalityMicronesian: 'Micronesiano',
  nationalityMoldovan: 'Moldavo',
  nationalityMonacan: 'Monegasco',
  nationalityMontserrat: 'Montserrat',
  nationalityMongolian: 'Mongol',
  nationalityMoroccan: 'Marroquí',
  nationalityMosotho: 'Lesotense',
  nationalityMozambican: 'Mozambiqueño',
  nationalityNamibian: 'Namibio',
  nationalityNauruan: 'Nauruano',
  nationalityNepalese: 'Nepalés',
  nationalityNewZealander: 'Neozelandés',
  nationalityNiVanuatu: 'Vanuatuense',
  nationalityNicaraguan: 'Nicaragüense',
  nationalityNigerian: 'Nigeriano',
  nationalityNigerien: 'Níger',
  nationalityNiueIsland: 'Niuean',
  nationalityNorwegian: 'Noruego',
  nationalityNorfolkIsland: 'Norfolkiano',
  nationalityOmani: 'Omaní',
  nationalityPakistani: 'Pakistaní',
  nationalityPalauan: 'Palauano',
  nationalityPanamanian: 'Panameño',
  nationalityPapuaNewGuinean: 'Papú',
  nationalityParaguayan: 'Paraguayo',
  nationalityPeruvian: 'Peruano',
  nationalityPolish: 'Polaco',
  nationalityPortuguese: 'Portugués',
  nationalityQatari: 'Qatari',
  nationalityRomanian: 'Rumano',
  nationalityRussian: 'Ruso',
  nationalityRwandan: 'Ruandés',
  nationalitySaintLucian: 'Santalucense',
  nationalitySalvadoran: 'Salvadoreño',
  nationalitySamoan: 'Samoano',
  nationalitySanMarinese: 'Samarines',
  nationalitySanVicent: 'Son vicentino',
  nationalitySaintHelenians: 'Santa Elena',
  nationalitySaoTomean: 'Son tomense',
  nationalitySaudi: 'Saudita',
  nationalitySenegalese: 'Senegalés',
  nationalitySeychellois: 'Seychellois',
  nationalitySierraLeonean: 'Leonés',
  nationalitySingaporean: 'Singapur',
  nationalitySlovakian: 'Eslovaco',
  nationalitySlovenian: 'Esloveno',
  nationalitySolomonIslander: 'Salomónica',
  nationalitySomali: 'Somalí',
  nationalitySouthAfrican: 'Sudafricano',
  nationalitySpanish: 'Español',
  nationalitySriLankan: 'Cingalés',
  nationalitySudanese: 'Sudanés',
  nationalitySurinamer: 'Surinamés',
  nationalityStateless: 'Apátrida',
  nationalitySwazi: 'Swazilandia',
  nationalitySwedish: 'Sueco',
  nationalitySwiss: 'Suizo',
  nationalitySyrian: 'Sírio',
  nationalityTaiwanese: 'Taiwanés',
  nationalityTajik: 'Tayiko',
  nationalityTanzanian: 'Tanzano',
  nationalityThai: 'Tailandés',
  nationalityTogolese: 'Togolés',
  nationalityTokelau: 'Toquelauano',
  nationalityTongan: 'Tongano',
  nationalityTrinidadianOrTobagonian: 'Trinitaria',
  nationalityTunisian: 'Tunecino',
  nationalityTurkish: 'Turco',
  nationalityTurkmens: 'Turcomano',
  nationalityTuvaluan: 'Tuvaluano',
  nationalityTurksCaicos: 'Turco-caicense',
  nationalityUgandan: 'Ugandés',
  nationalityUkrainian: 'Ucraniano',
  nationalityUsMinorIslands: 'Ilhas Minor Outlying',
  nationalityUruguayan: 'Uruguayo',
  nationalityUzbekistani: 'Uzbeko',
  nationalityVaticano: 'Vaticano',
  nationalityVenezuelan: 'Venezolano',
  nationalityVietnamese: 'Vietnamita',
  nationalityWallisFutunaIsland: 'Wallisiano',
  nationalityWesternSahara: 'Sara occidental',
  nationalityYemenite: 'Yemenite',
  nationalityYugoslavian: 'Yugoslavo',
  nationalityZairense: 'Zaire',
  nationalityZambian: 'Zambiano',
  nationalityZimbabwean: 'Zimbabuense',
}
