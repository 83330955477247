import default_es_nationality_messages from '../es/nationalityMessages'
import default_es_status_messages from '../es/statusMessages'
import default_es_messages from '../es/messages'
import digitalStoreMessages from './digitalStoreMessages'
import subStatusMessages from './subStatusMessages'

export default {
  ...default_es_messages,
  ...default_es_status_messages,
  ...default_es_nationality_messages,
  ...digitalStoreMessages,
  ...subStatusMessages,
  countdownMessage: 'Espere {countdown} segundos',
  resendLinkButtonText: 'Enviar Enlace',
  sendingLinkMessage: 'Enlace de reenvío',
  linkSentSuccessfully: 'Enlace enviado con éxito',
  errorMessage: 'Parece que algo no salió como se esperaba. Espere un momento y vuelva a intentarlo.',
  allRegisters: 'Todos los registros',
  noResultsOnCommercialStructure: 'No se encontraron resultados en su zona',
  resultsOutsideStructure: 'Resultados fuera de la zona',
  consultantCode: 'Codigo CN',
  contact: 'Contacto',
  contactNumber: 'Teléfono',
  mobileNumber: 'Teléfono Móvil',
  whatsappNumber: 'Whatsapp',
  indicator: 'Recomendadora',
  suggestedForStart: 'En Proceso',
  dropouts: 'Rechazados',
  registerOn: 'Registro en',
  level4: 'Colonia',
  neighborhood: 'Colonia',
  number: 'Número Exterior',
  complement: 'Número Interior',
  city: 'Municipio o Delegación',
  middleName: 'Apellido Paterno',
  lastName: 'Apellido Materno',
  digitalStoreUrl: 'www.avon.com.mx/consultoria/',
  rfc: 'RFC',
  registerAllocatedOnCommercialStructure: 'Registro asignado en zona: ',
  consultantRegistration: 'Registro de Consultor',
  personCode: 'Registro',
  selfRegistration: 'Autonombramiento',
  registrant: 'Captura de Nombramiento',
  filterByCreditLine: 'Tipo de crédito',
  filterByOrigin: 'Origen',
  restart: 'Ocultar Campo',
  homeTime: 'Días activo',
  commercialStructure: 'Empresaria',
  status: 'Status',
  waitingFirstOrder: 'Registrados',
  beginnings: 'Inicios / Reinicios',
  registerStatus: 'Status de Registro',
  paymentPlan: 'Tipo de Crédito',
  registerStatusCollaborador: 'Motivo',
  registerButtonLinkLabel: 'Nuevo Registro',
  zone: 'Zona',
  timeLeftLessThanHour: 'menos de una hora',
  oneHourLeft: '1 hora',
  hoursLeft: '{hours}h',
  oneDayLeft: '1 día',
  daysLeft: '{days} días',
}
