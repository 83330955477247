import implement from 'implement-js'
import { PathsInterface } from '../../interfaces/paths'

const Paths = {
  getScopedPath: () => '/',

  consultantDetailsPath: (location, personId) => `${location.pathname}/show/${personId}`,
}

export default implement(PathsInterface)(Paths)
