import React from 'react'
import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles'
import { ThemeProvider } from '@naturacosmeticos/natds-react'
import { Provider } from '@naturacosmeticos/natds-web'
import { getTheme } from './utils/theme'

const generateClassName = createGenerateClassName({
  productionPrefix: 'naturaReportsStyle',
  disableGlobal: true,
  seed: 'naturaReportsStyle',
})

const ThemeWrapper = ({ children }) => (
  <StylesProvider generateClassName={generateClassName}>
    <ThemeProvider theme={getTheme()}>
      <Provider theme={getTheme()}>
        {children}
      </Provider>
    </ThemeProvider>
  </StylesProvider>

  )

export default ThemeWrapper
