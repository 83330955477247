import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import { Spacing } from '@naturacosmeticos/natds-web'
import IconButton from '../../../components/Buttons/IconButton'
import Loading from '../../../components/Loading'
// eslint-disable-next-line import/no-cycle
import ErrorArea from '../../../components/Error/ErrorInfo'
// eslint-disable-next-line import/no-cycle
import { useNotification } from '../../../components/Notifications/Notifications'
import ScreenContainer from '../../../components/ScreenContainer/ScreenContainer'
import { snakeToCamel } from '../../../helpers/common'
import { useResponsiveness } from '../../../hooks/responsive'
import { useGetProspectDetailsSWR } from '../../../hooks/swrServices/useGetProspectDetailsSWR'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'
import { changeGroupPeopleService } from '../../../services/people'
import CancelRegistrationCard from './CancelRegistrationCard/CancelRegistrationCard'
import { EmailButton } from './EmailButton/EmailButton'
import AddressCard from './AddressCard'
import ChangeGroupCard from './ChangeGroupCard'
import CollaboratorRegistrationCard from './CollaboratorRegistrationCard'
import ConsultantRegistrationCard from './ConsultantRegistrationCard/ConsultantRegistrationCard'
import ContactCard from './ContactCard'
import DocumentsCard from './DocumentsCard/DocumentsCard'
import EditButton from './EditButton/EditButton'
import { ExpirationTokenDate } from './ExpirationTokenDate/ExpirationTokenDate'
import { MyBusinessButton } from './MyBusinessButton/MyBusinessButton'
import PersonalDataCard from './PersonalDataCard'
import SendLinkButton from './SendLink/SendLinkButton'
import { useStyles } from './prospect-details.styles'
import Reprocess from './reprocess'

export default () => {
  const [isLoadingGroup, setIsLoadingGroup] = useState(false)
  const { id, status } = useParams()
  const {
 userData, isLoading: loading, hasError, reload,
} = useGetProspectDetailsSWR(id)
  const { isMobile, isDesktop } = useResponsiveness()
  const history = useHistory()
  const subStatus = userData?.substatus
  const classes = useStyles()
  const { notify } = useNotification()
  const { configs } = useTenantConfigs()
  const showEditButton = userData?.isEditable && configs?.showEditButtonDetails
  const EditButtonLinkedToProspectForm = () => (showEditButton ? <EditButton personId={id} display="block" /> : null)
  const showMyBusinessButton = configs?.showMyBusinessButton && isDesktop
  const MyBusinessButtonToProspectForm = () => (showMyBusinessButton ? <MyBusinessButton consultantCode={userData?.code} variant={showEditButton ? 'outlined' : 'contained'} /> : null)
  const ButtonEmail = () => (isDesktop ? <EmailButton email={userData?.email} /> : null)
  const shouldBreakLine = showEditButton || showMyBusinessButton

  const handleChangeGroup = async (newGroup) => {
    try {
      setIsLoadingGroup(true)
      const { functionId } = userData?.roles.find((role) => role.roleId === configs.roles.cb.roleId && role.functionId === configs.roles.cb.functionId && role.endDate === null) || {}

      const originStructureCode = userData.structureCodeLevels.find((level) => level.value === userData.commercialStructure).value
      const originStructureLevelId = userData.structureCodeLevels.find((level) => level.value === userData.commercialStructure).level

      const payload = {
        destinationStructureCode: newGroup?.code,
        destinationStructureLevelId: newGroup?.levelId,
        functionId,
        originStructureCode,
        originStructureLevelId,
        personCode: userData?.code,
        personId: id,
        headers: {
          businessmodelid: 1,
          countryid: configs.country?.alpha2Code,
          companyid: configs.company.id,
          'user-id': 1,
          'user-name': 'selfRegistration',
        },
      }

      await changeGroupPeopleService(payload)
      notify(<FormattedMessage id="changeGroupFeedback" defaultMessage="Group changed successfully" />)
    } catch (error) {
      notify(<FormattedMessage id="changeGroupFeedbackError" defaultMessage="Error changing group" />)
      console.error(error)
    } finally {
      await reload()
      setIsLoadingGroup(false)
    }
  }

  return (
    <ScreenContainer
      hasDrawer
      headerButtons={(
        <>
          {shouldBreakLine && <div className={classes.breakLine} />}
          <ButtonEmail />
          <Reprocess subStatus={subStatus} />
          <MyBusinessButtonToProspectForm />
          <EditButtonLinkedToProspectForm />
        </>
      )}
      title={
        isMobile ? (
          userData?.name
        ) : (
          <FormattedMessage id={snakeToCamel(status)} />
        )
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {hasError ? (
        <ErrorArea />
      ) : (loading || isLoadingGroup) ? (
        <Loading />
      ) : (
        <>
          <ExpirationTokenDate />

          {isDesktop && (
            <AppBar
              elevation={1}
              data-testid="prospectNameAppBar"
              color="inherit"
              classes={{ root: classes.appBar }}
              position="static"
            >
              <Toolbar variant="dense" className={classes.prospectToolbar}>
                <div className={classes.headerStart}>
                  <IconButton edge="start" aria-label="back" onClick={() => history.goBack()}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography className={classes.prospectName} variant="h6" color="inherit">
                    {userData?.name}
                  </Typography>
                </div>

                <Spacing display="flex">
                  <ButtonEmail />
                  <Reprocess subStatus={subStatus} />
                  <MyBusinessButtonToProspectForm />
                  <EditButtonLinkedToProspectForm />
                  {configs?.profile?.shouldDisplaySendLinkButton && (
                    <SendLinkButton subStatus={subStatus} classNameBtn={classes.propectButton} />
                  )}
                </Spacing>
              </Toolbar>
            </AppBar>
          )}
          <Grid container spacing={1} classes={{ root: classes.gridContainer }}>
            <PersonalDataCard userData={userData} />
            <ContactCard userData={userData} personId={id} />
            <DocumentsCard userData={userData} cardTitleId="documents" cardTitleDefaultMessage="Documents" />
            <AddressCard
              cardTitleId="mailingAddress"
              cardTitleDefaultMessage="Mailing Address"
              personId={userData.personId}
              addressData={userData.addresses ? userData.addresses.MAILING : null}
            />
            <CollaboratorRegistrationCard userData={userData} />
            <ConsultantRegistrationCard userData={userData} />
            <ChangeGroupCard userData={userData} onChangeGroup={handleChangeGroup} />
            <CancelRegistrationCard userData={userData} />
          </Grid>
        </>
      )}
    </ScreenContainer>
  )
}
