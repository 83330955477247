import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  containerPaper: {
    minWidth: 350,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'calc(100% - 32px)',
      margin: 16,
    },
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '5fr auto',
    fontFamily: 'Roboto',
  },
  iconButton: {
    justifySelf: 'end',
    alignSelf: 'start',
    color: theme.palette.grey[500],
    padding: 8,
    marginLeft: 12,
    width: 40,
    height: 40,
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0',
    },
  },
  content: {
    dividers: {
      margin: '0 16px',
      padding: '16px 0',
    },
    fontFamily: 'Roboto',
  },
  actions: {
    padding: '8px 16px',
    justifyContent: 'unset',
  },
}))
