import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import i18nMessages from './locales/i18nMessages'
import { getAppLocale } from './helpers/mfeLocaleConfig'
import Loading from './components/Loading'

import { setLocale } from './redux/locale/reducers'

export default ({ children }) => {
  const [loadingLocale, setLoadingLocale] = useState(true)
  const locale = useSelector((state) => state.locale.locale)
  const dispatch = useDispatch()
  const getSystemLocale = async () => {
    setLoadingLocale(true)
    const locale = await getAppLocale()
    dispatch(setLocale(locale))
    setLoadingLocale(false)
  }

  useEffect(() => {
    getSystemLocale()
  }, [])

  if (loadingLocale) return <Loading />

  return (
    <IntlProvider locale={locale} messages={i18nMessages[locale]}>
      {children}
    </IntlProvider>
  )
}
