import { useEffect, useState } from 'react'

export const useResponsiveness = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setDeviceWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return {
    isMobile: deviceWidth <= 768,
    isDesktop: deviceWidth > 768,
  }
}
