import React from 'react'
import implement from 'implement-js'
import { ProvidersInterface } from '../../interfaces/providers'
import { useCognito } from '../../../hooks/useCognito'
import Loading from '../../../components/Loading'

const Providers = {
  AuthenticationProvider: ({ children }) => {
    const { loading } = useCognito()

    const render = () => {
      if (loading) {
        return <Loading />
      }

      return children
    }

    return render()
  },
}

export default implement(ProvidersInterface)(Providers)
