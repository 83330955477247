import React from 'react'
import implement from 'implement-js'
import { LayoutInterface } from '../../interfaces/layout'
// eslint-disable-next-line import/no-cycle
import Header from '../../../components/Header/Header'
import { useResponsiveness } from '../../../hooks/responsive'

const Layout = {
  Header: ({ children, ...props }) => (useResponsiveness().isMobile ? (
    <Header {...props} hasBackButton={false}>
      {children}
    </Header>
  ) : null),

  Drawer: () => null,
}

export default implement(LayoutInterface)(Layout)
