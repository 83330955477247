import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FilterPeopleTableOptions } from '../people-table-options'

const strategies = {
  [FilterPeopleTableOptions.ALL]: () => (
    <FormattedMessage
      id="noRegisters"
      defaultMessage="There are no registers"
    />
  ),
  [FilterPeopleTableOptions.HAS_CONTACTED]: () => (
    <FormattedMessage
      id="noRegistersContacted"
      defaultMessage="There are no already contacted registers"
    />
  ),
  [FilterPeopleTableOptions.NEW_REGISTERS]: () => (
    <FormattedMessage
      id="noNewRegisters"
      defaultMessage="There are no new registers"
    />
  ),
}

export const getEmptyListText = (filterPeopleTableOption) => {
  const strategy = strategies[filterPeopleTableOption]

  if (strategy) {
    return strategy()
  }

  throw new Error('You must implement getEmptyListText strategy')
}
