import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import Header from '../../components/Header/Header'
import RegisterReportsList from '../../components/AppMenu/RegisterReportList'
import { useStyles } from './RegisterReportMenu.styles'
import { useResponsiveness } from '../../hooks/responsive'
import { SUGGESTED_FOR_START_URL } from '../../routes/RestrictedRoutes'

const RegisterReportMenu = () => {
  const classes = useStyles()
  const { isDesktop } = useResponsiveness()
  const history = useHistory()

  useEffect(() => {
    if (isDesktop) {
      history.push(SUGGESTED_FOR_START_URL)
    }
  }, [history, isDesktop])

  return (
    <>
      <Header
        hasBackButton={false}
        hideNewRegisterButton
        hasElevation
        title={
          <FormattedMessage id="myRegisters" defaultMessage="My Registers" />
        }
      />
      <Paper className={classes.paperRoot} square>
        <Typography
          color="textPrimary"
          variant="subtitle2"
          component="h2"
          classes={{ root: classes.subtitle }}
        >
          <FormattedMessage
            id="registerReports"
            defaultMessage="Register reports"
            classes={classes}
          />
        </Typography>
        <RegisterReportsList />
      </Paper>
    </>
  )
}

export default RegisterReportMenu
