import { STORAGE_COMPANY_KEY, STORAGE_COUNTRY_KEY } from '../../helpers/auth'
import { getObjectInStorage, getStringInStorage } from '../../hooks/useStorage'
import { getConfigsMFEFirebase } from '../../helpers/mfeConfisFirebase'

export const validateCompany = (company) => {
  const localStorageCompany = Number(getStringInStorage(STORAGE_COMPANY_KEY))
  return company && company === localStorageCompany
}

export const validateCountry = (country) => {
  const localStorageCountry = getStringInStorage(STORAGE_COUNTRY_KEY)
  return country && country === localStorageCountry
}

export const validateCountryAndCompany = (country, company) => (validateCountry(country) && validateCompany(company))

export const validateDesignEloIsActive = () => {
  const countryKeyEmbedReports = getObjectInStorage('@naturaReports:auth')?.country
  const companyKeyEmbedReports = getObjectInStorage('@naturaReports:auth')?.country

  const countryKey = getStringInStorage(STORAGE_COUNTRY_KEY) || countryKeyEmbedReports
  const companyKey = Number(getStringInStorage(STORAGE_COMPANY_KEY)) || companyKeyEmbedReports

  if (countryKey && companyKey) {
    return getConfigsMFEFirebase('ENABLE_THEME_ELO')
  }
}
