import { sortFilterOptionsByI18nNames } from '../../../models/filterOptions'

export const getCreditLineFilterOptions = (intl) => {
  const unsortedOptions = [
    {
      id: 1,
      name: intl.formatMessage({ id: 'creditLinePostPaid', defaultMessage: 'Credit' }),
    },
    {
      id: 2,
      name: intl.formatMessage({ id: 'prePaid', defaultMessage: 'Pre paid' }),
    },
  ]
  return sortFilterOptionsByI18nNames(unsortedOptions)
}
