import { FormattedMessage } from 'react-intl'
import React from 'react'
import { useStyles } from './CancelRegistrationButtonWithDialog.styles'

export default function CancelRegistrationTitleDialog({ name }) {
  const classes = useStyles()

  return (
    <>
      <h4 className={classes.title}>
        <FormattedMessage
          id="cancelRegistrationDialogTitle"
          defaultMessage="Cancel Registration"
        />
      </h4>
      <span className={classes.message}>
        <FormattedMessage
          id="cancelRegistrationDialogMessage"
          defaultMessage="Are you sure you want to cancel the registration of <strong>{name}</strong>?"
          values={{
              name,
              strong: (text) => <strong>{text}</strong>,
            }}
        />
      </span>
    </>
  )
}
