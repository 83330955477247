export const subStatusNameEnum = {
  1: 'EMAIL_EXISTENT',
  2: 'PENDING_COMMERCIAL_STRUCTURE_NOT_COVERED',
  3: 'QUARANTINE',
  4: 'NAME_VALIDATION',
  5: 'CREDIT_VALIDATION',
  6: 'APPROVED_SELLER',
  7: 'ACTIVE_SELLER',
  8: 'PENDING_DATA',
  9: 'PENDING_DOCUMENTATION',
  10: 'RENUNCIATION_NATURA',
  11: 'RENUNCIATION_CANDIDATE',
  12: 'PENDING_ALLOCATE',
  13: 'PENDING_CSC_APPROVAL',
  14: 'PROCESSING',
  15: 'ADDRESS_VALIDATION_FAIL',
  16: 'CEP_VALIDATION_FAIL',
  18: 'PENDING_GR_APPROVAL',
  19: 'PENDING_GV_APPROVAL',
  20: 'ATTACHMENT_VALIDATION_FAIL',
  21: 'PENDING_REGISTRATION_APPROVAL',
  22: 'PENDING_PREPAID',
  23: 'ACTIVE_DEBT',
  24: 'REJECTED_BY_GV',
  25: 'RESTRICTED_ADDRESS',
  26: 'PENDING_ADDRESS_REVIEW',
  27: 'PENDING_RESTART_REVIEW',
  28: 'REJECTED_BY_CSC',
  29: 'REJECTED_BY_REGISTER_TEAM',
  30: 'PENDING_EMAIL_VERIFICATION',
  31: 'REJECTED_BY_GR',
  32: 'CREATING_PERSON',
  33: 'CEASED',
  34: 'CN_ACTIVE',
  35: 'TERM_OF_ACCEPTANCE',
  36: 'RESTRICTION_EMAIL',
  37: 'OPEN_TITLE',
  38: 'RESTRICTED_ROLE',
  39: 'DOCUMENT_POSTED',
  40: 'CLEAR_SALE_VALIDATION_FAIL',
  41: 'ACTIVE_CN_PENDING_CSC',
  42: 'RESTRICTION_TELEPHONE',
  45: 'APPROVED_BY_GR', //
  46: 'APPROVED_BY_GV',
  47: 'SYSTEM_FAIL',
  48: 'EXISTENT_ROLE',
  49: 'PENDING_MANUAL_ASSIGNMENT',
  50: 'INTEGRATION_ERROR_WITH_GERA',
  51: 'ALLOCATION_STRUCTURE_ERROR',
  52: 'BRM_BAD_PARAMETERS',
  53: 'RETRIEVE_CYCLE_ERROR',
  54: 'ESTABLISHMENT_NOT_FOUND',
  55: 'REJECTED_BY_BLOCKLIST',
  56: 'FAILURE_CEASE_GERA',
  57: 'PENDING_ADDRESS_BLOCKLIST_REVIEW',
  58: 'REJECTED_WITH_REASON',
  59: 'PENDING_APPROVAL',
  60: 'EXISTENT_EMAIL_ON_GERA',
  61: 'MANUALLY_APPROVED',
  62: 'ERROR_COMPLETE_DATA_ON_BRM',
  63: 'GERA_DEBT_SERVICE_ERROR',
  64: 'PENDING_CREDIT_ANALYSIS',
  65: 'CREDIT_RATING_ERROR',
  66: 'ADDRESS_INCOMPLETE',
  67: 'FINAL_DATA_VALIDATION',
  68: 'WITHOUT_ADDRESS',
  69: 'REJECTED_BY_MINIMAL_PERIOD_AS_CEASED',
  70: 'ATTACHMENT_MISSING',
  71: 'ATTACHMENT_VALIDATION_SUCCESS',
  72: 'WORKFLOW_EXECUTION_ERROR',
  73: 'PENDING_DIGITAL_SPACE',
  74: 'MISSING_CONFIRMATION_CODE',
  75: 'AGREEMENTS_SERVICE_ERROR',
  76: 'INVALID_ADDRESSES',
  77: 'SITIDATA_SERVICE_ERROR',
  78: 'INVALID_MAILING_ADDRESS',
  79: 'INVALID_DELIVERY_ADDRESS',
  80: 'PENDING_DIGITAL_SIGNATURE',
  81: 'PENDING_IDENTITY_VALIDATION',
  82: 'REJECTED_BY_AGS',
  83: 'REJECTED_BY_EQUIFAX',
  84: 'PENDING_MARITAL_STATUS',
  85: 'PENDING_NAME',
  86: 'PENDING_CELLPHONE',
  87: 'PENDING_CEP',
  88: 'PENDING_EMAIL',
  89: 'PENDING_BIRTHDAY',
  90: 'PENDING_GENDER',
  91: 'PENDING_ADDRESS_DETAILS',
  92: 'PENDING_ACCEPT_DS',
  93: 'PENDING_CREATE_DS',
  94: 'PENDING_UPLOAD_DOC',
  95: 'PENDING_PASSWORD_CREATION',
  96: 'PENDING_WORKFLOW',
  97: 'REJECTED_DOCS',
  98: 'PENDING_NOMINATION', // recommendation
  99: 'PENDING_TOKEN',
  100: 'PENDING_EQUIFAX_VALIDATION',
  101: 'PENDING_SOURCE_OF_FUNDS',
}
