import React from 'react'
import Link from '@material-ui/core/Link'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { snakeToCamel } from '../../../../helpers/common'
import defaultMessages from '../../../../locales/en/messages'
import useStyles from './Document.styles'

const AttachmentList = ({ attachments }) => {
  const classes = useStyles()
  const intl = useIntl()

  if (!attachments || (attachments && attachments.length === 0)) {
    return ' - '
  }

  const getDocumentType = (documentType) => {
    if (!documentType) return ''

    const type = snakeToCamel(documentType)
    return intl.formatMessage({
      id: type,
      defaultMessage: defaultMessages[type],
    })
  }

  return (
    <div className={classes.anchorsContainer}>
      {attachments.map(({ type, url, documentType }) => {
        if (type) {
          return (
            <Link
              key={type}
              classes={{ root: classes.anchor }}
              color="inherit"
              href={url}
              target="_blank"
              underline="none"
              variant="body2"
            >
              <FormattedMessage
                id={snakeToCamel(type)}
                defaultMessage={defaultMessages[snakeToCamel(type)]}
                values={{ doc: getDocumentType(documentType) }}
              />
            </Link>
          )
        }
        return null
      })}
    </div>
  )
}

AttachmentList.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()),
}

AttachmentList.defaultProps = {
  attachments: [],
}

export default AttachmentList
