import { getBffApiGatewayConnection } from './config'
import { getBaseHeaders } from './configs/request-headers'

// eslint-disable-next-line consistent-return
const mapDocumentFilter = ({ document }) => {
  if (document) {
    return {
      documentType: document.type,
      documentNumber: document.number,
    }
  }
}

// eslint-disable-next-line consistent-return
const mapGroupFilter = ({ group }) => {
  if (group) {
    return {
      structureCode: group.code,
      structureLevelId: group.levelId,
    }
  }
}

const mapSubStatusFilter = ({ subStatus }) => {
  if (subStatus) {
    return { subStatus }
  }

  return {}
}

const mapStatusFilter = ({ status }) => {
  if (status) {
    return { status }
  }

  return {}
}

const mapOriginFilter = ({ origin }) => {
  if (origin) {
    return { origin }
  }
  return {}
}

const mapCreditLineFilter = ({ creditLine }) => {
  if (creditLine) {
    return { creditLine }
  }

  return {}
}

const mapRestartFilter = ({ registrationType }) => {
  if (registrationType) {
    return { registrationType: registrationType.value }
  }

  return {}
}

const filtersMap = (filters) => {
  if (!filters) {
    return null
  }

  const mappedDocumentFilter = mapDocumentFilter(filters)

  if (mappedDocumentFilter) {
    return mappedDocumentFilter
  }

  return {
    ...mapGroupFilter(filters),
    ...mapStatusFilter(filters),
    ...mapSubStatusFilter(filters),
    ...mapCreditLineFilter(filters),
    ...mapOriginFilter(filters),
    ...mapRestartFilter(filters),
  }
}

export const getPeopleByProspectStatusService = async ({
  prospectStatus,
  hasContacted,
  filters,
  sort,
  limit,
  offset,
  cancelToken,
}) => {
  if (prospectStatus === undefined) {
    throw new Error('Invalid Prospect Status')
  }
  const connection = await getBffApiGatewayConnection()

  const response = await connection.get(
    `/accounts/register-reports/v1/people/status/${prospectStatus}`,
    {
      cancelToken,
      params: {
        hasContacted,
        sort,
        limit,
        offset,
        ...filtersMap(filters),
      },
    },
  )

  return response.data
}

export const getPersonDetailsService = async (personId) => {
  const connection = await getBffApiGatewayConnection()
  const response = await connection.get(
    `/accounts/register-reports/v1/people/${personId}`,
  )
  return response.data
}

export const hasContactMadeService = async (people) => {
  if (!people || people.length <= 0) {
    throw new Error('Invalid prospect status')
  }

  const connection = await getBffApiGatewayConnection()
  const response = await connection.put(
    '/accounts/register-reports/v1/people/contacts',
    people,
  )
  return response.data
}

export const reprocessRegistrationService = async (personId) => {
  const connection = await getBffApiGatewayConnection()

  await connection.post(`/accounts/register-reports/v1/people/reprocess/${personId}`)
}

export const sendLinkService = async ({ personid, companyid, countryid }) => {
  const customHeaders = getBaseHeaders()
  const connection = await getBffApiGatewayConnection(customHeaders)

  await connection.post('/accounts/register-reports/v1/link/send', {}, {
    headers: { companyid, countryid, personid },
  })
}

export const changeGroupPeopleService = async ({
  destinationStructureCode,
  destinationStructureLevelId,
  functionId,
  originStructureCode,
  originStructureLevelId,
  personCode,
  personId,
  headers,
}) => {
  const customHeaders = getBaseHeaders()
  const connection = await getBffApiGatewayConnection(customHeaders)

  const payload = {
    destinationStructureCode,
    destinationStructureLevelId,
    function: functionId,
    originStructureCode: Number(originStructureCode),
    originStructureLevelId,
    personId,
    role: 1,
  }

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(Number(personCode))) {
    payload.personCode = Number(personCode)
  }

  await connection.post('/accounts/register-reports/v1/change-group', payload, { headers })
}

export const getPersonAgreements = async (personId) => {
  const connection = await getBffApiGatewayConnection()
  const response = await connection.get(
    `/accounts/register-reports/v1/people/${personId}/agreements`,
  )
  return response.data
}

export const getPersonAgreementExpirationDate = async (personId, termId, termVersion) => {
  const connection = await getBffApiGatewayConnection()
  const response = await connection.get(
    `/accounts/register-reports/v1/people/${personId}/expiration-date/${termId}/${termVersion}`,
  )
  return response.data
}

export const renunciatePeopleRole = async ({
 renunciateReason, personId, personRoleId, headers,
}) => {
  const customHeaders = getBaseHeaders()
  const connection = await getBffApiGatewayConnection(customHeaders)
  const payload = { renunciateReason }
  await connection.patch(`/accounts/register-reports/v1/people/${personId}/role/renunciate/${personRoleId}`, payload, { headers })
}
