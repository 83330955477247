import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import Sagas from './sagas'

import reducers from './combinedReducers'
import { LOCAL_STORAGE_PREFIX } from '../Constants'

const basename = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}scopedPath`) || '/'
export const history = createBrowserHistory({ basename })
const sagaMiddleware = createSagaMiddleware()

const config = {
  key: LOCAL_STORAGE_PREFIX,
  storage,
  blacklist: ['prospectTable', 'router'],
}

const persistedReducers = persistReducer(config, reducers(history))

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
  : compose

const middlewares = [routerMiddleware(history), sagaMiddleware]

const store = createStore(
  persistedReducers,
  composeEnhancers(applyMiddleware(...middlewares)),
)
const persistor = persistStore(store)

sagaMiddleware.run(Sagas)
export { persistor, store }
