import useSWR from 'swr'
import { getBffApiGatewayConnection } from '../../services/config'

export const makeGetDocumentsSWR = () => '/accounts/register-reports/v1/documents'

export function useGetDocumentsSWR(initialData = []) {
  const { data, error } = useSWR(makeGetDocumentsSWR(), async (url) => {
    const connection = await getBffApiGatewayConnection()
    const response = await connection.get(url)
    return response.data.documents
  })

  return {
    documents: data || initialData,
    isLoading: !error && !data,
    hasError: Boolean(error),
  }
}
