import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/styles'

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
}

const Loading = ({ classes }) => (
  <div className={classes.loadingContainer}>
    <CircularProgress />
  </div>
)

export default withStyles(styles)(Loading)
