import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import Dialog from '../../../../../../components/Dialog'
import Button from '../../../../../../components/Buttons/Button'

import { useStyles } from './ApprovalButtonWithDialog.styles'
import { useAxiosHttpRequest } from '../../../../../../hooks/useAxiosHttpRequest'
import { getBffApiGatewayConnection } from '../../../../../../services/config'
import { makeGetProspectDetailsKey } from '../../../../../../hooks/swrServices/useGetProspectDetailsSWR'

export const ApprovalButtonWithDialog = ({ name, personId, personRoleId }) => {
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const {
    state: { isLoading },
    doRequest: doApprovePersonRolePatch,
  } = useAxiosHttpRequest(
    {
      url: `/accounts/register-reports/v1/people/${personId}/person-roles/${personRoleId}/approve`,
      method: 'patch',
    },
    {
      axiosClient: getBffApiGatewayConnection(),
    },
  )

  const handleApprovePersonRole = () => {
    doApprovePersonRolePatch(undefined, {
      onSuccessCallback: () => {
        setIsDialogOpen(false)
      },
      mutateOnSuccess: [
        {
          key: makeGetProspectDetailsKey(personId),
        },
      ],
    })
  }

  function renderTitle() {
    return (
      <>
        <h4 className={classes.title}>
          <FormattedMessage
            id="approveRegistrationDialogTitle"
            defaultMessage="Approve Registration"
          />
        </h4>
        <span className={classes.message}>
          <FormattedMessage
            id="approveRegistrationDialogMessage"
            defaultMessage="Are you sure you want to approve the registration of <strong>{name}</strong>"
            values={{
              name,
              strong: (text) => <strong>{text}</strong>,
            }}
          />
        </span>
      </>
    )
  }

  function renderActions() {
    return (
      <div className={classes.actionsContainer}>
        <Button
          variant="contained"
          color="error"
          onClick={() => setIsDialogOpen(false)}
          data-testid="decide-later-button"
        >
          <FormattedMessage id="decideLater" defaultMessage="Decide Later" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApprovePersonRole}
          loading={isLoading}
          data-testid="yes-im-sure-button"
        >
          <FormattedMessage id="yesImSure" defaultMessage="Yes, I'm sure" />
        </Button>
      </div>
    )
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsDialogOpen(true)}
      >
        <FormattedMessage id="approve" defaultMessage="Approve" />
      </Button>
      <Dialog
        data-testid="approval-dialog"
        open={isDialogOpen}
        title={renderTitle()}
        actions={renderActions()}
        dividers={false}
      />
    </>
  )
}
