import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Checkbox } from '@naturacosmeticos/natds-web'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useStyles } from './TableList.styles'

const CustomTableHead = ({
  onOrder,
  onSelectAll,
  orderBy,
  orderDirection,
  columns,
  data,
  disableCheckboxes,
}) => {
  const classes = useStyles()
  const { filters } = useSelector(({ prospectTable }) => prospectTable)
  return (
    <TableHead classes={{ root: classes.tableHead }}>
      <TableRow>
        <TableCell padding="checkbox">
          {onSelectAll && (
            <Checkbox
              color="primary"
              className={classes.checkbox}
              checked={data.length > 0 ? data.every((entry) => entry.selected) : false}
              onChange={onSelectAll}
              inputProps={{ 'aria-label': 'select all registers' }}
              disabled={disableCheckboxes}
            />
          )}
        </TableCell>
        {columns.map((column) => (
          <TableCell
            width={column.width}
            classes={{ root: classes.tableCell }}
            key={column.labelId}
          >
            {column.ordered && !filters.document ? (
              <TableSortLabel
                active={orderBy === column.dataIndex}
                direction={orderDirection}
                onClick={() => onOrder(column)}
              >
                <FormattedMessage
                  id={column.labelId}
                  defaultMessage={column.labelDefaultMessage}
                />
              </TableSortLabel>
            ) : (
              <FormattedMessage
                id={column.labelId}
                defaultMessage={column.labelDefaultMessage}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

CustomTableHead.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      labelId: PropTypes.string,
      labelDefaultMessage: PropTypes.string,
      dataIndex: PropTypes.string,
      width: PropTypes.string,
      render: PropTypes.func,
      ordered: PropTypes.bool,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderBy: PropTypes.string.isRequired,
  orderDirection: PropTypes.string.isRequired,
  onOrder: PropTypes.func.isRequired,
}

export default CustomTableHead
