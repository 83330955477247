import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import CustomCardLog from './CustomCardLog'

const useStyles = makeStyles({
  listItem: {
    cursor: 'inherit',
  },
  fontSizeStrong: {
    fontSize: '14px',
  },
})

const CustomListItem = ({
 fieldId, fieldDefaultMessage, data, hideWhenThereIsNotData, showCardLog = false, dataCardLog = {},
}) => {
  const classes = useStyles()

  if (hideWhenThereIsNotData && !data) {
    return <></>
  }

  return (
    <>
      <ListItem className={classes.listItem} data-testid={`${fieldId}-custom-list-item`}>
        <ListItemText
          primary={[
            <strong className={classes.fontSizeStrong} key="list_item_label">
              <FormattedMessage defaultMessage={fieldDefaultMessage} id={fieldId} />
              :
              {' '}
            </strong>,
            <span className={classes.fontSizeStrong} data-testid={`${fieldId}-data`} key="list_item_data">
              {data || '-'}
            </span>,
          ]}
        />
      </ListItem>
      {showCardLog && (
        <CustomCardLog
          fieldId={fieldId}
          dataCardLog={dataCardLog}
        />
      )}
    </>
  )
}

export default CustomListItem
