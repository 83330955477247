import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
`

export const Icon = ({
  name, width = 24, height = 24, ...props
}) => {
  // eslint-disable-next-line import/no-dynamic-require
  let imageSource = require(`../assets/icons/${name}.svg`)

  imageSource = imageSource.default || imageSource

  return (
    <Container {...props} width={width} height={height}>
      <img alt={name} src={imageSource} />
    </Container>
  )
}
