import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import CustomCard from './Card'
import CustomListItem from './CustomListItem'
import Button from '../../../components/Buttons/Button'
import FilterByGroup, { DefaultFilterByGroupOption } from '../../../components/Filter/filterOptions/FilterByGroup'
import { hasRole } from '../../../helpers/hasRole'

import { useTenantConfigs } from '../../../hooks/useTenantConfigs/useTenantConfigs'

const useStyles = makeStyles({
  containerButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 16,
  },
  changeButton: {
    minWidth: 'auto',
    whiteSpace: 'nowrap',
  },
  changeGroupNotify: {
    fontFamily: 'Roboto',
    fontSize: '11px',
  },
})

const ChangeGroupCard = ({ userData, onChangeGroup }) => {
  const [selectedGroup, setSelectedGroup] = useState(DefaultFilterByGroupOption('selectGroup'))

  const { configs } = useTenantConfigs()
  const { groupsFilter: { groups }, user: { data } } = useSelector(({ user }) => user)

  const classes = useStyles()

  const functionIdGN = configs?.roles?.gn.functionId
  const roleIdGN = configs?.roles?.gn.roleId

  const isGN = hasRole({
    roles: data?.roles || [],
    functionId: functionIdGN,
    roleId: roleIdGN,
  })

  const hasChangeGroup = configs?.canChangeGroup?.includes(userData?.statusInfo)

  const showChangeGroupButton = configs?.showChangeGroupButton && isGN && hasChangeGroup
  const handleChangeGroup = () => {
    onChangeGroup(selectedGroup)
  }

  if (!showChangeGroupButton) {
    return null
  }

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard titleMessageId="registrationManagement" titleDefaultMessage="Registration management">
        <List dense>
          <CustomListItem
            fieldId="changeGroupLabel"
            fieldDefaultMessage="Group"
            data={userData?.commercialStructure}
          />
        </List>

        <FilterByGroup
          groups={groups}
          canFilterByGroup
          selectedGroup={selectedGroup}
          onSelectGroup={setSelectedGroup}
          label={<FormattedMessage id="changeConsultantGroup" defaultMessage="Change Consultant Group" />}
        />

        <div className={classes.containerButton}>
          <div className={classes.changeGroupNotify}>
            <FormattedMessage id="changeGroupNotify" defaultMessage="*Group change may take a few minutes to reflect on the screen." />
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.changeButton}
            disabled={!selectedGroup?.levelId}
            onClick={handleChangeGroup}
          >
            <FormattedMessage id="changeGroup" defaultMessage="Change group" />
          </Button>
        </div>

      </CustomCard>
    </Grid>
  )
}

export default ChangeGroupCard
