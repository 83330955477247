import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import TabsMenu from '../../components/TabsMenu/TabsMenu'
import ScreenContainer from '../../components/ScreenContainer/ScreenContainer'
import { mapStatusFromUrlToPageDetails } from '../../models/filterOptions'
import useGlobal from '../../hooks/globalState/useGlobal'
import { useToggle } from '../../hooks/useToggle'
import { useUserCountry } from '../../hooks/useUserCountry'
import { CountryId } from '../../models/country-id'
import { FilterPeopleTableOptions, PeopleTable } from '../../containers/people-table'

export const RegistersList = () => {
  const { status } = useParams()
  const registerStatus = mapStatusFromUrlToPageDetails(status)
  const [, globalActions] = useGlobal()
  const { CHECK_FEATURE, ENABLE_ALL_REGISTERS_TAB } = useToggle()
  const { country } = useUserCountry()

  useEffect(() => {
    if (CHECK_FEATURE) {
      // eslint-disable-next-line no-console
      console.log('This alert is activated by a remote toggle, enjoy the freedom.')
    }
  }, [CHECK_FEATURE])

  useEffect(() => {
    globalActions.setProspectStatusScreen(registerStatus.id)

    if (registerStatus.showStatusFilter) {
      globalActions.setShowStatusFilter(true)
    }

    return registerStatus.showStatusFilter ? () => globalActions.setShowStatusFilter(false) : undefined
  }, [globalActions, registerStatus.id, registerStatus.showStatusFilter])

  const tabs = getTabsByCountry({ country, registerStatusId: registerStatus.id, isAllRegistersTabToggleEnabled: ENABLE_ALL_REGISTERS_TAB })

  return (
    <ScreenContainer
      hasDrawer
      title={(
        <FormattedMessage
          id={registerStatus.translationId}
          defaultMessage={registerStatus.defaultStatusName}
        />
      )}
    >
      <TabsMenu tabs={tabs} />
    </ScreenContainer>
  )
}

function getTabsByCountry({ country, registerStatusId, isAllRegistersTabToggleEnabled }) {
  const defaultTabs = [
    {
      labelId: 'newRegisters',
      labelDefaultMessage: 'New Registers',
      Component: () => (
        <PeopleTable prospectStatus={registerStatusId} filterPeopleBy={FilterPeopleTableOptions.NEW_REGISTERS} />
      ),
    },
    {
      labelId: 'alreadyMadeContact',
      labelDefaultMessage: 'Already Made Contact',
      Component: () => (
        <PeopleTable prospectStatus={registerStatusId} filterPeopleBy={FilterPeopleTableOptions.HAS_CONTACTED} />
      ),
    },
  ]

  if (allRegistersTabEnabledForBrazil({ isAllRegistersTabToggleEnabled, countryId: country })) {
    defaultTabs.unshift(
      {
        labelId: 'allRegisters',
        labelDefaultMessage: 'All Registers',
        Component: () => (
          <PeopleTable prospectStatus={registerStatusId} filterPeopleBy={FilterPeopleTableOptions.ALL} />
        ),
      },
    )
  }

  return defaultTabs

  function allRegistersTabEnabledForBrazil({ isAllRegistersTabToggleEnabled, countryId }) {
    return isAllRegistersTabToggleEnabled && countryId === CountryId.BRAZIL
  }
}

export default RegistersList
