import axios from 'axios'
import { getToken } from '../helpers/auth'
import { getConfigsMFEFirebase } from '../helpers/mfeConfisFirebase'
import { getCountryConfigurationByStorage } from '../hooks/useTenantConfigs'
import { BffApiGatewayHeaders } from './configs/request-headers'

export const getRequestCancelSource = () => {
  const { cancel, token } = axios.CancelToken.source()

  return {
    cancelRequests: cancel,
    cancelToken: token,
  }
}

const getAxiosClient = async ({ baseURL, getHeaders }) => {
  const token = getToken()

  const headers = await getHeaders
  const params = {
    baseURL,
    headers,
  }

  if (token) {
    params.headers.Authorization = `Bearer ${token}`
  }
  return axios.create(params)
}

export const getBffApiGatewayConnection = async (getHeaders, type = 'gateway') => {
  const configs = await getCountryConfigurationByStorage()
  const registerReportsBffUrl = await configs[type]?.getRegisterReportsBffUrl?.()

  return getAxiosClient({
    baseURL: registerReportsBffUrl || process.env.REACT_APP_REGISTER_REPORTS_BFF_URL,
    getHeaders: getHeaders || BffApiGatewayHeaders.connection(type),
  })
}

export const getBffApiGatewayAuthenticatedConnection = async (getHeaders, type = 'gateway') => {
  const registerReportsBffAuthenticatedUrl = await getConfigsMFEFirebase('REGISTER_REPORTS_BFF_AUTHENTICATED_URL')

  return getAxiosClient({
    baseURL: registerReportsBffAuthenticatedUrl || process.env.REACT_APP_REGISTER_REPORTS_BFF_AUTHENTICATED_URL,
    getHeaders: getHeaders || BffApiGatewayHeaders.connection(type),
  })
}

export const getGlobalAccountUrl = () => ({
  url: process.env.REACT_APP_GLOBAL_ACCOUNT_URL,
})

export const getV1ConsultancyUrl = () => ({
  url: process.env.REACT_APP_V1_CONSULTANCY_URL,
})
