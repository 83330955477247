import makeStyles from '@material-ui/core/styles/makeStyles'
import { appMode } from '../../adapter'

const drawerWidth = 281

export const useStyles = makeStyles((theme) => {
  const {
    drawerFiltersPaper,
  } = appMode().styles(theme)

  return {

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    paper: {
      width: drawerWidth,
      borderRight: theme.spacing(0),
      ...drawerFiltersPaper,
    },
    drawerDesktopContainer: theme.mixins.toolbar,
    divider: {
      backgroundColor: theme.palette.complementary.highlight,
      opacity: 0.12,
    },
    drawerContent: {
      flexGrow: 1,
      overflow: 'auto',
    },
    drawerFooter: {
      justifyContent: 'center',
      display: 'flex',
      flexShrink: 0,
      padding: theme.spacing(2),
      boxShadow: '0 -6px 10px 0 rgba(0, 0, 0, 0.14)',
    },
  }
})
