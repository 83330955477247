import { createReducer } from 'reduxsauce'
import TYPES from '../types'

const INITIAL_STATE = {
  groupsFilter: {
    canFilterByGroup: false,
    groups: [],
  },
  user: {
    loading: false,
    status: 'INITIAL',
    data: null,
    error: null,
  },
}

const setGroupsFilterActionHandler = (state = INITIAL_STATE, action) => ({
  ...state,
  groupsFilter: action.groupsFilter,
})

export const handleGetUserStart = (state) => ({
  ...state,
  user: {
    ...INITIAL_STATE.user,
    loading: true,
  },
})

export const handleGetUserSuccess = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    loading: false,
    status: 'SUCCESS',
    data: action.payload.user,
  },
})

export const handleGetUserFail = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    loading: false,
    status: 'FAIL',
    data: null,
    error: action.payload.error,
  },
})

export default createReducer(INITIAL_STATE, {
  [TYPES.USER.SET_FILTER_GROUPS]: setGroupsFilterActionHandler,
  [TYPES.USER.GET_USER_START]: handleGetUserStart,
  [TYPES.USER.GET_USER_SUCCESS]: handleGetUserSuccess,
  [TYPES.USER.GET_USER_FAIL]: handleGetUserFail,
})
