import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import { useStyles } from './ExpirationTokenDate.styles'
import { NotesCard } from '../../../../components/NotesCard/NotesCard'
import { getPersonAgreementExpirationDate, getPersonAgreements } from '../../../../services/people'
import { STORAGE_COMPANY_KEY } from '../../../../helpers/auth'
import { companyNameById } from '../../../../models/company-name-by-id'

export function ExpirationTokenDate() {
  const [isLoading, setIsLoading] = useState(true)
  const [termExpiration, setTermExpiration] = useState(null)

  const classes = useStyles()
  const history = useHistory()
  const intl = useIntl()
  const { id, status } = useParams()

  const { configs } = useTenantConfigs()

  useEffect(() => {
    if (!configs?.showExpirationTokenNotesCard || (!termExpiration && !isLoading)) return

    const button = document.getElementById('#sendCodeButton')

    function handleClickButton() {
      history.push(`/${status}/edit/${id}`)
    }

    if (button) {
      button.addEventListener('click', handleClickButton)
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClickButton)
      }
    }
  }, [configs?.showExpirationTokenNotesCard, termExpiration, isLoading])

  useEffect(() => {
    async function loadData() {
      try {
        const agreements = await getPersonAgreements(id)
        const companyNumber = Number(localStorage.getItem(STORAGE_COMPANY_KEY))
        const company = companyNameById[companyNumber].toLowerCase()
        const foundAgreement = agreements.find((agreement) => agreement?.termVersion?.term?.products?.includes(company))

        if (foundAgreement) {
          const expirationDateResponse = await getPersonAgreementExpirationDate(id, foundAgreement.termVersion.term.id, foundAgreement.termVersion.id)

          if (expirationDateResponse?.expireDate) {
            const isInFuture = moment(expirationDateResponse.expireDate).isAfter(moment())

            if (isInFuture) {
              setTermExpiration(new Date(expirationDateResponse.expireDate))
            }
          }
        }

        setIsLoading(false)
      } catch {
        setIsLoading(false)
      }
    }

    loadData()
  }, [])

  const timeLeft = useMemo(() => {
     if (!termExpiration) return ''
    const now = moment()
    const expiration = moment(termExpiration)
    const duration = moment.duration(expiration.diff(now))

    if (duration.asHours() < 1) {
      return intl.formatMessage({ id: 'timeLeftLessThanHour', defaultMessage: 'less than an hour' }) // Menos de uma hora
    } if (duration.asHours() < 24) {
      const hours = Math.floor(duration.asHours())
      return intl.formatMessage({ id: 'hoursLeft', defaultMessage: '{hours}h' }, { hours })
    }
      const days = Math.floor(duration.asDays())
      if (days === 1) {
        return intl.formatMessage({ id: 'oneDayLeft', defaultMessage: '1 day' }) // 1 dia
      }
        return intl.formatMessage({ id: 'daysLeft', defaultMessage: '{days} days' }, { days })
  }, [termExpiration])

  if (!configs?.showExpirationTokenNotesCard || !termExpiration) {
    return null
  }

  return (
    <div className={classes.container}>
      <NotesCard
        title="Código de verificação pendente."
        description={`Seu código de verificação expira em ${timeLeft}. Durante este período utilize o mesmo código. <button id='#sendCodeButton'>Clique aqui</button> se precisar reenviar`}
        type="info"
        icon="outlined-alert-info"
      />
    </div>
  )
}
