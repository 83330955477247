import { TENANT_ID } from './useTenantConfigs/tenantId'
import { useToggle } from './useToggle'

export const useMapExcelToggles = () => {
    const { SHOW_EXPORT_BUTTON_AVON_AR, SHOW_EXPORT_BUTTON_AVON_MX, SHOW_EXPORT_BUTTON_NATURA_PE } = useToggle()
    const mapExcelToggles = {
        [TENANT_ID.AVON_ARGENTINA]: SHOW_EXPORT_BUTTON_AVON_AR,
        [TENANT_ID.AVON_MEXICO]: SHOW_EXPORT_BUTTON_AVON_MX,
        [TENANT_ID.NATURA_PERU]: SHOW_EXPORT_BUTTON_NATURA_PE,
      }
     return { mapExcelToggles }
}
