const SET_LOCALE = 'SET_LOCALE'

const initialState = {
  locale: 'en',
}

const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      }
    default:
      return state
  }
}

export const setLocale = (locale) => ({
  type: SET_LOCALE,
  payload: {
    locale,
  },
})

export default localeReducer
