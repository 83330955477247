import { createReducer } from 'reduxsauce'
import TYPES from '../types'

const { AUTH } = TYPES
export const INITIAL_STATE = {
  data: {
    ssoToken: null,
    accessToken: null,
    expiresIn: null,
    status: 'INITIAL',
    error: null,
    loading: false,
  },
}

export const handleGetAcessTokenStart = (state) => ({
  ...state,
  data: {
    ...state.data,
    status: 'INITIAL',
    loading: true,
  },
})

export const handleGetAcessTokenSuccess = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    error: null,
    loading: false,
    status: 'SUCCESS',
    accessToken: action.payload.accessToken,
    expiresIn: action.payload.expiresIn,
    ssoToken: action.payload.ssoToken,
  },
})

export const handleGetAcessTokenFail = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    accessToken: null,
    expiresIn: null,
    status: 'FAIL',
    loading: false,
    error: action.payload.error,
  },
})

export default createReducer(INITIAL_STATE, {
  [AUTH.GET_ACCESS_TOKEN_START]: handleGetAcessTokenStart,
  [AUTH.GET_ACCESS_TOKEN_SUCCESS]: handleGetAcessTokenSuccess,
  [AUTH.GET_ACCESS_TOKEN_FAIL]: handleGetAcessTokenFail,
})
