import React from 'react'
import { IconButton } from '@naturacosmeticos/natds-web'
import makeStyles from '@material-ui/core/styles/makeStyles'

const classes = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderRadius: '50%',
  },
}))

export default (props) => (
  // eslint-disable-next-line react/destructuring-assignment
  <IconButton {...props} classes={{ root: classes().root, ...props.classes }} />
)
