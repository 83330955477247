import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CompanyId } from '../../../models/company-id'
import { CountryId } from '../../../models/country-id'
import { TENANT_ID } from '../tenantId'

export const getNaturaEcuador = () => ({
  tenantId: TENANT_ID.NATURA_ECUADOR,
  showEditButtonDetails: false,
  country: {
    alpha2Code: CountryId.ECUADOR,
    alpha3Code: 'ECU',
    language: 'es',
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: '/login?country=ECU&company=NATURA',
      externalLink: false,
    },
  },
  header: {
    hasRegisterButtonLink: true,
    registerButtonLinkHref: `${process.env.REACT_APP_REGISTRATION_FV_V3}`,
    hasLogout: true,
  },
  profile: {
    editContactWithV3: true,
    hasNationalityState: true,
    shouldDisplayThreeNames: true,
    shouldDisplaySendLinkButton: false,
    logoModel: 'a',
  },
  digitalStore: {
    shouldDisplayRFC: false,
  },
  redirectUrlToLogin: {
    url: async () => getConfigsMFEFirebase('HOSTED_UI_AUTHENTICATION_URL'),
    clientId: async () => getConfigsMFEFirebase('CLIENT_ID_COGNITO'),
  },
  filter: {
    origin: true,
  },
})
