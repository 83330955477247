import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  button: {
   width: '32rem',
   maxWidth: '100%',
   marginTop: '2rem',
  },
  actions: {
    display: 'flex',
    padding: '0 60px',
  },
}))

export default useStyles
