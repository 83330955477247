import { snakeToCamel } from '../helpers/common'
import { subStatusNameEnum } from '../components/Filter/filter-substatus-enum'

export const statusOptions = {
  dropouts: 'dropouts',
  approved: 'approved',
  waitingFirstOrder: 'waiting_first_order',
  suggestedForStart: 'suggested_for_start',
  beginnings: 'beginnings',
  allRegisters: 'all_reports',
}

const statusFromUrl = {
  dropouts: {
    id: 'dropouts',
    translationId: 'dropouts',
    defaultStatusName: 'Disapproved/Desistance',
  },
  approved: {
    id: 'approved',
    translationId: 'approved',
    defaultStatusName: 'Approved',
  },
  waiting_first_order: {
    id: 'waiting_first_order',
    translationId: 'waitingFirstOrder',
    defaultStatusName: 'Waiting First Order',
  },
  suggested_for_start: {
    id: 'suggested_for_start',
    translationId: 'suggestedForStart',
    defaultStatusName: 'Suggested for start',
  },
  beginnings: {
    id: 'beginnings',
    translationId: 'beginnings',
    defaultStatusName: 'Beginnings',
  },
  all_registers: {
    id: 'all_reports',
    translationId: 'allRegisters',
    defaultStatusName: 'All Registers',
    showStatusFilter: true,
  },
}

export const mapStatusFromUrlToPageDetails = (status) => statusFromUrl[status]

export const sortFilterOptionsByI18nNames = (options) => options.sort((current, next) => {
  if (current.name >= next.name) {
    return 1
  }
  return -1
})

export const statusForFilter = (intl) => {
  const statusForFilterObject = { ...statusOptions }
  delete statusForFilterObject.allRegisters

  const options = Object.keys(statusForFilterObject).reduce(
    (accumulator, key) => {
      accumulator.push({
        id: statusForFilterObject[key],
        name: intl.formatMessage({ id: key, defaultMessage: key }),
      })
      return accumulator
    },
    [],
  )
  return sortFilterOptionsByI18nNames(options)
}

export const subStatusForFilter = (intl, options = []) => {
  const subStatusIntlOptions = options.reduce((accumulator, option) => {
    const optionName = option.name || subStatusNameEnum[option.id]
    const fullSnakeCaseMessageId = `SUB_STATUS_${optionName}`
    const fullCamelCaseMessageId = snakeToCamel(fullSnakeCaseMessageId)
    accumulator.push({
      id: option.id,
      name: intl.formatMessage({
        id: fullCamelCaseMessageId,
        defaultMessage: fullCamelCaseMessageId,
      }),
    })
    return accumulator
  }, [])
  return sortFilterOptionsByI18nNames(subStatusIntlOptions)
}

export const convertMultipleCheckedOptionsObjectToString = (
  multipleOptionsObject,
) => Object.keys(multipleOptionsObject)
  .filter((key) => multipleOptionsObject[key])
  .map((key) => key)
  .join(',')

export const convertMultipleCheckedOptionsStringToObject = (
  commaSeparatedString,
) => commaSeparatedString.split(',')
  .reduce((multipleOptionsObject, key) => {
    if (key.trim().length) {
      multipleOptionsObject[key] = true
    }
    return multipleOptionsObject
  }, {})

export const isObjectNotEmpty = (object) => !!Object.values(object)
  .filter((value) => value)
  .length
