import { LOCAL_STORAGE_PREFIX } from '../Constants'
// eslint-disable-next-line import/no-cycle
import standAloneMode from './modes/standalone'
// eslint-disable-next-line import/no-cycle
import webFvMode from './modes/webfv'
import appFvMode from './modes/appfv'
// eslint-disable-next-line import/no-cycle
import builtInMode from './modes/builtin'
import { APP_MODE } from '../helpers/mfeConfig'

const modesList = {
  [APP_MODE.STAND_ALONE]: standAloneMode,
  [APP_MODE.BUILT_IN]: builtInMode,
  [APP_MODE.WEB_FV]: webFvMode,
  [APP_MODE.APP_FV]: appFvMode,
}

const getMode = () => {
  const mode = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}mode`)
  let selectedMode

  if (mode) {
    selectedMode = modesList[mode]
  }

  return selectedMode || modesList[APP_MODE.STAND_ALONE]
}

export const appMode = getMode
