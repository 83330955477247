export default {
  subStatusActiveCnPendingCsc: 'CBN Role Active',
  subStatusActiveDebt: 'Rejected by debt',
  subStatusActiveSeller: 'Active Consultant',
  subStatusAddressIncomplete: 'Incomplete address',
  subStatusAddressValidationFail: 'Invalid address',
  subStatusAllocationStructureError: 'Error to allocate commercial structure',
  subStatusApprovedByGr: 'Approved by GR',
  subStatusApprovedByGv: 'Approved by GV',
  subStatusApprovedSeller: 'Approved',
  subStatusAttachmentMissing: 'Documents missing',
  subStatusAttachmentValidationFail: 'Documents validation failed',
  subStatusAttachmentValidationSuccess: 'Documents validated successfully',
  subStatusBrmBadParameters: 'BRM bad parameters',
  subStatusCeased: 'Ceased',
  subStatusCepValidationFail: 'Zip code not found',
  subStatusClearSaleValidationFail: 'System unavailable',
  subStatusCnActive: 'CBN Role Active',
  subStatusCreatingPerson: 'Candidate creation failed',
  subStatusCreditRatingError: 'Registration in credit analysis',
  subStatusCreditValidation: 'Rejected Natura Policies',
  subStatusDocumentPosted: 'Document posted',
  subStatusEmailExistent: 'Email existent',
  subStatusErrorCompleteDataOnBrm: 'Registration in process in BRM',
  subStatusEstablishmentNotFound: 'Establishment not found',
  subStatusExistentEmailOnGera: 'Existent email on GERA',
  subStatusExistentRole: 'Role already exists',
  subStatusFailureCeaseGera: 'Failure to cease in GERA',
  subStatusFinalDataValidation: 'Final data validation',
  subStatusGeraDebtServiceError: 'Error checking debits',
  subStatusIntegrationErrorWithGera: 'Registration in process in GERA',
  subStatusManuallyApproved: 'Manually approved',
  subStatusNameValidation: 'Name restriction',
  subStatusOpenTitle: 'Disapproved in Securities',
  subStatusPendingAddressBlocklistReview: 'Pending analysis blocklist',
  subStatusPendingAddressReview: 'Pending Analysis: Address',
  subStatusPendingAllocate: 'Pending allocation',
  subStatusPendingApproval: 'Pending approval',
  subStatusPendingCommercialStructureNotCovered: "Can't find commercial structure",
  subStatusPendingCreditAnalysis: 'Pending credit analysis',
  subStatusPendingCscApproval: 'Pending by CSC Approval',
  subStatusPendingData: 'Pending Data',
  subStatusPendingDigitalSpace: 'Pending E-Store',
  subStatusPendingDocumentation: 'Pending documentation',
  subStatusPendingEmailVerification: 'Pending contacts validation',
  subStatusPendingGrApproval: 'Pending GR approval',
  subStatusPendingGvApproval: 'Pending GV approval',
  subStatusPendingManualAssignment: 'Pending manual assignment',
  subStatusPendingPrepaid: 'Pending Pre Payment',
  subStatusPendingRegistrationApproval: 'Pending Registration Analysis',
  subStatusPendingRestartReview: 'Pending Analysis: Restart',
  subStatusProcessing: 'Processing',
  subStatusQuarantine: 'Quarentine',
  subStatusRejectedByBlocklist: 'Rejected by blocklist',
  subStatusRejectedByCsc: 'Rejected by CSC',
  subStatusRejectedByGr: 'Rejected by GR',
  subStatusRejectedByGv: 'Rejected by GV',
  subStatusRejectedByRegisterTeam: 'Rejected by Register Team',
  subStatusRejectedByMinimalPeriodAsCeased: 'Rejected by minimal period as ceased',
  subStatusRejectedWithReason: 'Rejected with a reason',
  subStatusRejectedByEquifax: 'Rejected by Equifax',
  subStatusRejectedByAgs: 'Rejected by AGS',
  subStatusRenunciationCandidate: 'Candidate withdrawal',
  subStatusRenunciationNatura: 'Natura withdrawal',
  subStatusRestrictedAddress: 'Address without coverage',
  subStatusRestrictedRole: 'Restricted Role',
  subStatusRestrictionEmail: 'Restricted Email',
  subStatusRestrictionTelephone: 'Restricted Phone',
  subStatusRetrieveCycleError: 'Error to retrieve cycle',
  subStatusSystemFail: 'System failure',
  subStatusTermOfAcceptance: 'Term of acceptance',
  subStatusWithoutAddress: 'Without address',
  subStatusWorkflowExecutionError: 'Workflow execution error',
  subStatusAgreementsServiceError: 'Agreements service error',
  subStatusInvalidAddresses: 'Direcciones erradas',
  subStatusInvalidDeliveryAddress: 'Invalid delivery address',
  subStatusInvalidMailingAddress: 'Invalid mailing address',
  subStatusMissingConfirmationCode: 'Pending terms authorization',
  subStatusSitidataServiceError: 'Sitidata service error',
  subStatusPendingDigitalSignature: 'Pending digital signature',
  subStatusPendingIdentityValidation: 'Pending identity validation',
  subStatusPendingName: 'Pending name',
  subStatusPendingCellphone: 'Pending cellphone validation',
  subStatusPendingCep: 'Pending CEP',
  subStatusPendingEmail: 'Pending e-mail',
  subStatusPendingBirthday: 'Pending birthday',
  subStatusPendingGender: 'Pending gender',
  subStatusPendingAddressDetails: 'Pending address details',
  subStatusPendingAcceptDs: 'Pending accept digital store',
  subStatusPendingCreateDs: 'Pending create digital store',
  subStatusPendingUploadDoc: 'Pending upload doc',
  subStatusPendingPasswordCreation: 'Pending password creation',
  subStatusPendingWorkflow: 'Pending Workflow',
  subStatusRejectedDocs: 'Rejected Documents',
  subStatusPendingEquifaxValidation: 'Equifax Validation Pending',
  subStatusPendingMaritalStatus: 'Marital Status Pending',
  subStatusPendingSourceOfFunds: 'Source of Funds Pending',
  subStatusPendingNomination: 'Pending indication',
  subStatusPendingToken: 'Pending SMS token validation',
}
