import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../../../components/Loading'
import ErrorArea from '../../../components/Error/ErrorInfo'
import ScreenContainer from '../../../components/ScreenContainer/ScreenContainer'
import { snakeToCamel } from '../../../helpers/common'
import { useResponsiveness } from '../../../hooks/responsive'
import { useGetProspectDetailsSWR } from '../../../hooks/swrServices/useGetProspectDetailsSWR'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'

const IFrame = () => {
  const { id } = useParams()
  const { isMobile } = useResponsiveness()
  const { configs } = useTenantConfigs()
  const {
    user: { data: loggedUserData },
  } = useSelector(({ user }) => user)
  const mobileHeight = isMobile ? 'calc(100vh - 60px)' : 'calc(100vh - 68px)'

  const url = `${process.env.REACT_APP_REGISTRATION_FV_V3}/validate-rejoin`
  const company = configs?.company?.name?.toLowerCase()
  const country = configs?.country?.alpha2Code?.toLowerCase()
  const personId = id
  const leaderId = loggedUserData?.personId
  const leaderCode = loggedUserData?.code
  const functionId = 4
  const embedded = true

  const v3Url = new URL(url)
  const queryParams = new URLSearchParams({
    company,
    country,
    personId,
    functionId,
    leaderId,
    leaderCode,
    embedded,
  })

  v3Url.search = queryParams

  if (!configs || !loggedUserData) {
    return null
  }

  return <iframe style={{ width: '100%', height: mobileHeight, border: 'none' }} src={v3Url.toString()} title="v3" />
}

export default () => {
  const { id, status } = useParams()
  const { userData, isLoading: loading, hasError } = useGetProspectDetailsSWR(id)
  const { isMobile } = useResponsiveness()
  const history = useHistory()

  const onCloseV3 = (event) => {
    if (event.data === 'closeV3') {
      history.replace(`/${status}/show/${id}`)
    }
  }

  useEffect(() => {
    window.addEventListener('message', onCloseV3, false)
    return () => {
      window.removeEventListener('message', onCloseV3, false)
    }
  }, [])

  return (
    <ScreenContainer
      hasDrawer
      withoutPadding
      title={
        isMobile ? (
          userData?.name
        ) : (
          <>
            <FormattedMessage id={snakeToCamel(status)} />
          </>
        )
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {hasError ? <ErrorArea /> : loading ? <Loading /> : <IFrame />}
    </ScreenContainer>
  )
}
