import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'

import { FormattedMessage } from 'react-intl'
import CustomCard from '../Card'
import CustomListItem from '../CustomListItem'
import { snakeToCamel } from '../../../../helpers/common'
import DocumentList from './DocumentList'
import AttachmentList from './AttachmentList'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'

const DocumentsCard = ({ cardTitleId, cardTitleDefaultMessage, userData }) => {
  const fields = [
    {
      fieldId: 'nationality',
      fieldDefaultMessage: 'Nationality',
      dataIndex: 'nationality',
      render: () => userData.nationality && <FormattedMessage id={snakeToCamel(userData.nationality)} />,
    },
  ]

  const { configs } = useTenantConfigs()

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard titleMessageId={cardTitleId} titleDefaultMessage={cardTitleDefaultMessage}>
        <List dense>
          <DocumentList documents={userData.documents} />
          {fields.map((field) => (
            <CustomListItem
              key={field.fieldId}
              fieldId={field.fieldId}
              fieldDefaultMessage={field.fieldDefaultMessage}
              data={field.render ? field.render() : userData[field.dataIndex]}
            />
          ))}
          {configs?.shouldDisplayDocumentAttachments && (
            <CustomListItem
              fieldId="attachments"
              fieldDefaultMessage="Attachments"
              data={<AttachmentList attachments={userData.attachments} />}
            />
          )}
          <CustomListItem
            fieldId="termsAndConditionsRemark"
            fieldDefaultMessage="Terms and conditions (remark)"
            data={userData.policiesAccepted}
            hideWhenThereIsNotData
          />
        </List>
      </CustomCard>
    </Grid>
  )
}

export default DocumentsCard
