import {
  useReducer, useState, useRef, useEffect,
} from 'react'
import axios from 'axios'
import { mutate } from 'swr'

const REQUEST_STATUS_TYPE = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_ERROR: 'FETCH_ERROR',
}

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case REQUEST_STATUS_TYPE.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        error: null,
      }
    case REQUEST_STATUS_TYPE.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload,
        error: null,
      }
    case REQUEST_STATUS_TYPE.FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

const defaultOptions = {
  initialData: undefined,
  axiosClient: undefined,
  shouldFetchOnStart: false,
  onSuccessCallback: undefined,
  onErrorCallback: undefined,
  onBeforeRequestCallback: undefined,
  mutateBeforeRequest: [],
  mutateOnSuccess: [],
  mutateOnError: [],
}

export const useAxiosHttpRequest = (
  initialConfig = {},
  initialOptions = defaultOptions,
) => {
  const isAllowedToFetch = useRef(false)
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    hasError: false,
    data: initialOptions.initialData,
    error: null,
  })
  const [axiosConfig, setAxiosConfig] = useState(initialConfig)
  const [options, setOptions] = useState({
    ...defaultOptions,
    ...initialOptions,
  })

  useEffect(() => {
    if (options.shouldFetchOnStart) {
      isAllowedToFetch.current = true
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      function handleErrorResponse(error) {
        if (error.response) {
          dispatch({
            type: REQUEST_STATUS_TYPE.FETCH_ERROR,
            payload: {
              status: error.response.status,
              data: error.response.data,
            },
          })
        } else if (error.request) {
          dispatch({
            type: REQUEST_STATUS_TYPE.FETCH_ERROR,
            payload: {
              status: 500,
              data: error.request,
            },
          })
        } else {
          dispatch({
            type: REQUEST_STATUS_TYPE.FETCH_ERROR,
            payload: {
              status: 500,
              data: error.message,
            },
          })
        }
      }

      dispatch({
        type: REQUEST_STATUS_TYPE.FETCH_REQUEST,
      })
      try {
        const axiosInstance = (await initialOptions.axiosClient)
          || axios.create({
            baseURL: initialConfig.baseURL,
          })
        if (options.onBeforeRequestCallback) {
          options.onBeforeRequestCallback()
        }
        if (options.mutateBeforeRequest) {
          options.mutateBeforeRequest.forEach((mutateItem) => {
            mutate(
              mutateItem.key,
              mutateItem.data,
              mutateItem.shouldRevalidate,
            )
          })
        }
        const response = await axiosInstance.request(axiosConfig)
        if (options.onSuccessCallback) {
          options.onSuccessCallback(response.data)
        }
        if (options.mutateOnSuccess) {
          options.mutateOnSuccess.forEach((mutateItem) => {
            mutate(
              mutateItem.key,
              mutateItem.data,
              mutateItem.shouldRevalidate,
            )
          })
        }

        dispatch({
          type: REQUEST_STATUS_TYPE.FETCH_SUCCESS,
          payload: response.data,
        })
      } catch (error) {
        if (options.onErrorCallback) {
          options.onErrorCallback(error)
        }
        if (options.mutateOnError) {
          options.mutateOnError.forEach((mutateItem) => {
            mutate(
              mutateItem.key,
              mutateItem.data,
              mutateItem.shouldRevalidate,
            )
          })
        }
        handleErrorResponse(error)
      }
    }

    if (isAllowedToFetch.current) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [axiosConfig]);

  function doRequest(config = {}, options = {}) {
    isAllowedToFetch.current = true
    setOptions({ ...initialOptions, ...options })
    setAxiosConfig({ ...initialConfig, ...config })
  }

  return {
    state: {
      data: state.data,
      isLoading: state.isLoading,
      hasError: state.hasError,
      error: state.error,
    },
    doRequest,
  }
}
