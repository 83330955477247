export default {
  digitalStoreName: 'Loja Online',
  digitalStoreStatus: 'Status da loja online',
  ownDigitalStore: 'Meu espaço digital',
  digitalStoreUrl: 'www.natura.com.br/consultora/',
  active: 'Ativa',
  ceased: 'Inativa',
  deniedBankAccount: 'Registro no Mercado Pago rejeitado',
  deniedPaymentAccount: 'Registro no Mercado Pago rejeitado',
  notStarted: 'Abertura de loja não solicitada',
  pending: 'Solicitação em Processo',
  pendingAgreements: 'Termos de venda online pendentes',
  pendingBankAccount: 'Ativação do Mercado Pago pendente',
  pendingFranchiseAgreement: 'Ativa',
  pendingPaymentAccount: 'Ativação do Mercado Pago pendente',
  suspended: 'Bloqueada',
  unableToSell: 'Primeiro pedido pendente para vendas presenciais',
  withSale: 'Ativa',
}
