import { FilterPeopleTableOptions } from '../people-table-options'

const strategies = {
  [FilterPeopleTableOptions.ALL]: () => true,
  [FilterPeopleTableOptions.HAS_CONTACTED]: () => false,
  [FilterPeopleTableOptions.NEW_REGISTERS]: () => false,
}

export const getShouldDisableContactMadeButton = (filterPeopleTableOption) => {
  const strategy = strategies[filterPeopleTableOption]

  if (strategy) {
    return strategy()
  }

  throw new Error('You must implement getShouldDisableContactMadeButton strategy')
}
