import React, { useState, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import Dialog from '../../../../../../components/Dialog'
import Button from '../../../../../../components/Buttons/Button'
import Select from '../../../../../../components/Select/Select'

import { useStyles } from './RejectionButtonWithDialog.styles'
import { useGetRejectionReasonsSWR } from '../../../../../../hooks/swrServices/useGetRejectionReasonsSWR'
import { useAxiosHttpRequest } from '../../../../../../hooks/useAxiosHttpRequest'
import { getBffApiGatewayConnection } from '../../../../../../services/config'
import { makeGetProspectDetailsKey } from '../../../../../../hooks/swrServices/useGetProspectDetailsSWR'

export const RejectionButtonWithDialog = ({ name, personId, personRoleId }) => {
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedRejectionReasonId, setSelectedRejectionReasonId] = useState()
  const { rejectionReasons, isLoading } = useGetRejectionReasonsSWR()
  const {
    state: { isLoading: isLoadingRejectPersonRolePatch },
    doRequest: doRejectPersonRolePatch,
  } = useAxiosHttpRequest(
    {
      url: `/accounts/register-reports/v1/people/${personId}/person-roles/${personRoleId}/reject`,
      method: 'patch',
    },
    {
      axiosClient: getBffApiGatewayConnection(),
    },
  )

  useEffect(() => {
    if (!isDialogOpen) {
      setSelectedRejectionReasonId(undefined)
    }
  }, [isDialogOpen])

  const handleButtonClick = () => {
    setIsDialogOpen(true)
  }

  const handleRejectPersonRole = () => {
    doRejectPersonRolePatch(
      {
        data: {
          rejectionReasonId: selectedRejectionReasonId,
        },
      },
      {
        onSuccessCallback: () => {
          setIsDialogOpen(false)
        },
        mutateOnSuccess: [
          {
            key: makeGetProspectDetailsKey(personId),
          },
        ],
      },
    )
  }

  function renderTitle() {
    return (
      <>
        <h4 className={classes.title}>
          <FormattedMessage
            id="rejectRegistrationDialogTitle"
            defaultMessage="Reject Registration"
          />
        </h4>
        <span className={classes.message}>
          <FormattedMessage
            id="rejectRegistrationDialogMessage"
            defaultMessage="Are you sure you want to reject the registration of <strong>{name}</strong>?"
            values={{
              name,
              strong: (text) => <strong>{text}</strong>,
            }}
          />
        </span>
      </>
    )
  }

  function renderActions() {
    return (
      <div className={classes.actionsContainer}>
        <Button
          variant="contained"
          color="error"
          onClick={() => setIsDialogOpen(false)}
        >
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!selectedRejectionReasonId}
          loading={isLoadingRejectPersonRolePatch}
          onClick={handleRejectPersonRole}
        >
          <FormattedMessage
            id="rejectRegistration"
            defaultMessage="Reject Registration"
          />
        </Button>
      </div>
    )
  }

  function transformRejectionReasonsItemsToSelectOptions(items) {
    return items
      ? items.map((item) => ({
        id: item.reasonId,
        optionName: item.reasonName,
      }))
      : []
  }

  function renderContent() {
    function handleSelectChange(value) {
      setSelectedRejectionReasonId(value)
    }

    return (
      <Select
        selectDataTestId="select-rejection-reasons"
        label="Select reason for disapproval"
        selectOptions={transformRejectionReasonsItemsToSelectOptions(
          rejectionReasons,
        )}
        selectedValue={selectedRejectionReasonId}
        onChangeEvent={handleSelectChange}
        fullWidth
      />
    )
  }

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={handleButtonClick}
        loading={isLoading}
        disabled={isLoading}
      >
        <FormattedMessage id="reject" defaultMessage="Reject" />
      </Button>
      <Dialog
        data-testid="rejection-dialog"
        open={isDialogOpen}
        title={renderTitle()}
        actions={renderActions()}
        dividers={false}
      >
        {renderContent()}
      </Dialog>
    </>
  )
}
