import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Cognito from './cognito'
import Loading from '../../components/Loading'

import { useCognito } from '../../hooks/useCognito'
import { COUNTRY_SELECTION } from '../../Routes'
import { redirectToHostedUi } from './cognito/redirect-to-hosted-ui'
import { getCountryConfiguration } from '../../hooks/useTenantConfigs'

const Auth = (props) => {
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const ssoToken = query.get('sso_token')
  const country = query.get('country')
  const company = query.get('company')
  const { loading } = useCognito()

  if (country && company && !ssoToken) {
    const configs = getCountryConfiguration(company, country)
    redirectToHostedUi(location, configs)

    return null
  }

  if (!ssoToken) {
    history.push(COUNTRY_SELECTION)
  }

  const renderLogin = () => {
    if (loading) {
      return <Loading />
    }

    return <Cognito {...props} />
  }

  return renderLogin()
}

export default Auth
