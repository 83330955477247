import React from 'react'
import PropTypes from 'prop-types'
import CustomListItem from '../CustomListItem'
import { snakeToCamel } from '../../../../helpers/common'
import defaultMessages from '../../../../locales/en/messages'

const DocumentList = ({ documents }) => {
  const hideCpfValue = (documentNumber) => `${documentNumber.toString().substring(0, 3)}.XXX.XXX-XX`

  if (!documents) return null
  return (
    /* eslint operator-linebreak:0 */
    documents &&
    documents.map(({ type = 'document', number }) => {
        if (type === 'CPF') {
          return (
            <CustomListItem
              key={type}
              fieldId={snakeToCamel(type)}
              fieldDefaultMessage={defaultMessages[snakeToCamel(type)]}
              data={hideCpfValue(number)}
            />
          )
        }
          return (
            <CustomListItem
              key={type}
              fieldId={snakeToCamel(type)}
              fieldDefaultMessage={defaultMessages[snakeToCamel(type)]}
              data={number}
            />
          )
    }))
}

DocumentList.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape()),
}

export default DocumentList
