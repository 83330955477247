import useSWR from 'swr'
import { getBffApiGatewayConnection } from '../../services/config'

export const makeGetRejectionReasonsSWR = () => '/accounts/register-reports/v1/rejection-reasons'

export function useGetRejectionReasonsSWR() {
  const { data, error } = useSWR(makeGetRejectionReasonsSWR(), async (url) => {
    const connection = await getBffApiGatewayConnection()
    const response = await connection.get(url)
    return response.data.items
  })

  return {
    rejectionReasons: data,
    isLoading: !error && !data,
    hasError: Boolean(error),
  }
}
