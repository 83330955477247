import { useEffect, useState } from 'react'
import { getUserInfo } from '../helpers/auth'
import { CompanyId } from '../models/company-id'

const useCompany = () => {
  const [companyId, setCompanyId] = useState()

  const isCurrentCompany = (company) => company === companyId

  const getCompanyName = (companyId) => {
    const companyNames = Object.keys(CompanyId)
    const companyName = companyNames.find((name) => CompanyId[name] === companyId)
    return companyName
  }

  useEffect(() => {
    const getCompany = async () => {
      const userData = await getUserInfo()
      if (userData) {
        setCompanyId(userData.company)
      }
    }

    getCompany()
  }, [])

  return {
    companyId,
    isCurrentCompany,
    getCompanyName,
  }
}

export default useCompany
