import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@naturacosmeticos/natds-web'
import { validateDesignEloIsActive } from '../../../utils/validateCountryId'

const useStyles = makeStyles((themes) => ({
  root: {
    height: '100%',
  },
  cardTitle: {
    fontWeight: 'bold',
    color: (props) => (props.isElo ? themes.color.primaryDarkest : themes.color.primary),
  },
  cardHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const CustomCard = ({
 titleDefaultMessage, titleMessageId, children, showEditButton, onEditButtonClick,
}) => {
  const isElo = validateDesignEloIsActive()
  const classes = useStyles({ isElo })

  return (
    <Card className={classes.root} data-testid={titleMessageId}>
      <div className={classes.cardHeaderContent}>
        <CardHeader
          title={(
            <Typography classes={{ root: classes.cardTitle }} variant="body1">
              <FormattedMessage defaultMessage={titleDefaultMessage} id={titleMessageId} />
            </Typography>
          )}
        />
        {showEditButton && (
          <Button color={isElo ? 'onBackground' : 'primary'} onClick={onEditButtonClick}>
            <FormattedMessage id="editButton" defaultMessage="Edit" />
          </Button>
        )}
      </div>
      {children}
    </Card>
  )
}

export default CustomCard
