import { getUserInfo } from '../../helpers/auth'
import { getCountryConfigurationByStorage } from '../../hooks/useTenantConfigs'

export const SOURCE_SYSTEM = '50'

export const getBaseHeaders = async (type = 'gateway') => {
  const { country, loginId, company } = await getUserInfo()

  const configs = await getCountryConfigurationByStorage()

  const headers = {
    country,
    company,
    sourceSystem: SOURCE_SYSTEM,
    'x-api-key': configs[type]?.xApiKey || process.env.REACT_APP_REGISTER_REPORTS_BFF_API_KEY,
  }

  if (loginId) {
    headers.loginId = loginId
  }

  return headers
}

const buildConnectionDefaultHeaders = async (type) => {
  const defaultHeaders = await getBaseHeaders(type)
  const { personId } = await getUserInfo()

  return {
    ...defaultHeaders,
    personId,
  }
}

export const BffApiGatewayHeaders = {
  connection: (type) => buildConnectionDefaultHeaders(type),
}

export const AgreementsApiGatewayHeaders = {
  connection: (type) => buildConnectionDefaultHeaders(type),
}
