import { DocumentType } from './DocumentType'

export const getLengthByDocumentType = (documentType) => {
  switch (documentType) {
    case DocumentType.NATIONAL_REGISTRY:
      return {
        minLength: 8,
        maxLength: 8,
      }
    case DocumentType.PASSPORT:
    case DocumentType.FOREIGN_REGISTRY:
      return {
        minLength: 9,
        maxLength: 9,
      }
    case DocumentType.PERSON_CODE:
      return {
        minLength: 1,
        maxLength: 25,
      }
    case DocumentType.CPF:
      return {
        minLength: 1,
        maxLength: 15,
      }
    case DocumentType.MY_CAD:
      return {
        minLength: 1,
        maxLength: 12,
      }
    case DocumentType.PASSPORT_MALASYA:
      return {
        minLength: 1,
        maxLength: 9,
      }
    case DocumentType.RUT:
      return {
        minLength: 8,
        maxLength: 9,
      }
    case DocumentType.CITIZEN_CARD:
      return {
        minLength: 4,
        maxLength: 10,
      }
    case DocumentType.FOREIGN_CARD:
      return {
        minLength: 4,
        maxLength: 10,
      }
    case DocumentType.DNI_ARGENTINA:
      return {
        minLength: 7,
        maxLength: 8,
      }
    case DocumentType.CURP:
      return {
        minLength: 18,
        maxLength: 18,
      }
    case DocumentType.INE_MEXICO:
      return {
        minLength: 18,
        maxLength: 18,
      }
    case DocumentType.CEDULA_IDENTIDAD_EC:
      return {
        minLength: 10,
        maxLength: 10,
      }
    default:
      return {
        minLength: 0,
        maxLength: 0,
      }
  }
}
