import React, { useEffect, useState } from 'react'
import { fetchAndActivateToggles } from './services/remote-config'
import Loading from './components/Loading'

export const ToggleProvider = ({ children }) => {
  const [loading, isLoading] = useState(true)

  useEffect(() => {
    fetchAndActivateToggles()
      .then(() => isLoading(false))
      .catch(() => isLoading(false))
  }, [])

  if (loading) return <Loading />
  return children
}
