import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((themes) => ({
  userCard: {
    position: 'fixed',
    right: 0,
    top: themes.spacing(6),
    zIndex: 9999,
    marginRight: themes.spacing(1),
    padding: themes.spacing(2),
    minWidth: themes.spacing(20),
  },
  buttonStyle: {
    width: '100%',
  },
  loadingContainer: {
    width: themes.spacing(5),
    height: themes.spacing(5),
  },
  avatarStyle: {
    cursor: 'pointer',
    backgroundColor: (props) => (props.isElo ? themes.color.primary : themes.color.secondary),
    color: (props) => (props.isElo ? themes.color.onBackground : 'white'),
  },
  avatarStyleAvon: {
    cursor: 'pointer',
    backgroundColor: themes.color.primary,
    color: 'white',
  },
}))
