import { LOCAL_STORAGE_PREFIX } from '../Constants'

export const APP_MODE = {
  BUILT_IN: 'built-in',
  STAND_ALONE: 'stand-alone',
  WEB_FV: 'web-fv',
  APP_FV: 'app-fv',
}

export const getMfeLayoutMode = () => {
  const layoutMode = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}mode`)

  return layoutMode || APP_MODE.STAND_ALONE
}

export const isStandAloneLayout = () => getMfeLayoutMode() === APP_MODE.STAND_ALONE
