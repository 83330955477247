import React from 'react'
import { useInfiniteScroll } from 'react-infinite-scroll-hook'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Checkbox } from '@naturacosmeticos/natds-web'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useStyles } from './TableList.styles'

const TableResults = ({
  columns,
  data,
  hasNextPage,
  loadingMore,
  onLoadMoreItems,
  onRowClick,
  onSelectRow,
  prependRow,
  disableCheckboxes,
}) => {
  const classes = useStyles()

  const infiniteContainerRef = useInfiniteScroll({
    hasNextPage,
    loading: loadingMore,
    onLoadMore: onLoadMoreItems,
    threshold: 300,
  })

  return (
    <TableBody ref={infiniteContainerRef}>
      {data.map((row) => (
        <React.Fragment key={`fragment_${row.personId}`}>
          {prependRow && prependRow(row)}
          <TableRow
            classes={{
              root: onRowClick ? classes.clickableTableRow : classes.tableRow,
            }}
            hover
            tabIndex={-1}
            data-testid={`row_${row.personId}`}
            key={row.personId}
          >
            <TableCell padding="checkbox">
              {onSelectRow && (
                <Checkbox
                  color="primary"
                  className={`checkbox_${row.personId} ${classes.checkbox}`}
                  checked={Boolean(row.selected)}
                  onClick={() => onSelectRow(row)}
                  disabled={disableCheckboxes}
                />
              )}
            </TableCell>
            {columns.map(({
                            colSpan, labelId, dataIndex, width, render,
                          }) => (
                            <TableCell
                              onClick={(event) => {
                  if (!window.getSelection().toString() && onRowClick) {
                    onRowClick(event, row)
                  }
                }}
                              colSpan={colSpan}
                              key={labelId}
                              width={width}
                              classes={{ root: classes.tableCell }}
                              id={labelId}
                              scope="row"
                            >
                              {render ? render(row) : row[dataIndex] || ' - '}
                            </TableCell>
            ))}
          </TableRow>
        </React.Fragment>
      ))}

      {loadingMore && (
        <TableRow>
          <TableCell colSpan={6}>
            <div className={classes.loadingMoreContainer}>
              <CircularProgress />
            </div>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

export default TableResults
