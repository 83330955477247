import TYPES from '../types'

export const actions = {
  getFilterGroups: () => ({
    type: TYPES.USER.GET_FILTER_GROUPS,
  }),
  setFilterGroups: ({ groupsFilter }) => ({
    type: TYPES.USER.SET_FILTER_GROUPS,
    groupsFilter,
  }),
  getUserStart: (personId, target) => ({
    type: TYPES.USER.GET_USER_START,
    payload: { personId, target },
  }),
  getUserSuccess: (user) => ({
    type: TYPES.USER.GET_USER_SUCCESS,
    payload: { user },
  }),
  getUserFail: (error) => ({
    type: TYPES.USER.GET_USER_FAIL,
    payload: { error },
  }),
}
