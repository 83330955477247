import useSWR from 'swr'
import { getBffApiGatewayConnection } from '../../services/config'

export const makeGetMenuItemsKey = () => '/accounts/register-reports/v1/menu'

export function useGetMenuItemsSWR() {
    const { data, error } = useSWR(
        makeGetMenuItemsKey(),
        async (url) => {
            const connection = await getBffApiGatewayConnection()
            const response = await connection.get(url)
            return response.data
        },
    )

    return {
        menuItems: data?.menuItems,
        isLoading: !error && !data,
        hasError: Boolean(error),
    }
}
