import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FilterPeopleTableOptions } from '../people-table-options'

const strategies = {
  [FilterPeopleTableOptions.ALL]: () => undefined,
  [FilterPeopleTableOptions.HAS_CONTACTED]: () => (
    <FormattedMessage
      id="moveToNewRegisters"
      defaultMessage="Move To New Records"
    />
  ),
  [FilterPeopleTableOptions.NEW_REGISTERS]: () => (
    <FormattedMessage
      id="alreadyGotInTouch"
      defaultMessage="Already Got In Touch"
    />
  ),
}

export const getContactMadeButtonText = (filterPeopleTableOption) => {
  const strategy = strategies[filterPeopleTableOption]

  if (strategy) {
    return strategy()
  }

  throw new Error('You must implement getContactMadeButtonText strategy')
}
