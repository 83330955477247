import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import PropTypes from 'prop-types'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { FormattedMessage } from 'react-intl'
import { useTenantConfigs } from '../../hooks/useTenantConfigs/useTenantConfigs'
import { CompanyId } from '../../models/company-id'

import { useStyles } from './TabsMenu.styles'
import { useResponsiveness } from '../../hooks/responsive'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

const TabsMenu = ({ tabs }) => {
  const classes = useStyles()
  const [tabIndex, setTabIndex] = useState(0)
  const { isMobile } = useResponsiveness()
  const { configs } = useTenantConfigs()

  const addClassAppBar = () => {
    if (configs?.company?.id === CompanyId.AVON && !isMobile) {
      return true
    }
    return false
  }

  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  return (
    <>
      <AppBar className={addClassAppBar() ? classes.appBar : ''} position="sticky" color="inherit" elevation={isMobile ? 4 : 1}>
        <Tabs
          variant={isMobile ? 'fullWidth' : 'standard'}
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
        >
          {tabs.map(({ labelId, labelDefaultMessage }, index) => (
            <Tab
              key={labelId}
              label={(
                <FormattedMessage
                  id={labelId}
                  defaultMessage={labelDefaultMessage}
                />
              )}
              classes={{ wrapper: classes.customWrapper }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map(({ labelId, Component }, index) => (
        <TabPanel
          className={classes.tabPanel}
          key={labelId}
          value={tabIndex}
          index={index}
        >
          <Component />
        </TabPanel>
      ))}
    </>
  )
}

TabsMenu.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      labelId: PropTypes.string,
      labelDefaultMessage: PropTypes.string,
      Component: PropTypes.func,
    }),
  ),
}

export default TabsMenu
