export const CountryId = {
  PERU: 'PE',
  BRAZIL: 'BR',
  CHILE: 'CL',
  COLOMBIA: 'CO',
  ARGENTINA: 'AR',
  MEXICO: 'MX',
  MALAYSIA: 'MY',
  ECUADOR: 'EC',
}
