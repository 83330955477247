import { getBffApiGatewayConnection } from './config'

export const recoverSession = async (ssoToken, deviceId) => {
  const connection = await getBffApiGatewayConnection(undefined, 'auth')

  const route = '/accounts/register-reports/v1/recover-session'

  const body = { token: ssoToken }
  if (deviceId) {
    body.deviceId = deviceId
  }

  const result = await connection.post(route, body)

  return result
}
