import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import { FormattedMessage } from 'react-intl'

import CustomCard from '../Card'
import CustomListItem from '../CustomListItem'
import { calcFromNow, snakeToCamel } from '../../../../helpers/common'
import { getAppLocale } from '../../../../helpers/mfeLocaleConfig'

import { ActionButtons } from './ActionButtons'
import { useToggle } from '../../../../hooks/useToggle'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import { hasRoleCeased } from '../../../../helpers/hasRole'

const getDigitalStoreInfo = (showDigitalStoreInfo, digitalStoreName, digitalStoreStatus, shouldDisplayRFC) => {
  if (!showDigitalStoreInfo) {
    return []
  }

  const documentRFC = getRFC(shouldDisplayRFC)

  return [
    {
      fieldId: 'digitalStoreName',
      fieldDefaultMessage: 'Digital Store',
      dataIndex: 'digitalStoreName',
      render: () => digitalStoreName && (
      <>
        <FormattedMessage id="digitalStoreUrl" />
        {digitalStoreName}
      </>
        ),
    },
    {
      fieldId: 'digitalStoreStatus',
      fieldDefaultMessage: 'Digital store status',
      render: () => digitalStoreStatus && <FormattedMessage id={snakeToCamel(digitalStoreStatus)} />,
    },
    ...documentRFC,
  ]

  function getRFC(shouldDisplayRFC) {
    if (shouldDisplayRFC) {
      const fieldRFC = {
        fieldId: 'rfc',
        fieldDefaultMessage: 'RFC',
        dataIndex: 'rfc',
      }
      return [fieldRFC]
    }
    return []
  }
}

const getFields = (userData, language, showDigitalStoreInfoToggle, shouldDisplayRFC) => {
  const {
    roles,
    registrant,
    origin,
    paymentPlan,
    registrationDate,
    showDigitalStoreInfo,
    digitalStoreName,
    digitalStoreStatus,
  } = userData
  const isReactivation = roles ? hasRoleCeased({ roles, functionId: 1, roleId: 1 }) : false
  const isMex = ['mx'].includes(language)
  const digitalStoreInfo = showDigitalStoreInfoToggle
    ? getDigitalStoreInfo(showDigitalStoreInfo, digitalStoreName, digitalStoreStatus, shouldDisplayRFC)
    : []

  return [
    {
      fieldId: 'indicator',
      fieldDefaultMessage: 'Indicator',
      dataIndex: 'introducerSeller',
    },
    {
      fieldId: 'registrant',
      fieldDefaultMessage: 'Registrant',
      render: () => (registrant === 'SELF_REGISTRATION' ? <FormattedMessage id={snakeToCamel(registrant)} /> : registrant),
    },
    {
      fieldId: 'origin',
      fieldDefaultMessage: 'Origin',
      render: () => origin && <FormattedMessage id={snakeToCamel(origin)} />,
    },
    {
      fieldId: 'rejectionReason',
      fieldDefaultMessage: 'Rejection Reason',
      dataIndex: 'rejectionReason',
    },
    {
      fieldId: 'paymentPlan',
      fieldDefaultMessage: 'Payment Plan',
      render: () => paymentPlan && <FormattedMessage id={snakeToCamel(paymentPlan)} />,
    },
    ...digitalStoreInfo,
    {
      fieldId: 'restart',
      fieldDefaultMessage: 'Restart',
      render: () => <FormattedMessage id={isReactivation ? 'yes' : 'no'} />,
    },
    {
      fieldId: 'homeTime',
      fieldDefaultMessage: 'Home Time',
      render: () => calcFromNow(new Date(registrationDate), isMex ? `es-${language}` : language),
    },
  ]
}

const ConsultantRegistrationCard = ({ userData }) => {
  const [language, setLanguage] = useState(null)
  const [fields, setFields] = useState([])
  const { SHOW_DIGITAL_STORE_INFO } = useToggle()
  const { isLoading, configs } = useTenantConfigs()
  const shouldDisplayRFC = configs?.digitalStore?.shouldDisplayRFC && !isLoading

  useEffect(() => {
    getAppLocale().then((languageTag) => {
      setLanguage(languageTag)
    })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const showDigitalStoreInfoToggle = SHOW_DIGITAL_STORE_INFO === true
    const fieldsToBuild = getFields(userData, language, showDigitalStoreInfoToggle, shouldDisplayRFC)

    setFields(fieldsToBuild)

    // eslint-disable-next-line
  }, [language, SHOW_DIGITAL_STORE_INFO])

  return (
    <Grid item xs={12} sm={6}>
      <CustomCard titleMessageId="additionalData" titleDefaultMessage="Additional Data">
        <List dense>
          {fields.map(({
 fieldId, fieldDefaultMessage, render, dataIndex,
}) => (
  <CustomListItem
    key={fieldId}
    fieldId={fieldId}
    fieldDefaultMessage={fieldDefaultMessage}
    data={render ? render() : userData[dataIndex]}
  />
          ))}
        </List>
        {userData.isPendingApproval && <ActionButtons name={userData.name} personRoleId={userData.personRoleId} />}
      </CustomCard>
    </Grid>
  )
}

export default ConsultantRegistrationCard
