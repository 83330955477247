import { remoteConfig } from '../helpers/firebaseConfig'

export const getAllToggles = () => {
  const toggles = remoteConfig.getAll()
  const appToggles = []

  Object.keys(toggles).forEach((toggleKey) => {
    const toggleValidation = new RegExp(`^${process.env.REACT_APP_PRODUCT_ID}_(.+)_TOGGLE$`)

    if (toggleValidation.test(toggleKey)) {
      const finalKey = toggleKey.match(toggleValidation)
      appToggles[finalKey[1]] = JSON.parse(toggles[toggleKey].asString())
    }
  })

  return { ...appToggles }
}

export const fetchAndActivateToggles = async () => remoteConfig.fetchAndActivate()
