/* eslint-disable react/jsx-one-expression-per-line */
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './RegistrantCell.styles'
import { snakeToCamel } from '../../helpers/common'

export const RegistrantCell = ({ registrant }) => {
  const classes = useStyles()
  if (!registrant) return '-'
  const containsId = registrant.includes(' - ')
  if (containsId) {
    const [id, name] = registrant.split(' - ')
    return (
      <>
        <Typography variant="body1" classes={{ root: classes.name }}>
          {name}
        </Typography>
        <Typography classes={{ root: classes.id }} variant="overline">
          <strong>ID:</strong>
          {id}
        </Typography>
      </>
    )
  }
  return String(registrant).toUpperCase() === 'SELF_REGISTRATION' ? (
    <FormattedMessage id={snakeToCamel(registrant)} />
  ) : (
    registrant
  )
}
