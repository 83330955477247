import React from 'react'
import PropTypes from 'prop-types'
import hexToRgba from 'hex-to-rgba'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button as NatDsButton } from '@naturacosmeticos/natds-web'

const defaultClasses = makeStyles((theme) => ({
  containedPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  containedError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
  outlinedDefault: {
    border: `1px solid ${hexToRgba(
      theme.palette.complementary.highlight,
      0.23,
    )}`,
    borderColor: hexToRgba(theme.palette.complementary.highlight, 0.12),
  },
}))

function capitalizeFirstLetter(color) {
  return color[0].toUpperCase() + color.slice(1)
}

// eslint-disable-next-line consistent-return
const getDefaultClass = (variant, color) => {
  const styles = defaultClasses()
  if (variant && color) {
    return styles[variant + capitalizeFirstLetter(color)]
  }
}

const Button = ({
  children, variant, color, classes, loading, ...rest
}) => (
  <NatDsButton
    variant={variant}
    color={color}
    classes={{ root: getDefaultClass(variant, color), ...classes }}
    {...rest}
  >
    {loading ? <CircularProgress size={30} color="inherit" /> : children}
  </NatDsButton>
)

Button.propTypes = {
  // eslint-disable-next-line react/require-default-props
  color: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  classes: PropTypes.object,
}

Button.defaultProps = {}

export default Button
