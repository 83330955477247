import Select from '@material-ui/core/Select'
import { MenuItem } from '@naturacosmeticos/natds-web'
import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import styled from 'styled-components'
import { useStyles } from '../Filter/filterOptions/FilterByGroup.styles'

import { useResponsiveness } from '../../hooks/responsive'
import { Icon } from '../Icon'

const StyledSelect = styled(Select)`
  min-width: 200px;

  .select-icon {
    height: 90%;
    display: flex;
    align-items: center;
  }
`

export default ({
  label,
  selectDataTestId,
  selectName,
  selectedValue = '',
  onChangeEvent,
  selectOptions,
  fullWidth,
  id,
}) => {
  const classes = useStyles()
  const { isMobile } = useResponsiveness()

  const getDisabledOptionClassName = (disabled) => {
    if (disabled) {
      return classes.selectOptionDisabled
    }
    return ''
  }

  return (
    <FormControl fullWidth={fullWidth || isMobile}>
      {label && (
        <InputLabel disableAnimation focused={false}>
          {label}
        </InputLabel>
      )}
      <StyledSelect
        id={id}
        data-testid={selectDataTestId}
        className={classes.selectContainer}
        IconComponent={({ className }) => (
          <Icon
            className={`select-icon ${className}`}
            name={
              className === 'MuiSelect-iconOpen' ? 'arrow_up' : 'arrow_down'
            }
          />
        )}
        disableUnderline
        displayEmpty
        name={selectName}
        value={selectedValue}
        onChange={(event) => {
          onChangeEvent(event.target.value)
        }}
      >
        {selectOptions.map(
          ({
            id, optionName, disabled, className, ...otherProps
          }) => (
            <MenuItem
              key={id}
              value={id}
              name={optionName}
              {...otherProps}
              disabled={disabled}
              className={`${className} ${getDisabledOptionClassName(
                  disabled,
                )}`}
            >
              {optionName}
            </MenuItem>
            ),
        )}
      </StyledSelect>
    </FormControl>
  )
}
