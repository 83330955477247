import { Box, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import Select from '../../../../components/Select/Select'
import CustomCard from '../Card'
import { CancelRegistrationButtonWithDialog } from './CancelRegistrationButtonWithDialog'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import { hasRole } from '../../../../helpers/hasRole'

const CancelRegistrationCard = ({ userData }) => {
  const [cancelReason, setCancelReason] = useState('')
  const [cancelReasonId, setCancelReasonId] = useState('0')
  const { personId, name, personRoleId } = userData
  const { configs } = useTenantConfigs()
  const redirectURLCancelRegistration = configs?.redirectURLCancelRegistration
  const functionIdGN = configs?.roles?.gn.functionId
  const roleIdGN = configs?.roles?.gn.roleId
  const functionIdLeader = configs?.roles?.leader.functionId
  const roleIdLeader = configs?.roles?.leader.roleId
  const selectorData = useSelector(({ user }) => user)
  const data = selectorData?.user ? selectorData.user.data : selectorData.data

  const isGN = hasRole({
    roles: data?.roles || [],
    functionId: functionIdGN,
    roleId: roleIdGN,
  })

  const isLider = hasRole({
    roles: data?.roles || [],
    functionId: functionIdLeader,
    roleId: roleIdLeader,
  })

  const userIsAvailable = isGN || isLider
  const cbIsDraft = userData.status === 'DRAFT'

  const showCancelRegistrationCard = configs?.showCancelRegistrationCard && userIsAvailable && cbIsDraft

  if (!showCancelRegistrationCard) {
    return null
  }

  const options = [
    {
      id: '0',
      optionName: <FormattedMessage
        id="selectPlaceHolderCancelRegister"
        defaultMessage="Select a reason"
      />,
      disabled: true,
    },
    {
      id: '1',
      optionName: <FormattedMessage
        id="cancelReasonCandidate"
        defaultMessage="Candidate withdrew from registration"
      />,
      renunciateReason: 'renunciationCandidate',

    },
    {
      id: '2',
      optionName: <FormattedMessage
        id="cancelReasonNatura"
        defaultMessage="Cancellation by Natura"
      />,
      renunciateReason: 'renunciationNatura',
    },
  ]

  const handleSelectChange = (value) => {
    setCancelReasonId(options[value].id)
    setCancelReason(options[value].renunciateReason)
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <CustomCard titleMessageId="cancelRegister" titleDefaultMessage="Cancel Registration">
          <Box px={2} pb={2} mt={1} display="flex" flexDirection="column" alignItems="flex-end" gap={2}>
            <Select
              selectDataTestId="select-cancel-reasons"
              label={(
                <FormattedMessage
                  id="labelSelectCancelReason"
                  defaultMessage="What is the reason for the cancellation?"
                />
              )}
              selectOptions={options}
              selectedValue={cancelReasonId}
              fullWidth
              onChangeEvent={handleSelectChange}
              value={cancelReason}
            />
            <CancelRegistrationButtonWithDialog
              name={name}
              personId={personId}
              personRoleId={personRoleId}
              renunciateReason={cancelReason}
              redirectURLCancelRegistration={redirectURLCancelRegistration}
            />
          </Box>
        </CustomCard>
      </Grid>
    </>
  )
}
export default CancelRegistrationCard
