import implement from 'implement-js'
import { MainModeInterface } from '../../interfaces/main_mode'

import adapteePaths from './paths'
// eslint-disable-next-line import/no-cycle
import adapteeLayout from './layout'
import adapteeAuth from './providers'
import adapteeStyles from './styles'

const BuiltInMode = {
  paths: adapteePaths,
  layout: adapteeLayout,
  providers: adapteeAuth,
  styles: adapteeStyles,
}

export default implement(MainModeInterface)(BuiltInMode)
