import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  personName: {
    lineHeight: '22px',
  },
  personDocument: {
    lineHeight: '21px',
    fontSize: '14px',
    letterSpacing: '0.1px',
  },
}))
