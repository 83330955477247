import React from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useParams } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Icon, Logo as LogoReact } from '@naturacosmeticos/natds-react'

import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import IconButton from '../Buttons/IconButton'
import { useStyles } from './Header.styles'
import { useResponsiveness } from '../../hooks/responsive'
import naturaBrand from '../../assets/img/naturaBrand.svg'
import useCompany from '../../hooks/useCompany'
import { CompanyId } from '../../models/company-id'
import { useTenantConfigs } from '../../hooks/useTenantConfigs/useTenantConfigs'
import { Avatar } from '../Avatar/Avatar'
import { validateDesignEloIsActive } from '../../utils/validateCountryId'

const NewRegisterButtonLink = ({ href, company }) => {
  const isElo = validateDesignEloIsActive()
  const { id } = useParams()
  const { isMobile, isDesktop } = useResponsiveness()
  const classes = useStyles({ isMobile, isDesktop })

  if (id && isMobile) {
    return null
  }

  return (
    <Button
      href={href}
      variant="contained"
      className={company === 'avon' ? classes.newRegisterAvonButton : classes.newRegisterButton}
      aria-label="New Register"
      startIcon={isElo && <Icon name="outlined-social-addcontact" color="onSecondaryDarkest" />}
      target="_blank"
    >
      <FormattedMessage id="registerButtonLinkLabel" />
    </Button>
  )
}

const Header = ({
  children,
  title,
  mobileTitle,
  hasBackButton,
  hideNewRegisterButton,
  hasElevation,
}) => {
  const history = useHistory()
  const { isMobile, isDesktop } = useResponsiveness()
  const classes = useStyles({ isMobile, isDesktop })
  const { isLoading, configs } = useTenantConfigs()

  const shouldRenderNewRegisterButton = !hideNewRegisterButton
    && !isLoading
    && configs?.header.hasRegisterButtonLink && isDesktop

  const shouldRenderTitle = isDesktop || (!hideNewRegisterButton && !shouldRenderNewRegisterButton)

  const shouldRenderLogo = isDesktop || (!shouldRenderTitle && !shouldRenderNewRegisterButton)

  const shouldRenderAvatar = configs?.header.hasLogout && !hideNewRegisterButton && isDesktop
  const {
    user: { data: loggedUserData },
  } = useSelector(({ user }) => user)

  const company = configs?.company?.name?.toLowerCase()
  const country = configs?.country?.alpha2Code?.toLowerCase()
  const editContactWithV3 = configs?.profile?.editContactWithV3
  const leaderId = loggedUserData?.personId
  const leaderCode = loggedUserData?.code
  const functionId = 4

  return (
    <AppBar
      position={isDesktop ? 'fixed' : 'static'}
      color="inherit"
      className={classes.appBar}
      elevation={hasElevation || isDesktop ? 4 : 0}
    >
      <Toolbar classes={{ root: classes.toolbar }}>
        <div className={classes.titleMobileContainer}>
          {hasBackButton && isMobile && (
          <IconButton
            edge="start"
            aria-label="back"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
          {isMobile && (
          <Typography
            color="textPrimary"
            variant="h6"
            noWrap
            classes={{
              root: classes.titleMobile,
            }}
          >
            {mobileTitle}
          </Typography>
)}
        </div>
        {
          shouldRenderLogo && (<Logo />)
        }
        {
          shouldRenderTitle && (
            <>
              <Typography
                color="textPrimary"
                variant="h6"
                noWrap
                classes={{
                  root: classes.title,
                }}
              >
                {title}
              </Typography>
            </>
          )
        }
        <div className={classes.rightContainer}>
          {shouldRenderNewRegisterButton && (
            <NewRegisterButtonLink
              href={editContactWithV3
            ? `${configs.header.registerButtonLinkHref}/?company=${company}&country=${country}&leaderId=${leaderId}&leaderCode=${leaderCode}&functionId=${functionId}`
            : `${configs.header.registerButtonLinkHref}`}
              company={company}
            />
          )}
          {shouldRenderAvatar && (
            <div className={classes.avatarContainer}>
              <Avatar />
            </div>
          )}
        </div>
        {children}
      </Toolbar>
    </AppBar>
  )
}

const LogoCompany = ({ companyId, defaultMessageCompany, brandImage }) => {
  const classes = useStyles()
  const intl = useIntl()

  if (companyId === 'avonBrand') {
    return (
      <div className={classes.naturaLogoContainer}>
        <LogoReact
          arialabel={intl.formatMessage({
            id: companyId,
            defaultMessage: defaultMessageCompany,
          })}
          brand="avon_v2"
          color="neutral"
          model="a"
          size="hugeXX"
        />
      </div>
    )
  }

  return (
    <div className={classes.naturaLogoContainer}>
      <Link to="/">
        <img
          alt={intl.formatMessage({
            id: companyId,
            defaultMessage: defaultMessageCompany,
          })}
          width={150}
          src={brandImage}
        />
      </Link>
    </div>
  )
}

const Logo = () => {
  const { isCurrentCompany } = useCompany()
  const { configs } = useTenantConfigs()
  const classes = useStyles()

  return (
    <>
      {isCurrentCompany(CompanyId.NATURA) && !validateDesignEloIsActive() && (
        <LogoCompany companyId="naturaBrand" defaultMessageCompany="Natura Brand" brandImage={naturaBrand} />
      )}
      {isCurrentCompany(CompanyId.AVON) && <LogoCompany companyId="avonBrand" defaultMessageCompany="Avon Brand" />}
      {validateDesignEloIsActive() && (
      <div className={classes.eloLogoContainer}>
        <Link to="/">
          <LogoReact size="hugeXXX" style={{ marginRight: 30 }} model={configs?.profile.logoModel} />
        </Link>
      </div>
        )}
    </>
  )
}

Header.defaultProps = {
  hasBackButton: true,
  hideNewRegisterButton: false,
}

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.any.isRequired,
  hasBackButton: PropTypes.bool,
  hideNewRegisterButton: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  hasElevation: PropTypes.bool,
}

export default Header
