import implement from 'implement-js'
import { MainModeInterface } from '../../interfaces/main_mode'

import adapteePaths from './paths'
import adapteeLayout from '../builtin/layout'
import adapteeAuth from '../builtin/providers'
import adapteeStyles from '../builtin/styles'

const WebFvMode = {
  paths: adapteePaths,
  layout: adapteeLayout,
  providers: adapteeAuth,
  styles: adapteeStyles,
}

export default implement(MainModeInterface)(WebFvMode)
