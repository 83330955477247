import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon } from '@naturacosmeticos/natds-web'
import Button from '../../../../components/Buttons/Button'
import { useResponsiveness } from '../../../../hooks/responsive'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import { getV1ConsultancyUrl } from '../../../../services/config'
import useStyles from './MyBusinessButton.styles'

export function MyBusinessButton({ consultantCode, variant = 'contained' }) {
  const classes = useStyles()
  const { isDesktop } = useResponsiveness()
  const { configs } = useTenantConfigs()

  const v1ConsultancyUrl = getV1ConsultancyUrl()

  if (!configs?.showMyBusinessButton || !consultantCode) return null

  return (
    <a
      className={classes.anchor}
      href={`${v1ConsultancyUrl.url}/my-registers/loading/person?consultantCode=${consultantCode}`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="my business button"
    >
      <Button
        variant={variant}
        color="primary"
        className={isDesktop ? classes.editButton : ''}
        id="my-business"
        size="medium"
        type="button"
        startIcon={<Icon name="outlined-social-myprofile" />}
      >
        <FormattedMessage id="myBusiness" defaultMessage="MY BUSINESS" />
      </Button>
    </a>
  )
}
