import React from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './ContactCell.styles'

const ContactCell = ({ phoneMobile, email }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="body2" classes={{ root: classes.personName }}>
        <strong>
          <FormattedMessage id="cellphone" />
          :
        </strong>
        {` ${phoneMobile || '-'}`}
      </Typography>
      <Typography variant="body2" classes={{ root: classes.personName }}>
        <strong>
          <FormattedMessage id="email" />
          :
        </strong>
        {` ${email || '-'}`}
      </Typography>
    </>
  )
}

export default ContactCell
