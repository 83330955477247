import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  filterTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(),
  },
  filterDecorator: {
    borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
    background: `${theme.palette.primary.main} 0% 0% no-repeat padding-box`,
    height: theme.sizes.semi,
    width: theme.sizes.micro,
    marginRight: theme.spacing(2),
  },
}))
