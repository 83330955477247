import { FormattedMessage } from 'react-intl'
import React from 'react'
import Button from '../../../../../components/Buttons/Button'
import { useStyles } from './CancelRegistrationButtonWithDialog.styles'

export default function ActionButtonsDialog({
 isOpen, renunciateReason, isLoadingRenunciatePersonRolePatch, handleRenunciatePersonRole,
}) {
  const classes = useStyles()
  return (
    <div className={classes.actionsContainer}>
      <Button
        variant="contained"
        color="default"
        onClick={() => isOpen(false)}
      >
        <FormattedMessage id="cancelActionButton" defaultMessage="Cancel" />
      </Button>
      <Button
        data-testid="cancelRegistration-dialogAction"
        variant="contained"
        color="primary"
        disabled={!renunciateReason}
        loading={isLoadingRenunciatePersonRolePatch}
        onClick={handleRenunciatePersonRole}
        className={classes.buttonRenunciate}
      >
        <FormattedMessage
          id="cancelRegistration"
          defaultMessage="Cancel Registration"
        />
      </Button>
    </div>
  )
}
