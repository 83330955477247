import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CompanyId } from '../../../models/company-id'
import { CountryId } from '../../../models/country-id'
import { TENANT_ID } from '../tenantId'

export const getAvonArgentina = () => ({
  tenantId: TENANT_ID.AVON_ARGENTINA,
  country: {
    alpha2Code: CountryId.ARGENTINA,
    alpha3Code: 'ARG',
    language: 'es',
  },
  company: {
    name: 'AVON',
    id: CompanyId.AVON,
    logout: {
      buttonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/logout`,
      externalLink: true,
    },
  },
  header: {
    hasRegisterButtonLink: false,
    /**
     * Verificar sobre esse path
     */
    registerButtonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/ar/auto`,
    hasLogout: true,
  },
  profile: {
    hasNationalityState: true,
    shouldDisplayThreeNames: true,
    shouldDisplaySendLinkButton: false,
  },
  digitalStore: {
    shouldDisplayRFC: false,
  },
  gateway: {
    getRegisterReportsBffUrl: async () => process.env.REACT_APP_REGISTER_REPORTS_BFF_URL,
    getAgreementsUrl: async () => process.env.REACT_APP_CADUNI_AGREEMENTS_URL,
    xApiKey: process.env.REACT_APP_REGISTER_REPORTS_BFF_API_KEY,
  },
  auth: {
    getRegisterReportsBffUrl: async () => getConfigsMFEFirebase('REGISTER_REPORTS_BFF_URL'),
    xApiKey: process.env.REACT_APP_REGISTER_REPORTS_BFF_API_KEY_AVON,
  },
  shouldDisplayStructureCodeLevels: true,
  showEditButtonDetails: false,
  structureCodeLevels: [
    {
      level: 1,
      label: 'division',
      defaultLabel: 'Division',
    },
    {
      level: 2,
      label: 'zone',
      defaultLabel: 'Zone',
    },
  ],
  redirectUrlToLogin: {
    url: async () => getConfigsMFEFirebase('HOSTED_UI_AUTHENTICATION_URL'),
    clientId: async () => getConfigsMFEFirebase('CLIENT_ID_COGNITO'),
  },
})
