export default {
  nationalityAfghan: 'Afghan',
  nationalityAlbanian: 'Albanian',
  nationalityAmerican: 'American',
  nationalityAndorran: 'Andorran',
  nationalityAngolan: 'Angolan',
  nationalityAnguillan: 'Anguillan',
  nationalityAntartic: 'Antartic',
  nationalityAntiguans: 'Antiguans',
  nationalityArab: 'Arab',
  nationalityArubean: 'Arubean',
  nationalityAlgerian: 'Algerian',
  nationalityArgentinean: 'Argentinean',
  nationalityArmenian: 'Armenian',
  nationalityAustralian: 'Australian',
  nationalityAustrian: 'Austrian',
  nationalityAzerbaijani: 'Azerbaijani',
  nationalityBahamian: 'Bahamian',
  nationalityBahraini: 'Bahraini',
  nationalityBangladeshi: 'Bangladeshi',
  nationalityBarbadian: 'Barbadian',
  nationalityBatswana: 'Batswana',
  nationalityBelarusian: 'Belarusian',
  nationalityBelgian: 'Belgian',
  nationalityBelizean: 'Belizean',
  nationalityBeninese: 'Beninese',
  nationalityBermudian: 'Bermudian',
  nationalityBhutanese: 'Bhutanese',
  nationalityBolivian: 'Bolivian',
  nationalityBosnian: 'Bosnian',
  nationalityBouvetIsland: 'Bouvet Island',
  nationalityBrazilian: 'Brazilian',
  nationalityBritish: 'British',
  nationalityBruneian: 'Bruneian',
  nationalityBulgarian: 'Bulgarian',
  nationalityBurkinabe: 'Burkinabe',
  nationalityBurmese: 'Burmese',
  nationalityBurundian: 'Burundian',
  nationalityCambodian: 'Cambodian',
  nationalityCameroonian: 'Cameroonian',
  nationalityCanadian: 'Canadian',
  nationalityCapeVerdean: 'Cape Verdean',
  nationalityCentralAfrican: 'Central African',
  nationalityChadian: 'Chadian',
  nationalityChilean: 'Chilean',
  nationalityChinese: 'Chinese',
  nationalityColombian: 'Colombian',
  nationalityComoran: 'Comoran',
  nationalityCongolese: 'Congolese',
  nationalityCookIsland: 'Cook Islands',
  nationalityCostaRican: 'Costa Rican',
  nationalityCorean: 'Corean',
  nationalityCroatian: 'Croatian',
  nationalityCuban: 'Cuban',
  nationalityCypriot: 'Cypriot',
  nationalityCzech: 'Czech',
  nationalityDanish: 'Danish',
  nationalityDjibouti: 'Djibouti',
  nationalityDominican: 'Dominican',
  nationalityDutch: 'Dutch',
  nationalityEastTimorese: 'East Timorese',
  nationalityEcuadorean: 'Ecuadorean',
  nationalityEgyptian: 'Egyptian',
  nationalityEquatorialGuinean: 'Equatorial Guinean',
  nationalityEritrean: 'Eritrean',
  nationalityEstonian: 'Estonian',
  nationalityEthiopian: 'Ethiopian',
  nationalityFijian: 'Fijian',
  nationalityFilipino: 'Filipino',
  nationalityFinnish: 'Finnish',
  nationalityFrench: 'French',
  nationalityGabonese: 'Gabonese',
  nationalityGambian: 'Gambian',
  nationalityGeorgian: 'Georgian',
  nationalityGerman: 'German',
  nationalityGibraltar: 'Gibraltar',
  nationalityGhanaian: 'Ghanaian',
  nationalityGreek: 'Greek',
  nationalityGrenadian: 'Grenadian',
  nationalityGuatemalan: 'Guatemalan',
  nationalityGuineaBissauan: 'Guinea-Bissauan',
  nationalityGuinean: 'Guinean',
  nationalityGuyanese: 'Guyanese',
  nationalityHaitian: 'Haitian',
  nationalityHonduran: 'Honduran',
  nationalityHongKong: 'Hong Kong',
  nationalityHungarian: 'Hungarian',
  nationalityIKiribati: 'I-Kiribati',
  nationalityIcelander: 'Icelander',
  nationalityIndian: 'Indian',
  nationalityIndonesian: 'Indonesian',
  nationalityIranian: 'Iranian',
  nationalityIraqi: 'Iraqi',
  nationalityIrish: 'Irish',
  nationalityIsraeli: 'Israeli',
  nationalityItalian: 'Italian',
  nationalityIvorian: 'Ivorian',
  nationalityJamaican: 'Jamaican',
  nationalityJapanese: 'Japanese',
  nationalityJordanian: 'Jordanian',
  nationalityKazakhstani: 'Kazakhstani',
  nationalityKenyan: 'Kenyan',
  nationalityKittianAndNevisian: 'Kittian and Nevisian',
  nationalityKuwaiti: 'Kuwaiti',
  nationalityKyrgyz: 'Kyrgyz',
  nationalityLaotian: 'Laotian',
  nationalityLatvian: 'Latvian',
  nationalityLebanese: 'Lebanese',
  nationalityLiberian: 'Liberian',
  nationalityLibyan: 'Libyan',
  nationalityLiechtensteiner: 'Liechtensteiner',
  nationalityLithuanian: 'Lithuanian',
  nationalityLuxembourger: 'Luxembourger',
  nationalityMacedonian: 'Macedonian',
  nationalityMalagasy: 'Malagasy',
  nationalityMalawian: 'Malawian',
  nationalityMalaysian: 'Malaysian',
  nationalityMaldivian: 'Maldivian',
  nationalityMalian: 'Malian',
  nationalityMaltese: 'Maltese',
  nationalityMarshallese: 'Marshallese',
  nationalityMauritanian: 'Mauritanian',
  nationalityMauritian: 'Mauritian',
  nationalityMexican: 'Mexican',
  nationalityMicronesian: 'Micronesian',
  nationalityMoldovan: 'Moldovan',
  nationalityMonacan: 'Monacan',
  nationalityMontserrat: 'Montserrat',
  nationalityMongolian: 'Mongolian',
  nationalityMoroccan: 'Moroccan',
  nationalityMosotho: 'Mosotho',
  nationalityMozambican: 'Mozambican',
  nationalityNamibian: 'Namibian',
  nationalityNauruan: 'Nauruan',
  nationalityNepalese: 'Nepalese',
  nationalityNewZealander: 'New Zealander',
  nationalityNiVanuatu: 'Ni-Vanuatu',
  nationalityNicaraguan: 'Nicaraguan',
  nationalityNigerian: 'Nigerian',
  nationalityNigerien: 'Nigerien',
  nationalityNiueIsland: 'Niue Island',
  nationalityNorwegian: 'Norwegian',
  nationalityNorfolkIsland: 'Norfolk Island',
  nationalityOmani: 'Omani',
  nationalityPakistani: 'Pakistani',
  nationalityPalauan: 'Palauan',
  nationalityPanamanian: 'Panamanian',
  nationalityPapuaNewGuinean: 'Papua New Guinean',
  nationalityParaguayan: 'Paraguayan',
  nationalityPeruvian: 'Peruvian',
  nationalityPolish: 'Polish',
  nationalityPortuguese: 'Portuguese',
  nationalityQatari: 'Qatari',
  nationalityRomanian: 'Romanian',
  nationalityRussian: 'Russian',
  nationalityRwandan: 'Rwandan',
  nationalitySaintLucian: 'Saint Lucian',
  nationalitySalvadoran: 'Salvadoran',
  nationalitySamoan: 'Samoan',
  nationalitySanMarinese: 'San Marinese',
  nationalitySanVicent: 'Saint Vincent and the Grenadines',
  nationalitySaintHelenians: 'Saint Helenians',
  nationalitySaoTomean: 'Sao Tomean',
  nationalitySaudi: 'Saudi',
  nationalitySenegalese: 'Senegalese',
  nationalitySeychellois: 'Seychellois',
  nationalitySierraLeonean: 'Sierra Leonean',
  nationalitySingaporean: 'Singaporean',
  nationalitySlovakian: 'Slovakian',
  nationalitySlovenian: 'Slovenian',
  nationalitySolomonIslander: 'Solomon Islander',
  nationalitySomali: 'Somali',
  nationalitySouthAfrican: 'South African',
  nationalitySpanish: 'Spanish',
  nationalitySriLankan: 'Sri Lankan',
  nationalitySudanese: 'Sudanese',
  nationalitySurinamer: 'Surinamer',
  nationalityStateless: 'Stateless',
  nationalitySwazi: 'Swazi',
  nationalitySwedish: 'Swedish',
  nationalitySwiss: 'Swiss',
  nationalitySyrian: 'Syrian',
  nationalityTaiwanese: 'Taiwanese',
  nationalityTajik: 'Tajik',
  nationalityTanzanian: 'Tanzanian',
  nationalityThai: 'Thai',
  nationalityTogolese: 'Togolese',
  nationalityTokelau: 'Tokelau',
  nationalityTongan: 'Tongan',
  nationalityTrinidadianOrTobagonian: 'Trinidadian or Tobagonian',
  nationalityTunisian: 'Tunisian',
  nationalityTurkish: 'Turkish',
  nationalityTurkmens: 'Turkmens',
  nationalityTuvaluan: 'Tuvaluan',
  nationalityTurksCaicos: 'Turks and Caicos Islands',
  nationalityUgandan: 'Ugandan',
  nationalityUkrainian: 'Ukrainian',
  nationalityUsMinorIslands: 'United States Minor Outlying Islands',
  nationalityUruguayan: 'Uruguayan',
  nationalityUzbekistani: 'Uzbekistani',
  nationalityVaticano: 'Vaticano',
  nationalityVenezuelan: 'Venezuelan',
  nationalityVietnamese: 'Vietnamese',
  nationalityWallisFutunaIsland: 'Wallis and Futuna Islands',
  nationalityWesternSahara: 'Western Sahara',
  nationalityYemenite: 'Yemenite',
  nationalityYugoslavian: 'Yugoslavian',
  nationalityZairense: 'Zairense',
  nationalityZambian: 'Zambian',
  nationalityZimbabwean: 'Zimbabwean',
}
