import { makeStyles } from '@material-ui/core/styles'
import { appMode } from '../../adapter'

export const useStyles = makeStyles((theme) => {
  const {
    prospectTableListLoadingContainer,
    prospectTableListEmptyListContainer,
  } = appMode().styles(theme)

  return {
    tableHead: {
      backgroundColor: theme.palette.common.white,
    },
    tableCell: {
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    tableCellWithoutData: {
      border: 'none',
      textAlign: 'center',
      padding: '2rem 0px',
      '@media (max-width: 769px)': {
        padding: '2rem 1rem',
      },
      paddingTop: '4rem',
    },
    tableCellWithOuterComercialStructureInfo: {
      border: 'none',
      textAlign: 'center',
      padding: '2rem 0px',
      '@media (max-width: 769px)': {
        padding: '2rem 1rem',
      },
    },
    noRegistersDisclaimer: {
      fontWeight: 'normal',
    },
    tableRow: {
      backgroundColor: theme.palette.grey[50],
      '&:nth-child(even)': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    clickableTableRow: {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[50],
      '&:nth-child(even)': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    loadingContainer: prospectTableListLoadingContainer,
    emptyListContainer: prospectTableListEmptyListContainer,
    loadingMoreContainer: {
      display: 'flex',
      padding: 24,
      justifyContent: 'center',
    },
    checkbox: {
      padding: theme.spacing(1),
      borderRadius: '50%',
      color: theme.palette.text.secondary,
    },
    personCommercialStructureExtraRow: {
      paddingTop: 0,
      paddingLeft: 0,
      '@media (max-width: 769px)': {
        paddingLeft: '1rem',
      },
    },
  }
})
