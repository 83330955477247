import { getUserInfo } from './auth'
import { LOCAL_STORAGE_PREFIX } from '../Constants'
import { COUNTRY_TO_LANGUAGE_TAG, COMPANY_TO_LANGUAGE_TAG } from './common'
import { getLanguage } from './getLanguage'

export const isLocaleDefinedByBrowserLanguage = () => (
  localStorage.getItem(`${LOCAL_STORAGE_PREFIX}hasBrowserLanguage`) === 'true'
)

export const getAppLocale = async () => {
  const userInfo = await getUserInfo()
  const company = localStorage.getItem('company')
  const country = localStorage.getItem('country')
  const companyLanguages = COMPANY_TO_LANGUAGE_TAG[company]

  if (companyLanguages && companyLanguages[country?.toLocaleLowerCase()]) {
    return getLanguage(company, country)
  }
  if (userInfo?.country) {
    const userInfoCountry = userInfo.country.toLowerCase()
    return COUNTRY_TO_LANGUAGE_TAG[userInfoCountry] ?? userInfoCountry
  }

  if (isLocaleDefinedByBrowserLanguage()) {
    return navigator.language.toLowerCase()
  }

  const localeFromLocalStorage = localStorage.getItem(
    `${LOCAL_STORAGE_PREFIX}language`,
  )
  if (localeFromLocalStorage) {
    return localeFromLocalStorage.toLowerCase()
  }

  return 'en'
}
