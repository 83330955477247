export default {
  nationalityAfghan: 'Afegão',
  nationalityAlbanian: 'Albanês',
  nationalityAmerican: 'Norte Americano',
  nationalityAndorran: 'Andorrano',
  nationalityAngolan: 'Angolano',
  nationalityAnguillan: 'Anguilla',
  nationalityAntartic: 'Antártica',
  nationalityAntiguans: 'Antiguano',
  nationalityArab: 'Árabe',
  nationalityArubean: 'De Aruba',
  nationalityAlgerian: 'Argelino',
  nationalityArgentinean: 'Argentino',
  nationalityArmenian: 'Armênio',
  nationalityAustralian: 'Australiano',
  nationalityAustrian: 'Austríaco',
  nationalityAzerbaijani: 'Azerbeidjano',
  nationalityBahamian: 'Baamiano',
  nationalityBahraini: 'Bareinita',
  nationalityBangladeshi: 'Bangladesh',
  nationalityBarbadian: 'Barbadiano',
  nationalityBatswana: 'Betchuano',
  nationalityBelarusian: 'Bielo-russo',
  nationalityBelgian: 'Belga',
  nationalityBelizean: 'Belizenho',
  nationalityBeninese: 'Beninense',
  nationalityBermudian: 'Bermudense',
  nationalityBhutanese: 'Butanês',
  nationalityBolivian: 'Boliviano',
  nationalityBosnian: 'Bósnio',
  nationalityBouvetIsland: 'Ilhas Bouvet',
  nationalityBrazilian: 'Brasileiro',
  nationalityBritish: 'Britânico',
  nationalityBruneian: 'Bruneano',
  nationalityBulgarian: 'Búlgaro',
  nationalityBurkinabe: 'Burkina-Faso',
  nationalityBurmese: 'Birmanês',
  nationalityBurundian: 'Burundinês',
  nationalityCambodian: 'Cambojano',
  nationalityCameroonian: 'Camaronês',
  nationalityCanadian: 'Canadense',
  nationalityCapeVerdean: 'Cabo-verdiano',
  nationalityCentralAfrican: 'Centro-africano',
  nationalityChadian: 'Chadiano',
  nationalityChilean: 'Chileno',
  nationalityChinese: 'Chinês',
  nationalityColombian: 'Colombiano',
  nationalityComoran: 'Comorense',
  nationalityCongolese: 'Congolês',
  nationalityCookIsland: 'Ilhas Cook',
  nationalityCostaRican: 'Costarriquenho',
  nationalityCorean: 'Coreano',
  nationalityCroatian: 'Croata',
  nationalityCuban: 'Cubano',
  nationalityCypriot: 'Cipriota',
  nationalityCzech: 'Checo',
  nationalityDanish: 'Dinamarquês',
  nationalityDjibouti: 'Djibutiano',
  nationalityDominican: 'Dominicano',
  nationalityDutch: 'Holandês',
  nationalityEastTimorese: 'Timor Leste',
  nationalityEcuadorean: 'Equatoriano',
  nationalityEgyptian: 'Egípcio',
  nationalityEquatorialGuinean: 'Da Guiné Equatorial',
  nationalityEritrean: 'Da Eritreia',
  nationalityEstonian: 'Estoniano',
  nationalityEthiopian: 'EtÃ­ope',
  nationalityFijian: 'Fijiano',
  nationalityFilipino: 'Filipino',
  nationalityFinnish: 'Finlandês',
  nationalityFrench: 'Francês',
  nationalityGabonese: 'Gabonês',
  nationalityGambian: 'Gambiano',
  nationalityGeorgian: 'Georgiano',
  nationalityGerman: 'Alemão',
  nationalityGibraltar: 'Gibraltar',
  nationalityGhanaian: 'Ganense',
  nationalityGreek: 'Grego',
  nationalityGrenadian: 'Granadino',
  nationalityGuatemalan: 'Guatemalteco',
  nationalityGuineaBissauan: 'Guiné-Bissau',
  nationalityGuinean: 'Guineano',
  nationalityGuyanese: 'Guianense',
  nationalityHaitian: 'Haitiano',
  nationalityHonduran: 'Hondurenho',
  nationalityHongKong: 'Hong Kong',
  nationalityHungarian: 'Húngaro',
  nationalityIKiribati: 'De Kiribati',
  nationalityIcelander: 'Islandês',
  nationalityIndian: 'Indiano',
  nationalityIndonesian: 'Indonésio',
  nationalityIranian: 'Iraniano',
  nationalityIraqi: 'Iraquiano',
  nationalityIrish: 'Irlandês',
  nationalityIsraeli: 'Israelita',
  nationalityItalian: 'Italiano',
  nationalityIvorian: 'Costa do Marfim',
  nationalityJamaican: 'Jamaicano',
  nationalityJapanese: 'Japonês',
  nationalityJordanian: 'Jordaniano',
  nationalityKazakhstani: 'Do Cazaquistão',
  nationalityKenyan: 'Queniano',
  nationalityKittianAndNevisian: 'De São Cristovão e Nevis',
  nationalityKuwaiti: 'Kuwaitiano',
  nationalityKyrgyz: 'Quirguiz',
  nationalityLaotian: 'Laosiano',
  nationalityLatvian: 'Letão',
  nationalityLebanese: 'Libanês',
  nationalityLiberian: 'Liberiano',
  nationalityLibyan: 'Líbio',
  nationalityLiechtensteiner: 'Liechtenstein',
  nationalityLithuanian: 'Lituano',
  nationalityLuxembourger: 'Luxemburguês',
  nationalityMacedonian: 'Macedônio',
  nationalityMalagasy: 'Malgaxe',
  nationalityMalawian: 'Malaviano',
  nationalityMalaysian: 'Malaio',
  nationalityMaldivian: 'Maldivo',
  nationalityMalian: 'Malinês',
  nationalityMaltese: 'Maltês',
  nationalityMarshallese: 'Ilhas Marshall',
  nationalityMauritanian: 'Mauritano',
  nationalityMauritian: 'Mauriciano',
  nationalityMexican: 'Mexicano',
  nationalityMicronesian: 'Micronésio',
  nationalityMoldovan: 'Moldávio',
  nationalityMonacan: 'Monegasco',
  nationalityMontserrat: 'Montserrat',
  nationalityMongolian: 'Mongol',
  nationalityMoroccan: 'Marroquino',
  nationalityMosotho: 'Lesoto',
  nationalityMozambican: 'Moçambicano',
  nationalityNamibian: 'Namibiano',
  nationalityNauruan: 'Nauruano',
  nationalityNepalese: 'Nepalês',
  nationalityNewZealander: 'Neozelandês',
  nationalityNiVanuatu: 'Vanuatense',
  nationalityNicaraguan: 'Nicaraguense',
  nationalityNigerian: 'Nigeriano',
  nationalityNigerien: 'Nigerino',
  nationalityNiueIsland: 'Ilhas Niue',
  nationalityNorwegian: 'Norueguês',
  nationalityNorfolkIsland: 'Ilhas Norfolk',
  nationalityOmani: 'Omani',
  nationalityPakistani: 'Paquistanês',
  nationalityPalauan: 'Palaus',
  nationalityPanamanian: 'Panamenho',
  nationalityPapuaNewGuinean: 'Papuásio',
  nationalityParaguayan: 'Paraguaio',
  nationalityPeruvian: 'Peruano',
  nationalityPolish: 'Polonês',
  nationalityPortuguese: 'Português',
  nationalityQatari: 'Catariano',
  nationalityRomanian: 'Romeno',
  nationalityRussian: 'Russo',
  nationalityRwandan: 'Ruandês',
  nationalitySaintLucian: 'Santa-lucense',
  nationalitySalvadoran: 'Salvadorenho',
  nationalitySamoan: 'Samoano',
  nationalitySanMarinese: 'Samarinês',
  nationalitySanVicent: 'São-vicentino',
  nationalitySaintHelenians: 'Santa Helena',
  nationalitySaoTomean: 'São-tomense',
  nationalitySaudi: 'Saudita',
  nationalitySenegalese: 'Senegalês',
  nationalitySeychellois: 'Seichelense',
  nationalitySierraLeonean: 'Leonês',
  nationalitySingaporean: 'Cingapuriano',
  nationalitySlovakian: 'Eslovaco',
  nationalitySlovenian: 'Esloveno',
  nationalitySolomonIslander: 'Salomônico',
  nationalitySomali: 'Somali',
  nationalitySouthAfrican: 'Sul-africano',
  nationalitySpanish: 'Espanhol',
  nationalitySriLankan: 'Cingalês',
  nationalitySudanese: 'Sudanês',
  nationalitySurinamer: 'Surinamês',
  nationalityStateless: 'Apátrida',
  nationalitySwazi: 'Suazi',
  nationalitySwedish: 'Sueco',
  nationalitySwiss: 'Suíço',
  nationalitySyrian: 'Sírio',
  nationalityTaiwanese: 'De Taiwan',
  nationalityTajik: 'Tadjique',
  nationalityTanzanian: 'Tanzaniano',
  nationalityThai: 'Tailandês',
  nationalityTogolese: 'Togolêse',
  nationalityTokelau: 'Ilhas Tokelau',
  nationalityTongan: 'Tongonês',
  nationalityTrinidadianOrTobagonian: 'De Trinidad e Tobago',
  nationalityTunisian: 'Tunisino',
  nationalityTurkish: 'Turco',
  nationalityTurkmens: 'Turcomano',
  nationalityTuvaluan: 'Tuvaluano',
  nationalityTurksCaicos: 'Ilhas Turcos e Caicos',
  nationalityUgandan: 'Ugandense',
  nationalityUkrainian: 'Ucraniano',
  nationalityUsMinorIslands: 'Ilhas Minor Outlying',
  nationalityUruguayan: 'Uruguaio',
  nationalityUzbekistani: 'Uzbeque',
  nationalityVaticano: 'Vaticano',
  nationalityVenezuelan: 'Venezuelano',
  nationalityVietnamese: 'Vietnamita',
  nationalityWallisFutunaIsland: 'Ilhas Wallis e Futuna',
  nationalityWesternSahara: 'Sara ocidental',
  nationalityYemenite: 'Iemenita',
  nationalityYugoslavian: 'Iugoslavo',
  nationalityZairense: 'Zairense',
  nationalityZambian: 'Zambiano',
  nationalityZimbabwean: 'Zimbabuano',
}
