import React, { useState } from 'react'
import {
 Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, Divider,
} from '@naturacosmeticos/natds-web'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import { SubStatusTitle } from '../../../models/substatus'
import { useResponsiveness } from '../../../hooks/responsive'
import { useToggle } from '../../../hooks/useToggle'
import { reprocessRegistrationService } from '../../../services/people'
import Button from '../../../components/Buttons/Button'

const useStyles = makeStyles({
  reprocessButton: {
    padding: '0 3rem',
    marginLeft: '2rem',
  },
  reprocessButtonMobile: {
    marginLeft: '10px',
  },
  reprocessActionsButtonMobile: {
    width: '100%',
  },
  reprocessActionsSpacing: {
    width: '100%',
    marginTop: '8px',
  },
  reprocessIcon: {
    paddingRight: '5px',
  },
  reprocessLoading: {
    display: 'flex',
  },
  dialogActionsMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
  },
})

const Reprocess = ({ subStatus }) => {
  const { REPROCESS_BUTTON } = useToggle()
  const { isDesktop, isMobile } = useResponsiveness()
  const { id } = useParams()
  const classes = useStyles()
  const hasSubStatusError = subStatus === SubStatusTitle.PENDING_COMMERCIAL_STRUCTURE_NOT_COVERED
    || subStatus === SubStatusTitle.WORKFLOW_EXECUTION_ERROR
    || subStatus === SubStatusTitle.INTEGRATION_ERROR_WITH_GERA
  const shouldShowReprocessButton = REPROCESS_BUTTON && hasSubStatusError

  const [reprocessDialogOpened, setReprocessDialogOpened] = useState(false)
  const [reprocessing, setReprocessing] = useState(false)

  const doReprocess = async () => {
    setReprocessing(true)

    try {
      await reprocessRegistrationService(id)
      setTimeout(() => global.location.reload(), 5000)
    } catch (e) {
      setReprocessing(false)
    }
  }

  return (
    shouldShowReprocessButton ? (
      <>
        <Button
          variant={isDesktop ? 'outlined' : 'text'}
          color={isDesktop ? 'primary' : ''}
          className={isDesktop ? classes.reprocessButton : classes.reprocessButtonMobile}
          id="reprocess-register"
          onClick={() => setReprocessDialogOpened(true)}
          disabled={reprocessing}
          aria-label="reprocess button"
          size="medium"
        >
          <Icon
            name="outlined-action-update"
            size="tiny"
            className={isDesktop ? classes.reprocessIcon : null}
          />
          {isDesktop && <FormattedMessage id="reprocessButtonLabel" defaultMessage="Reprocess" />}
        </Button>
        <Dialog disableBackdropClick={reprocessing} open={reprocessDialogOpened} onClose={() => setReprocessDialogOpened(false)}>
          <DialogTitle>
            <FormattedMessage id="reprocessDialogTitle" defaultMessage="Are you sure you want to reprocess?" />
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DialogContentText>
              <FormattedMessage
                id="reprocessDialogText"
                defaultMessage="This will reprocess the registration, are you sure you want to do that?"
              />
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <div className={isMobile ? classes.dialogActionsMobile : null}>
              <Button
                variant="contained"
                color="primary"
                className={isDesktop ? classes.reprocessButton : classes.reprocessActionsButtonMobile}
                id="reprocess-register-confirm"
                onClick={doReprocess}
                loading={reprocessing}
                disabled={reprocessing}
              >
                <FormattedMessage id="reprocessDialogConfirm" defaultMessage="Yes, I'm sure" />
              </Button>
              <Button
                variant="outlined"
                className={isDesktop ? classes.reprocessButton : classes.reprocessActionsSpacing}
                onClick={() => setReprocessDialogOpened(false)}
                disabled={reprocessing}
              >
                <FormattedMessage id="reprocessDialogBack" defaultMessage="Cancel" />
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    ) : null
  )
}

export default Reprocess
