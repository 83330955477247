import React, { useState } from 'react'
import { Icon } from '@naturacosmeticos/natds-web'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'

import IconButton from '../Buttons/IconButton'
import { useStyles } from './Notifications.styles'

export const NotificationContext = React.createContext({
  notify: () => {},
})

const NotificationWrapper = ({ children }) => {
  const classes = useStyles()

  const [isOpen, setOpen] = useState(false)
  const [message, setMessage] = useState(false)

  const notify = (message) => {
    setOpen(true)
    setMessage(message)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={(
          <span className={classes.message}>
            <Typography>{message}</Typography>
          </span>
        )}
        action={(
          <IconButton color="inherit" onClick={handleClose}>
            <Icon name="filled-action-cancel" size="tiny" />
          </IconButton>
        )}
      />
    </NotificationContext.Provider>
  )
}

export const useNotification = () => React.useContext(NotificationContext)

export default NotificationWrapper
