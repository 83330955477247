import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import Dialog from '../../../../../components/Dialog'
import Button from '../../../../../components/Buttons/Button'

import { useStyles } from './CancelRegistrationButtonWithDialog.styles'
import { useTenantConfigs } from '../../../../../hooks/useTenantConfigs/useTenantConfigs'
import CancelRegistrationTitleDialog from './CancelRegistrationTitleDialog'
import ActionButtonsDialog from './ActionButtonsDialog'
import { renunciatePeopleRole } from '../../../../../services/people'

export const CancelRegistrationButtonWithDialog = ({
 name, personId, personRoleId, renunciateReason, redirectURLCancelRegistration,
}) => {
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { configs } = useTenantConfigs()
  const company = configs?.company?.id
  const country = configs?.country?.alpha2Code

  const handleButtonClick = () => {
    setIsDialogOpen(true)
  }

  const handleRenunciatePersonRole = async () => {
    try {
      setIsLoading(true)
      const payload = {
        renunciateReason,
        personId,
        personRoleId,
        headers: {
          company,
          country,
          sourceSystem: '1',
        },
      }
      await renunciatePeopleRole(payload)
    } catch (error) {
      console.error(error)
    } finally {
      setIsDialogOpen(false)
      setIsLoading(false)
      window.location.replace(redirectURLCancelRegistration)
    }
  }

  return (
    <div className={classes.buttonContainer}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleButtonClick()}
        loading={isLoading}
        disabled={isLoading || !renunciateReason}
        className={classes.buttonRenunciate}
      >
        <FormattedMessage id="cancelRegister" defaultMessage="Cancel Registration" />
      </Button>
      <Dialog
        data-testid="cancelRegister-dialog"
        open={isDialogOpen}
        title={<CancelRegistrationTitleDialog name={name} />}
        actions={(
          <ActionButtonsDialog
            renunciateReason={renunciateReason}
            handleRenunciatePersonRole={handleRenunciatePersonRole}
            isLoadingRenunciatePersonRolePatch={isLoading}
            isOpen={(value) => setIsDialogOpen(value)}
          />
        )}
        dividers={false}
      />
    </div>
  )
}
