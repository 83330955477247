import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Container } from '@naturacosmeticos/natds-web'
import Button from '../../../../components/Buttons/Button'
import { SubStatusTitle } from '../../../../models/substatus'
import messages_es_MX from '../../../../locales/es-mx/messages'
import Countdown from './Countdown'
import SendLinkDialog from './SendLinkDialog'
import { useStyles } from './SendLink.styles'
import { useResponsiveness } from '../../../../hooks/responsive'

const SendLinkButton = ({ subStatus, classNameBtn }) => {
  const isPendingDigitalSignature = subStatus === SubStatusTitle.PENDING_DIGITAL_SIGNATURE

  const [isDialogOpened, toggleDialog] = useState(false)
  const [isCountdownVisible, toggleCountdown] = useState(false)
  const [isButtonVisible, toggleButtonVisibility] = useState(true)

  const { isDesktop } = useResponsiveness()

  const classes = useStyles()

  const openDialog = () => toggleDialog(true)
  const closeDialog = () => toggleDialog(false)
  const showCountdown = () => toggleCountdown(true)
  const hideCountdown = () => toggleCountdown(false)
  const showButton = () => toggleButtonVisibility(true)
  const hideButton = () => toggleButtonVisibility(false)

  const onCloseDialog = () => {
    closeDialog()
    showCountdown()
    hideButton()
  }

  const onCloseCountdown = () => {
    showButton()
    hideCountdown()
  }

  return (
    <>
      { isPendingDigitalSignature && (
        <Container className={classes.sendLinkButtonContainer}>
          { isButtonVisible && (
            <Button
              id="resend-link-button"
              variant="contained"
              color="primary"
              onClick={openDialog}
              aria-label="send link button"
              className={classNameBtn}
              fullWidth={isDesktop}
              size="small"
            >
              <FormattedMessage id="resendLinkButtonText" defaultMessage="Resend Link" />
            </Button>
          )}

          <SendLinkDialog open={isDialogOpened} onClose={onCloseDialog} />

          <Countdown
            timerMessage={messages_es_MX.countdownMessage}
            open={isCountdownVisible}
            onClose={onCloseCountdown}
          />
        </Container>
      )}
    </>
  )
}

export default SendLinkButton
