import { useIntl } from 'react-intl'
import {
  getNaturaArgentina,
  getNaturaBrazil,
  getNaturaChile,
  getNaturaColombia,
  getNaturaPeru,
  getNaturaMalaysia,
  getAvonArgentina,
  getAvonMexico,
  getNaturaMexico,
  getNaturaEcuador,
} from './useTenantConfigs/configs/index'

const useCountriesConfig = () => {
  const intl = useIntl()
  const COUNTRIES = [
    {
      name: intl.formatMessage({
        id: 'AVON_ARG',
        defaultMessage: 'Avon Argentina',
      }),
      configs: getAvonArgentina(),
    },
    {
      name: intl.formatMessage({
        id: 'AVON_MEX',
        defaultMessage: 'Avon Mexico',
      }),
      configs: getAvonMexico(),
    },
    {
      name: intl.formatMessage({
        id: 'MEX',
        defaultMessage: 'Mexico',
      }),
      configs: getNaturaMexico(),
    },
    {
      name: intl.formatMessage({
        id: 'BRA',
        defaultMessage: 'Brazil',
      }),
      configs: getNaturaBrazil(),
    },
    {
      name: intl.formatMessage({
        id: 'PER',
        defaultMessage: 'Peru',
      }),
      configs: getNaturaPeru(),
    },
    {
      name: intl.formatMessage({
        id: 'COL',
        defaultMessage: 'Colombia',
      }),
      configs: getNaturaColombia(),
    },

    {
      name: intl.formatMessage({
        id: 'MYS',
        defaultMessage: 'Malaysia',
      }),
      configs: getNaturaMalaysia(),
    },
    {
      name: intl.formatMessage({
        id: 'CHL',
        defaultMessage: 'Chile',
      }),
      configs: getNaturaChile(),
    },
    {
      name: intl.formatMessage({
        id: 'ARG',
        defaultMessage: 'Argentina',
      }),
      configs: getNaturaArgentina(),
    },
    {
      name: intl.formatMessage({
        id: 'ECU',
        defaultMessage: 'Ecuador',
      }),
      configs: getNaturaEcuador(),
    },
  ]

  return COUNTRIES
}

export default useCountriesConfig
