import { getBffApiGatewayConnection } from './config'

export const fetchAvatarInfo = async ({ personId }) => {
  const connection = await getBffApiGatewayConnection()
  const {
    data: {
      name,
      nameInitials: initials,
      code: sellerId,
      roles,
    },
  } = await connection.get(`/accounts/register-reports/v1/user/avatar-information/${personId}`)
  return {
    name,
    initials,
    sellerId,
    roles,
  }
}
