import { sortFilterOptionsByI18nNames } from '../../../models/filterOptions'

export const getOriginFilterOptions = (intl) => {
  const unsortedOptions = [
    {
      id: 12,
      name: intl.formatMessage({ id: 'registerOriginAvonMigration', defaultMessage: 'Avon' }),
    },
  ]
  return sortFilterOptionsByI18nNames(unsortedOptions)
}
