import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useToggle } from './useToggle'
import {
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_COMPANY_KEY,
  STORAGE_COUNTRY_ALPHA3_CODE_KEY,
  STORAGE_COUNTRY_KEY,
  STORAGE_EXPIRESIN_KEY,
  STORAGE_SSO_TOKEN_KEY,
  STORAGE_ID_TOKEN_KEY,
  STORAGE_USER_INFO_KEY,
  STORAGE_DEVICE_ID_KEY,
} from '../helpers/auth'
import { useTenantConfigs, getCountryConfiguration } from './useTenantConfigs'
import { LOCAL_STORAGE_PREFIX } from '../Constants'
import { getBffApiGatewayConnection } from '../services/config'

export const useCognito = () => {
  const [loading, setLoading] = useState(true)
  const [isActive, setIsActive] = useState()
  const { configs, isLoading } = useTenantConfigs()

  const location = useLocation()
  const toggles = useToggle()

  useEffect(() => {
    if (isTogglesValid() && !isLoading) {
      setLoading(false)
      setIsHostedUiActive()
      saveCountryData()
    } else {
      setLoading(true)
    }
  }, [location.search, toggles, isLoading])

  const isTogglesValid = () => {
    if (!toggles || !Object.keys(toggles).length) {
      return false
    }
    return true
  }

  const setIsHostedUiActive = () => {
    if (isHostedUIEnabledBySSOToken()) {
      setIsActive(true)
    } else {
      setIsHostedUiActiveByToggle()
    }
  }

  const isHostedUIEnabledBySSOToken = () => {
    const isEnabledInEnvironments = isHostedUIEnabledInEnvironments()
    const ssoToken = getSSOToken()
    if (ssoToken && isEnabledInEnvironments) {
      return true
    }

    return false
  }

  const setIsHostedUiActiveByToggle = () => {
    try {
      const isEnabledInEnvironments = isHostedUIEnabledInEnvironments()
      const configurations = getHostedUiConfigurations()
      const isEnable = configurations.ENABLE_HOSTED_UI && isEnabledInEnvironments
      setIsActive(isEnable)
    } catch (error) {
      setIsActive(false)
    }
  }

  const isHostedUIEnabledInEnvironments = () => process.env.REACT_APP_HOSTED_UI_ACTIVE === 'true'

  const saveCountryData = () => {
    try {
      const company = getCompany()
      const country = getCountry()

      const configurations = getCountryConfiguration(company, country)
      const {
        country: { alpha3Code, alpha2Code },
        company: { id: companyId },
      } = configurations

      saveDataInLocalStorage(alpha2Code, alpha3Code, companyId)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Could not get country - company configs: ', error)
    }
  }

  const saveDataInLocalStorage = (alpha2Code, alpha3Code, companyId) => {
    localStorage.setItem(STORAGE_COUNTRY_ALPHA3_CODE_KEY, alpha3Code)
    localStorage.setItem(STORAGE_COUNTRY_KEY, alpha2Code.toUpperCase())
    localStorage.setItem(STORAGE_COMPANY_KEY, companyId)
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}language`, alpha2Code)
  }

  const getSSOToken = () => {
    const query = new URLSearchParams(location.search)
    const ssoToken = query.get('sso_token') ?? localStorage.getItem(STORAGE_SSO_TOKEN_KEY)
    return ssoToken
  }

  const getHostedUiConfigurations = () => {
    const company = getCompany()
    const country = getCountry()
    const configurations = toggles.AUTH?.[company]?.[country]
    validateToggle(configurations)
    return { ...configurations, company: company.toLocaleLowerCase(), countryAlpha3Code: country }
  }

  const getCompany = () => {
    const query = new URLSearchParams(location?.search)
    const company = query?.get('company') ?? configs?.company.name

    if (!company) {
      throw new Error("hosted ui's company not defined in search params")
    }

    return company.toUpperCase()
  }

  const getCountry = () => {
    const query = new URLSearchParams(location?.search)
    const country = query?.get('country') ?? configs?.country.alpha3Code

    if (!country) {
      throw new Error("hosted ui's country not defined in search params")
    }

    return country.toUpperCase()
  }

  const validateToggle = (toggle) => {
    if (typeof toggle === 'undefined') {
      throw new Error('toggle not found')
    } else if (typeof toggle === 'object' && !Object.keys(toggle).length) {
      throw new Error('toggle object is empty')
    }
  }

  const isHostedUIEnabled = () => {
    if (typeof isActive === 'undefined') {
      return false
    }
    return isActive
  }

  const clearLocalstorage = () => {
    localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY)
    localStorage.removeItem(STORAGE_EXPIRESIN_KEY)
    localStorage.removeItem(STORAGE_SSO_TOKEN_KEY)
    localStorage.removeItem(STORAGE_COUNTRY_ALPHA3_CODE_KEY)
    localStorage.removeItem(STORAGE_ID_TOKEN_KEY)
    localStorage.removeItem(STORAGE_COUNTRY_KEY)
    localStorage.removeItem(STORAGE_COMPANY_KEY)
    localStorage.removeItem(STORAGE_USER_INFO_KEY)
    localStorage.removeItem(STORAGE_DEVICE_ID_KEY)
  }

  const signOutUser = async () => {
    try {
      const connection = await getBffApiGatewayConnection(undefined, 'auth')

      const ssoToken = getSSOToken()

      const body = {
        token: ssoToken,
      }
      if (configs.auth?.useDeviceId) {
        body.deviceId = localStorage.getItem(STORAGE_DEVICE_ID_KEY)
      }

      clearLocalstorage()

      await connection.post('/accounts/register-reports/v1/sign-out', body)
    } catch (error) {
      clearLocalstorage()
      // eslint-disable-next-line no-console
      console.error('Could not sign out user out: ', error)
    }
  }

  return {
    isEnable: isActive,
    loading,
    isHostedUIEnabled,
    getSSOToken,
    signOutUser,
    saveDataInLocalStorage,
  }
}
