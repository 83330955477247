import { makeStyles } from '@material-ui/core/styles'
import { appMode } from '../../adapter'

export const useStyles = makeStyles((theme) => {
  const { prospectTableListError } = appMode().styles(theme)
  return {
    title: {
      fontSize: '24px',
      fontWeight: 400,
      width: '260px',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '14px',
      width: '198px',
      textAlign: 'center',
    },
    errorContainer: {
      width: 'auto',
    },
    error: prospectTableListError,
  }
})
