import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CompanyId } from '../../../models/company-id'
import { CountryId } from '../../../models/country-id'
import { TENANT_ID } from '../tenantId'
import { getDefault } from './defaultConfigs'

import { statusOptions } from '../../../models/filterOptions'

export const getNaturaChile = () => ({
  ...getDefault(),
  tenantId: TENANT_ID.NATURA_CHILE,
  country: {
    alpha2Code: CountryId.CHILE,
    alpha3Code: 'CHL',
    language: 'es',
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: '/login?country=CHL&company=NATURA',
      externalLink: false,
    },
  },
  filter: {
    origin: true,
  },
  showEditButtonDetails: true,
  redirectUrlToLogin: {
    url: async () => getConfigsMFEFirebase('HOSTED_UI_AUTHENTICATION_URL'),
    clientId: async () => getConfigsMFEFirebase('CLIENT_ID_COGNITO'),
  },
  showChangeGroupButton: true,
  roles: {
    gn: {
      functionId: 17,
      roleId: 6,
    },
    leader: {
      functionId: 4,
      roleId: 1,
    },
    cb: {
      functionId: 1,
      roleId: 1,
    },
  },
  canChangeGroup: [statusOptions.suggestedForStart, statusOptions.waitingFirstOrder],
})
