import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import TableResults from './TableResults'
import { useStyles } from './TableList.styles'
import Divider from '../Divider'

const SearchResultList = ({
  columns,
  data,
  hasNextPage,
  loadingMore,
  onLoadMoreItems,
  onRowClick,
  onSelectRow,
}) => {
  const classes = useStyles()
  const withStructure = data.filter((item) => item.isWithinStructure)
  const outOfStructure = data.filter((item) => !item.isWithinStructure)
  const tableResultProps = {
    columns,
    hasNextPage,
    loadingMore,
    onLoadMoreItems,
    onRowClick,
    onSelectRow,
  }

  return (
    <>
      {withStructure.length > 0 ? (
        <TableResults
          {...tableResultProps}
          data={withStructure}
        />
      ) : (
        <EmptySearchResultMessage
          messageId="noResultsOnCommercialStructure"
          defaultMessage="No results found on your commercial structure"
        />
      )}
      {outOfStructure.length > 0 && (
        <>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCellWithOuterComercialStructureInfo} colSpan={6}>
                <Divider variant="middleText">
                  <Typography color="textPrimary" variant="subtitle2" noWrap>
                    <FormattedMessage
                      defaultMessage="Results outside the commercial structure"
                      id="resultsOutsideStructure"
                    />
                  </Typography>
                </Divider>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableResults
            {...tableResultProps}
            prependRow={(personData) => (
              <PersonCommercialStructureExtraRow person={personData} key={`extra_row_${personData.personId}`} />
            )}
            data={outOfStructure}
          />
        </>
      )}
    </>
  )
}

const EmptySearchResultMessage = ({ messageId, defaultMessage }) => {
  const classes = useStyles()
  return (
    <TableBody>
      <TableRow>
        <TableCell className={classes.tableCellWithoutData} colSpan={6}>
          <Typography
            className={classes.noRegistersDisclaimer}
            variant="body2"
          >
            <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

const PersonCommercialStructureExtraRow = ({ person }) => {
  const classes = useStyles()

  if (person.commercialStructure) {
    return (
      <TableRow>
        <TableCell
          className={classes.personCommercialStructureExtraRow}
          colSpan={7}
        >
          <Typography variant="body2">
            <FormattedMessage
              id="registerAllocatedOnCommercialStructure"
              defaultMessage="Register allocated on commercial structure: "
            />
            {person.commercialStructure}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  return null
}

export default SearchResultList
