import { makeStyles } from '@material-ui/core/styles'
import { deviceMediaQuerySizes } from '../../../../../../helpers/deviceSizes'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minHeight: '100%',
  },
  content: {
    minWidth: '30rem',
    maxWidth: '50rem',
  },
  title: {
    fontSize: '1.4rem',
    margin: '1rem 0',
    color: theme.palette.primary.main,
  },
  message: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: theme.palette.grey[600],
  },
  actionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    justifyContent: 'center',
    margin: '1rem 0',
    width: '100%',
    [`@media ${deviceMediaQuerySizes.tablet}`]: {
      gridTemplateColumns: 'repeat(2, minmax(200px, 200px))',
    },
  },
}))
