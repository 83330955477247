import { makeStyles } from '@material-ui/core/styles'
import { deviceMediaQuerySizes } from '../../../../../helpers/deviceSizes'

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    justifySelf: 'center',
    minWidth: 200,
    maxWidth: 200,
    [`@media ${deviceMediaQuerySizes.laptopL}`]: {
      alignSelf: 'center',
    },
  },
}))
