import { getBffApiGatewayConnection } from './config'
import { getUserInfo } from '../helpers/auth'

export const getGroupsService = async () => {
  const { personId } = await getUserInfo()

  const connection = await getBffApiGatewayConnection()

  const response = await connection.get(
    `/accounts/register-reports/v1/user/${personId}/groups`,
  )

  return response.data
}
