import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCognitoAccessToken, STORAGE_SSO_TOKEN_KEY } from '../../../helpers/auth'
import { ROOT_ROUTE } from '../../../Routes'
import { getAccessTokenStart } from '../../../redux/auth/actions'
import { ScreenLoader } from '../../../components/ScreenLoader/ScreenLoader'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'
import { getDeviceIdParam, redirectToHostedUi } from './redirect-to-hosted-ui'

const Cognito = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { configs } = useTenantConfigs()

  const token = getCognitoAccessToken()

  useEffect(() => {
    if (isAuthenticated()) {
      history.push(getTarget())
    }
  }, [])

  useEffect(() => {
    if (!isAuthenticated() && configs) {
      authenticate()
    }
  }, [configs])

  const isAuthenticated = () => !!(token && typeof token !== 'undefined')

  const authenticate = () => {
    try {
      const ssoToken = getSSOToken()
      const deviceId = getDeviceIdParam(location, configs)
      dispatch(getAccessTokenStart({ ssoToken, deviceId, target: getTarget() }))
    } catch (error) {
      redirectToHostedUi(location, configs)
    }
  }

  const getSSOToken = () => {
    const query = new URLSearchParams(location.search)
    const ssoToken = query.get('sso_token') ?? localStorage.getItem(STORAGE_SSO_TOKEN_KEY)
    if (!ssoToken) {
      throw new Error('cannot get sso token')
    }
    localStorage.setItem(STORAGE_SSO_TOKEN_KEY, ssoToken)
    return ssoToken
  }

  const getTarget = () => {
    const query = new URLSearchParams(location.search)
    return query.get('target') ? `/${query.get('target')}` : ROOT_ROUTE
  }

  return <ScreenLoader />
}

export default Cognito
