import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(1.5)}px 0`,
    '@media (min-width: 769px)': {
      display: 'flex',
    },
  },
  textField: {
    flexGrow: 2,
    minWidth: 248,
    paddingLeft: theme.spacing(1),
    '@media (max-width: 769px)': {
      margin: theme.spacing(1),
      paddingLeft: 0,
    },
  },
  textFieldAdornment: {
    cursor: 'pointer',
  },
  fieldContainer: {
    '@media (max-width: 769px)': {
      margin: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
  },
  filterWrapper: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    paddingRight: theme.spacing(1.5),
  },
}))

export const style = {
  textFieldInput: { fontSize: 16, fontFamily: '"Roboto"' },
}
