import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CompanyId } from '../../../models/company-id'
import { CountryId } from '../../../models/country-id'
import { TENANT_ID } from '../tenantId'

export const getAvonBrazil = () => ({
  tenantId: TENANT_ID.AVON_BRAZIL,
  country: {
    alpha2Code: CountryId.BRAZIL,
    alpha3Code: 'BRA',
    language: 'pt',
  },
  company: {
    name: 'AVON',
    id: CompanyId.AVON,
    logout: {
      buttonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/logout`,
      externalLink: true,
    },
  },
  header: {
    hasRegisterButtonLink: true,
    registerButtonLinkHref: `${process.env.REACT_APP_GLOBAL_ACCOUNT_URL}/br/auto`,
    hasLogout: true,
  },
  profile: {
    hasNationalityState: true,
    shouldDisplayThreeNames: true,
    shouldDisplaySendLinkButton: true,
  },
  auth: {
    useDeviceId: true,
  },
  redirectUrlToLogin: {
    url: async () => getConfigsMFEFirebase('HOSTED_UI_AUTHENTICATION_URL'),
    clientId: async () => getConfigsMFEFirebase('CLIENT_ID_COGNITO'),
  },
  filter: {
    origin: true,
    restart: true,
  },
})
