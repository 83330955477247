import { themes } from '@naturacosmeticos/natds-web'
import { buildTheme } from '@naturacosmeticos/natds-react'
import { CompanyId } from '../../models/company-id'
import { validateCompany, validateCountry, validateDesignEloIsActive } from '../validateCountryId'
import { getCompanyIdByBrowserUrl } from '../../helpers/getCompanyIdByBrowserUrl'

export const getTheme = () => {
  const companyName = getCompanyIdByBrowserUrl()

  const isCountrySelectionAR = window?.location?.href?.includes('country-selection') && validateCountry('AR')

  if (validateDesignEloIsActive()) {
    const beautyConsultantTheme = buildTheme('consultoriaDeBeleza', 'light')
    return themeMap(themes.natura.light, beautyConsultantTheme)
  }

  if (companyName === 'AVON' || (validateCompany(CompanyId.AVON) && !isCountrySelectionAR)) {
    const beautyConsultantTheme = buildTheme('avon_v2', 'light')
    return themeMap(themes.avon_v2.light, beautyConsultantTheme)
  }

  return themes.natura.light
}

const themeMap = (themeWeb, themeReact) => {
  themeWeb.size = themeReact.size
  themeWeb.asset = themeReact.asset
  themeWeb.color = { ...themeWeb.color, ...themeReact.color }
  themeWeb.palette.primary.main = themeReact.color.primary
  themeWeb.palette.primary.dark = themeReact.color.primaryDark
  themeWeb.palette.secondary.main = themeReact.color.secondary
  return themeWeb
}
