import { getBffApiGatewayConnection } from './config'

export const peopleDownload = async ({
 personIds, status, hasContacted, sort,
}) => {
  const connection = await getBffApiGatewayConnection()

  const route = `/accounts/register-reports/v1/people/download/${status}?hasContacted=${hasContacted}&sort=${sort}&allReports=true`

  const result = await connection.post(route, { personIds })

  return result
}
