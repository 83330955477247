import React from 'react'
import { FormattedMessage } from 'react-intl'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import { MenuItem } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'
import SectionTitle from '../../SectionTitle'
import { useStyles } from './FilterByGroup.styles'
import { Icon } from '../../Icon'

export const DefaultFilterByGroupOption = () => ({
  code: <FormattedMessage id="selectGroup" defaultMessage="Select Group" />,
  levelId: undefined,
  name: '',
})

const StyledIcon = styled(Icon)`
  height: 90%;
  display: flex;
  align-items: center;
`

export default ({
  groups, canFilterByGroup, selectedGroup, onSelectGroup, label,
}) => {
  const classes = useStyles()

  if (!canFilterByGroup) {
    return null
  }

  return (
    <div className={classes.filterGroupContainer}>
      {!label && (
        <SectionTitle
          title={<FormattedMessage id="group" defaultMessage="Group" />}
        />
      )}

      <div className={classes.filterSelectorContainer} id="group-filter">
        {label && (
          <InputLabel disableAnimation focused={false} className={classes.filterSelectorLabel}>
            {label}
          </InputLabel>
        )}

        <FormControl fullWidth>
          <TextField
            value={selectedGroup.code}
            onChange={(e) => onSelectGroup(e.target.value)}
            SelectProps={{
              disableUnderline: true,
              renderValue: (value) => value,
              IconComponent: ({ className }) => (
                <StyledIcon
                  className={`${className} ${classes.filterSelectIcon}`}
                  name={
                    className === 'MuiSelect-iconOpen'
                      ? 'arrow_up'
                      : 'arrow_down'
                  }
                  width={24}
                  height={24}
                />
              ),
            }}
            select
          >
            <MenuItem value={DefaultFilterByGroupOption()}>
              {DefaultFilterByGroupOption().code}
            </MenuItem>

            {groups.map((option) => (
              <MenuItem key={option.code} value={option}>
                {option.code}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
    </div>
  )
}
