import React from 'react'
import Divider from '@material-ui/core/Divider'
import { useStyles } from './Divider.styles'

const DividerMiddleText = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.middleTextContainer}>
      <div className={classes.middleTextBorder} />
      <span className={classes.middleTextContent}>{children}</span>
      <div className={classes.middleTextBorder} />
    </div>
  )
}

const customDividerVariants = { middleText: DividerMiddleText }

const CustomDivider = ({ variant, ...props }) => {
  if (Object.keys(customDividerVariants).includes(variant)) {
    const Element = customDividerVariants[variant]
    return <Element {...props} />
  }

  return <Divider {...props} variant={variant} />
}

export default CustomDivider
