import React from 'react'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './PersonalDataCell.styles'

import { snakeToCamel } from '../../helpers/common'

const PersonalDataCell = ({ name, document }) => {
  const classes = useStyles()
  const documentNumber = document?.number ? document.number : ''
  return (
    <>
      <Typography variant="body1" classes={{ root: classes.personName }}>
        {name}
      </Typography>
      {document && document.type && (
        <Typography classes={{ root: classes.personDocument }} variant="overline">
          <strong>
            <FormattedMessage id={snakeToCamel(document.type)} />
            :
          </strong>
          {documentNumber}
        </Typography>
      )}
    </>
  )
}

export default PersonalDataCell
