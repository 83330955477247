import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  messageIcon: {
    marginRight: '10px',
  },
})
