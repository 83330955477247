import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { RegistersList } from '../screens/registers-list'
import ProspectDetails from '../screens/prospect/details/prospect-details'
import ProspectEdit from '../screens/prospect/details/prospect-edit'
import RegisterReportMenu from '../screens/home/RegisterReportMenu'
import bootstrapReduxRequests from '../redux/bootstrapRequests'

export const getScopedPath = (localURL = '') => `/${localURL}`

const ROOT_URL = getScopedPath()
export const STATUS = getScopedPath(':status')
export const SUGGESTED_FOR_START_URL = getScopedPath('suggested_for_start')
export const PROSPECT_DETAILS_URL = getScopedPath(':status/show/:id')
export const PROSPECT_EDIT_URL = getScopedPath(':status/edit/:id')

const routes = [
  {
    path: PROSPECT_EDIT_URL,
    Component: ProspectEdit,
  },
  {
    path: PROSPECT_DETAILS_URL,
    Component: ProspectDetails,
  },
  {
    path: STATUS,
    Component: RegistersList,
  },
  {
    path: ROOT_URL,
    Component: RegisterReportMenu,
    exact: true,
  },
]

export default () => {
  useEffect(() => {
    bootstrapReduxRequests()
  }, [])

  return (
    <Switch>
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path} component={Component} />
      ))}
    </Switch>
  )
}
