import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Dialog, DialogActions, DialogContent, DialogContentText, ProgressIndicator, GridContainer, GridItem,
 } from '@naturacosmeticos/natds-web'
import { useParams } from 'react-router-dom'
import Button from '../../../../components/Buttons/Button'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import { sendLinkService } from '../../../../services/people'
import { useStyles } from './SendLink.styles'

const SendLinkDialog = ({ open, onClose }) => {
  const [isLoading, toggleLoading] = useState(false)
  const [dialogContentText, setDialogContentText] = useState('sendingLinkMessage')

  const setSendingLinkMessage = () => setDialogContentText('sendingLinkMessage')
  const setLinkSentSuccessfullyMessage = () => setDialogContentText('linkSentSuccessfully')
  const setErrorMessage = () => setDialogContentText('errorMessage')
  const showLoading = () => toggleLoading(true)
  const hideLoading = () => toggleLoading(false)

  const { configs } = useTenantConfigs()
  const { id } = useParams()

  const classes = useStyles()

  const sendLink = async () => {
    try {
      showLoading()
      setSendingLinkMessage()

      await sendLinkService({
        personid: id,
        companyid: configs.company.id,
        countryid: configs.country.alpha2Code,
      })

      setLinkSentSuccessfullyMessage()
    } catch (error) {
      setErrorMessage()
    } finally {
      hideLoading()
    }
  }

  return (
    <Dialog open={open} onEntering={sendLink} data-testid="send-link-dialog">
      <DialogContent>
        <GridContainer direction="column" justify="center">
          { isLoading && (
            <GridItem className={classes.progressorIndicatorContainer}>
              <ProgressIndicator data-testid="send-link-loading" size={48} />
            </GridItem>
          )}

          <GridItem md={12} sm={12}>
            <DialogContentText data-testid="send-link-test">
              <FormattedMessage id={dialogContentText} defaultMessage="Sending link" />
            </DialogContentText>
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions>
        { !isLoading && (
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            aria-label="back button"
          >
            <FormattedMessage id="reprocessDialogBack" defaultMessage="Back" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SendLinkDialog
