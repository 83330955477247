import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { Icon } from '@naturacosmeticos/natds-react'
import { getGlobalAccountUrl } from '../../../../services/config'
import Button from '../../../../components/Buttons/Button'
import useStyles from './EditButton.styles'
import { useResponsiveness } from '../../../../hooks/responsive'
import { companyNameById } from '../../../../models/company-name-by-id'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import { validateDesignEloIsActive } from '../../../../utils/validateCountryId'

const EditButton = ({ personId, display }) => {
  const classes = useStyles({ display })
  const { id, status } = useParams()
  const { isDesktop } = useResponsiveness()
  const globalExternalService = getGlobalAccountUrl()
  const country = localStorage.getItem('country')?.toLocaleLowerCase()
  const company = companyNameById[Number(localStorage.getItem('company'))]
  const getFormPath = () => `sso?country=${country}&company=${company?.toLowerCase()}&editPersonId=${personId}`
  const { configs } = useTenantConfigs()
  const history = useHistory()
  const isElo = validateDesignEloIsActive()

  const FormatedButton = ({ onClick }) => (
    <Button
      variant="contained"
      color="primary"
      className={isDesktop ? classes.editButton : classes.editButtonMobile}
      id="edit-person"
      size="medium"
      onClick={onClick}
      startIcon={isElo && <Icon name="outlined-action-edit" />}
      display={display}
    >
      <FormattedMessage id="edit" defaultMessage="Edit" />
    </Button>
  )

  if (configs?.profile.editContactWithV3) {
    return <FormatedButton onClick={() => history.push(`/${status}/edit/${id}`)} />
  }

  return (
    <a
      className={classes.anchor}
      href={`${globalExternalService.url}/${getFormPath()}`}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="edit button"
    >
      <FormatedButton />
    </a>
  )
}

EditButton.propTypes = {
  personId: PropTypes.string.isRequired,
}

export default EditButton
