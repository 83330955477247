import { useEffect, useState } from 'react'
import { getUserInfo } from '../helpers/auth'

export const useUserCountry = () => {
  const [country, setCountry] = useState()

  useEffect(() => {
    getCountryFromUser()

    // eslint-disable-next-line
    async function getCountryFromUser() {
      const userInfo = await getUserInfo()

      setCountry(userInfo.country)
    }
  }, [])

  return { country }
}
