import React, { useEffect, useState } from 'react'
import {
  Button as DSButton,
  List as DSList,
  Spacing as DSSpacing,
  Typography as DSTypography,
  Container as DSContainer,
} from '@naturacosmeticos/natds-web'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import CountrySelectionItem from './CountrySelectionItem/CountrySelectionItem'
import { useStyles } from './Country-Selection.styles'
import useCountriesConfig from '../../hooks/useCountriesConfig'
import { useCognito } from '../../hooks/useCognito'
import { getCompanyIdByBrowserUrl } from '../../helpers/getCompanyIdByBrowserUrl'
import { CompanyName } from '../../models/company-name'
import { getAppLocale } from '../../helpers/mfeLocaleConfig'
import { setLocale } from '../../redux/locale/reducers'
import { redirectToHostedUi } from '../auth/cognito/redirect-to-hosted-ui'
import { useTenantConfigs } from '../../hooks/useTenantConfigs'

const CountrySeletion = () => {
  const classes = useStyles()
  const intl = useIntl()
  const [selectedCountry, setSelectedCountry] = useState(null)
  const location = useLocation()
  const { configs } = useTenantConfigs()
  const { saveDataInLocalStorage } = useCognito()
  const dispatch = useDispatch()
  const COUNTRIES = useCountriesConfig()
  const getSystemLocale = async () => {
    const locale = await getAppLocale()
    dispatch(setLocale(locale))
  }

  const currentCompanyId = getCompanyIdByBrowserUrl()

  const filteredLocalesByCompanyId = COUNTRIES.filter(({ configs, name }) => {
    if (currentCompanyId === CompanyName.NATURA) {
      return configs.company.name === CompanyName.NATURA || name === 'Avon Argentina'
    }
    return configs.company.name === currentCompanyId && name !== 'Avon Argentina'
  })

  useEffect(() => {
    getSystemLocale()
  }, [selectedCountry])

  const handleCountrySelect = (selectedLocale) => {
    setSelectedCountry(selectedLocale.configs)
    saveDataInLocalStorage(
      selectedLocale.configs.country.alpha2Code,
      selectedLocale.configs.country.alpha3Code,
      selectedLocale.configs.company.id,
    )
  }

  return (
    <>
      <DSSpacing paddingX="standard" paddingY="semiX">
        <DSContainer className="natds1" maxWidth="sm">
          <DSSpacing paddingBottom="semi" paddingX="small">
            <DSTypography variant="subtitle1" data-testid="country-selection-title">
              <strong>
                {intl.formatMessage({
                  id: 'countrySelection',
                  defaultMessage: 'Select your country',
                })}
              </strong>
            </DSTypography>
          </DSSpacing>
          <DSList data-testid="country-list" dense>
            {filteredLocalesByCompanyId.map((item) => (
              <CountrySelectionItem
                key={item.configs.tenantId}
                locale={item.configs.country.alpha3Code}
                label={item.name}
                onSelect={() => handleCountrySelect(item)}
                isChecked={selectedCountry !== null && selectedCountry?.tenantId === item.configs.tenantId}
              />
            ))}
          </DSList>
          <div className={classes.actions}>
            <DSButton
              color="primary"
              size="medium"
              variant="contained"
              className={classes.button}
              onClick={() => redirectToHostedUi(location, configs)}
              data-testid="button__login"
              disabled={!selectedCountry}
            >
              {intl.formatMessage({
                id: 'login',
                defaultMessage: 'Login',
              })}
            </DSButton>
          </div>
        </DSContainer>
      </DSSpacing>
    </>
  )
}

export default CountrySeletion
