import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage, useIntl } from 'react-intl'

import { Link, useLocation } from 'react-router-dom'
import Dialog from '../../components/Dialog'
import Button from '../../components/Buttons/Button'

import { useStyles } from './ConsultantDetailsDialog.styles'
import { snakeToCamel } from '../../helpers/common'
import { hasContactMadeService } from '../../services/people'
import { DocumentType } from '../../components/DocumentSearch/DocumentType'
import { appMode } from '../../adapter'
import { getHasContactedFilterParam } from '../people-table/strategies/get-has-contacted-filter-param'
import { useTenantConfigs } from '../../hooks/useTenantConfigs/useTenantConfigs'

const ConsultantDetailsDialog = ({
  open,
  onClose,
  peopleFilteredBy,
  consultant,
  contactMadeButtonText,
  shouldHideContactMadeButton,
  afterHandleMadeContact,
}) => {
  const { configs } = useTenantConfigs()

  const [loading, isLoading] = useState(false)
  const classes = useStyles()
  const location = useLocation()

  const handleMadeContact = async () => {
    try {
      isLoading(true)
      const currentHasContactedState = getHasContactedFilterParam(peopleFilteredBy)
      const selectedPeople = [
        { personId: consultant.personId, hasContacted: !currentHasContactedState },
      ]
      await hasContactMadeService(selectedPeople)
      afterHandleMadeContact({ selectedPeople })
      onClose()
      isLoading(false)
    } catch (e) {
      isLoading(false)
    }
  }

  const getI18nDocumentConfig = () => {
    const documentType = consultant.document.type
      ? consultant.document.type
      : DocumentType.UNKNOWN_DOCUMENT_TYPE
    // eslint-disable-next-line no-use-before-define
    const i18nDefaultMessage = intl.formatMessage({
      id: snakeToCamel(DocumentType.UNKNOWN_DOCUMENT_TYPE),
      defaultMessage: 'Document',
    })
    return {
      documentTypeId: snakeToCamel(documentType),
      i18nDefaultMessage,
    }
  }

  const getDetailsPath = () => appMode().paths.consultantDetailsPath(
    location,
    consultant.personId,
    consultant.isUnderApproval,
    consultant.candidateId,
  )

  const renderTitle = () => (
    <div>
      <Typography variant="h5">{consultant.name}</Typography>

      <Typography variant="body1">
        <FormattedMessage id="consultantCode" />
        :
        {' '}
        {consultant.candidateId ? consultant.candidateId : 'S/N'}
      </Typography>
    </div>
  )

  const renderContent = () => {
    const { documentTypeId, i18nDefaultMessage } = getI18nDocumentConfig()

    return (
      <div className={classes.contentContainer}>
        {consultant.document.number && (
          <div className={classes.infoContainer}>
            <Typography className={classes.infoTitle} variant="body1">
              <FormattedMessage
                id={documentTypeId}
                defaultMessage={i18nDefaultMessage}
              />
            </Typography>
            <Typography variant="body2">
              {consultant.document.number}
            </Typography>
          </div>
        )}
        <div className={classes.infoContainer}>
          <Typography className={classes.infoTitle} variant="body1">
            <FormattedMessage id="cellphone" />
          </Typography>
          <Typography variant="body2">{consultant.phoneMobile}</Typography>
        </div>
        <div className={classes.infoContainerFullWidth}>
          <Typography className={classes.infoTitle} variant="body1">
            <FormattedMessage id="signUpStatus" />
          </Typography>
          <Typography variant="body2">
            {consultant.substatus ? (
              <FormattedMessage
                id={snakeToCamel(`sub_status_${consultant.substatus}`)}
                defaultMessage={consultant.substatus}
              />
            ) : (
              ' - '
            )}
          </Typography>
        </div>
        <div className={classes.infoContainerFullWidth}>
          <Typography className={classes.infoTitle} variant="body1">
            <FormattedMessage id="email" />
          </Typography>
          <Typography variant="body2">{consultant.email || ' -'}</Typography>
        </div>
      </div>
    )
  }

  function extractPersonFirstName(name) {
    if (name) {
      const splittedName = name.split(' ')
      return splittedName[0]
    }

    return name
  }

  const renderActions = () => (
    <div className={classes.actionsContainer}>
      <Button disabled={configs?.country?.shouldHideDetailButton} classes={{ label: classes.textButtonLabel }}>
        <Link
          className={classes.link}
          style={{ pointerEvents: configs?.country?.shouldHideDetailButton ? 'none' : '' }}
          to={getDetailsPath()}
        >
          <FormattedMessage id="moreDetails" />
        </Link>
      </Button>
      <Button
        variant="contained"
        color="primary"
        href={
          consultant.phoneMobile ? `tel:${consultant.phoneMobile}` : undefined
        }
        fullWidth
      >
        <FormattedMessage
          id="callToPerson"
          values={{ name: extractPersonFirstName(consultant.name) }}
        />
      </Button>
      {shouldHideContactMadeButton ? undefined : (
        <Button
          onClick={handleMadeContact}
          loading={loading}
          variant="outlined"
          color="default"
          fullWidth
        >
          {contactMadeButtonText}
        </Button>
      )}
    </div>
  )
  const intl = useIntl()

  return (
    <Dialog
      data-testid="consultantDialog"
      open={open}
      onClose={onClose}
      title={renderTitle()}
      actions={renderActions()}
    >
      {renderContent()}
    </Dialog>
  )
}

ConsultantDetailsDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  consultant: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool.isRequired,
  shouldHideContactMadeButton: PropTypes.bool.isRequired,
  peopleFilteredBy: PropTypes.oneOf([PropTypes.any]),
}

ConsultantDetailsDialog.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  open: false,
  peopleFilteredBy: undefined,
}

export default ConsultantDetailsDialog
