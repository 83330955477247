import React from 'react'
import implement from 'implement-js'

// eslint-disable-next-line import/no-cycle
import Header from '../../../components/Header/Header'
// eslint-disable-next-line import/no-cycle
import Drawer from '../../../components/AppMenu/Drawer'
import { LayoutInterface } from '../../interfaces/layout'

const Layout = {
  Header: ({ children, ...props }) => <Header {...props}>{children}</Header>,

  Drawer: () => <Drawer />,
}

export default implement(LayoutInterface)(Layout)
