import substatusMessages from '../../es/substatusMessages'
import nationalityMessages from '../../es/nationalityMessages'
import statusMessages from '../../es/statusMessages'
import digitalStoreMessages from '../../es/digitalStoreMessages'

export default {
  admissionDate: 'Fecha de admisión',
  additionalData: 'Datos Adicionales',
  age: 'Edad',
  allRegisters: 'Todos los registros',
  alreadyGotInTouch: 'Ya me puse en contacto',
  alreadyMadeContact: 'Ya Contactado',
  alternativeAddress: 'Dirección Alternativa',
  applyFilter: 'Filtrar',
  approve: 'Aprobar',
  approved: 'Aprobado',
  approveRegistrationDialogTitle: 'Aprobar Registro',
  approveRegistrationDialogMessage: '¿Está seguro de que desea aprobar el registro de <strong>{name}</strong>?',
  attachments: 'Documentos adjuntos',
  attachmentsAddress: 'Comprobante de domicillio',
  attachmentsCelulaCiudadaniaBack: '{doc} - Dorso',
  attachmentsCelulaCiudadaniaFront: '{doc} - Frente',
  attachmentsCelulaExtranjeriaBack: '{doc} - Dorso',
  attachmentsCelulaExtranjeriaFront: '{doc} - Frente',
  attachmentsDniArgentinaBack: '{doc} - Dorso',
  attachmentsDniArgentinaFront: '{doc} - Frente',
  attachmentsIneMexicoBack: '{doc} - Dorso',
  attachmentsIneMexicoFront: '{doc} - Frente',
  attachmentsDocumentForeignBack: '{doc} - Dorso',
  attachmentsDocumentForeignFront: '{doc} - Frente',
  attachmentsDniPeruDocumentFront: '{doc} - Frente',
  attachmentsDniPeruDocumentBack: '{doc} - Dorso',
  attachmentsPassportMexico: '{doc}',
  attachmentsIntention: 'Fotografía de la intencion',
  attachmentsProofEconomicLife: 'Prova de  vida econômica',
  attachmentsTerms: 'Términos y Condiciones',
  beginnings: 'Inícios',
  birthday: 'Fecha de nacimiento',
  cancel: 'Cancel',
  callToPerson: 'Llama a {name}',
  cellphone: 'Teléfono (Móvil)',
  city: 'Comuna',
  citizenCard: 'Cédula de Ciudadanía',
  cologne: 'Colonia',
  commercialStructure: 'Grupo',
  complement: 'Complemento',
  consultantCode: 'Código CN',
  consultantRegistration: 'Registro de Consultor',
  contact: 'Contacto',
  contactNumber: 'Teléfono',
  country: 'País',
  cpf: 'CPF',
  credit: 'Crédito',
  creditLinePostPaid: 'Crédito',
  dateOfBirth: 'Fecha de nacimiento',
  decideLater: 'Decide después',
  deliveryAddress: 'Dirección de Entrega',
  department: 'Departamento',
  dropouts: 'Reprobados/Desistencia',
  district: 'Distrito',
  dni: 'DNI',
  dniArgentina: 'DNI',
  documentNumber: 'Nº de documento',
  document: 'Documento',
  documents: 'Documentos',
  edit: 'Editar Registro',
  editButton: 'Editar',
  myBusiness: 'Meu negócio',
  emailButton: 'EMAIL',
  emailButtonDisabled: 'Correo electrónico no registrado',
  email: 'E-mail',
  errorTitle: 'Lo sentimos, pero tenemos un problema con nuestro sistema…',
  errorSubtitle: 'Por favor, inténtelo de nuevo más tarde.',
  establishment: 'Establecimiento',
  femaleGender: 'Femenino',
  filterByCreditLine: 'Modalidad de Pago',
  filterByOrigin: 'Origen',
  status: 'Estado',
  filters: 'Filtros',
  foreignCard: 'Cédula de Extranjería',
  foreignRegistry: 'Carnet de Extranjeria',
  gender: 'Género',
  group: 'Grupo',
  helloStr: 'Hola! És o report app',
  homeTime: 'Tiempo de Casa',
  indicator: 'Indicante',
  leader: 'Lider',
  level1: 'Provincia',
  level2: 'Partido/Municipio',
  level3: 'Localidad',
  level4: 'Barrio',
  lineNumber: 'Teléfono fijo',
  logout: 'Salir',
  mailingAddress: 'Dirección Residencial',
  maleGender: 'Masculino',
  mobileNumber: 'Teléfono (Móvil)',
  moreDetails: 'Más detalles',
  moveToNewRegisters: 'Mover a nuevos registros',
  myRegisters: 'Mis Registros',
  myCad: 'MyCad',
  name: 'Nombre',
  nationalRegistry: 'DNI',
  nationality: 'Nacionalidad',
  naturaBrand: 'Imagen con la marca Natura',
  avonBrand: 'Imagen con la marca Avon',
  beautyConsulting: 'Imagen con la marca Consultora de Belleza',
  naturaReports: 'Informes Natura',
  avonReports: 'Informes Avon',
  neighborhood: 'Barrio',
  newRegisters: 'Nuevos Registros',
  no: 'no',
  noNewRegisters: 'No hay nuevos registros',
  noRegisters: 'No hay registros',
  noRegisteredAddress: 'No hay domicilio registrado.',
  noRegistersContacted: 'No hay registros ya contactados',
  noResultsOnCommercialStructure: 'No se encontraron resultados en su estructura comercial',
  rutChile: 'RUT',
  number: 'Número',
  origin: 'Origen',
  other: 'Otro',
  otherGender: 'Otro',
  paymentPlan: 'Modalidad de Pago',
  passport: 'Pasaporte',
  passportMalasya: 'Pasaporte Malayo',
  personCode: 'Código Persona',
  sellerId: 'Código',
  personalData: 'Datos Personales',
  personalContact: 'Datos Personales',
  personalContactLastUpdateTitle: 'Última actualización',
  personalContactLastUpdateDate: 'Fecha:',
  personalContactLastUpdateDatetime: 'Hora:',
  personalContactLastUpdateBy: 'Actualizado vía:',
  labelLogBackoffice: 'Servicio de backoffice',
  labelLogDigitalSpace: 'Espacio Digital',
  labelLogNatbotDialmyapp: 'NatBot/ Dialmyapp',
  labelLogMyPerfilCbWeb: 'Mi perfil Web CB',
  labelLogReportsFvWeb: 'Reports FV Web',
  labelLogMyPerfilCbApp: 'Mi perfil App CB',
  labelLogReportsFvApp: 'Reports FV App',
  labelLogOther: 'Otros',
  prePaid: 'Pre-pago',
  province: 'Provincia',
  references: 'Referencias',
  region: 'Región',
  registerOn: 'Registro en',
  registerReports: 'Reporte de registros',
  registerStatus: 'Estado de Registro',
  registrant: 'Cadastrante',
  registrationDenied: 'Registro Denegado',
  registerAllocatedOnCommercialStructure: 'Registro asignado en estructura comercial: ',
  registerButtonLinkLabel: 'Nuevo Nombramiento',
  registerOriginGlobal: 'Registro Global',
  registerOriginMinegocio: 'MiNegocio',
  registerOriginUndefined: 'Indefinido',
  registerOriginAvonMigration: 'Avon',
  reject: 'Rechazar',
  rejectRegistration: 'Rechazar Registro',
  rejectRegistrationDialogTitle: 'Rechazar Registro',
  rejectRegistrationDialogMessage: '¿Está seguro de que desea rechazar el registro de  <strong>{name}</strong>?',
  rejectionReason: 'Motivo de rechazo',
  restart: 'Reinício',
  resultsOutsideStructure: 'Resultados fuera de la estructura comercial',
  rg: 'RG',
  searchByDocument: 'Busque el documento',
  selectDocument: 'Selecciona documento',
  selectGroup: 'Selecciona grupo',
  selfRegistration: 'Auto Cadastro',
  signUpStatus: 'Estado de registro',
  state: 'Estado',
  street: 'Calle',
  streetAddressLine1: 'Dirección línea 1',
  streetAddressLine2: 'Dirección línea 2',
  suggestedForStart: 'En proceso de registro',
  termsAndConditionsRemark: 'Términos y condiciones (observación)',
  unknownDocumentType: 'Documento',
  waitingFirstOrder: 'Esperando el 1º pedido',
  years: '{age} años',
  yes: 'sí',
  yesImSure: 'Sí, estoy seguro',
  zipCode: 'Código Postal',
  zipcode: 'Código Postal', // this key came from BFF without camelCase
  reprocessButtonLabel: 'Reprocesar',
  reprocessDialogTitle: '¿Seguro que quieres reprocesar?',
  reprocessDialogText: 'Esto volverá a procesar este registro, ¿está seguro de que desea continuar?',
  reprocessDialogBack: 'Volver',
  reprocessDialogConfirm: 'Si, estoy segura',
  accepted: 'Acepto',
  noAccepted: 'No Acepto',
  dialogExcelBody: `
Al descargar el archivo de datos que contiene información personal de las Consultoras de Belleza, usted acepta los siguientes términos de confidencialidad ("Términos"): {br}
Usted acepta utilizar estos datos solo con el fin de administrar su estructura comercial, de conformidad con todas las leyes y regulaciones aplicables en relación con la protección de datos personales. {br}
Usted acepta proteger la confidencialidad y seguridad de dichos datos mediante el uso de medidas de seguridad apropiadas para evitar el acceso, uso o divulgación no autorizados de dicha información. {br}
Usted acepta no divulgar estos datos a terceros a menos que lo exija la ley o con la autorización expresa del respectivo titular de datos. {br}
Usted se compromete a notificar inmediatamente al Grupo Natura & Co (enviando un correo electrónico a privacy@naturaeco.com), en caso de cualquier violación de seguridad o uso indebido de esta información. {br}
En el caso de que usted sea una Líder de Negocios, usted también acepta notificar inmediatamente al titular de datos correspondiente en caso de cualquier violación de seguridad o uso indebido de esta información. {br}
Usted reconoce que cualquier violación de este Término puede resultar en sanciones civiles y penales, incluyendo demandas y daños y perjuicios. {br}
Usted se compromete a indemnizar y mantener indemne al Grupo Natura & Co y a sus representantes, directores, empleados y agentes de cualesquiera reclamaciones, pérdidas, daños o gastos derivados del tratamiento de los datos personales contenidos en el fichero. {br}
Al hacer clic en el botón "Acepto", usted acepta estos términos y se compromete a cumplir con sus responsabilidades con respecto a la privacidad y confidencialidad de los datos personales contenidas en estos Términos.  {br}
Por favor, lea atentamente estos términos antes de aceptarlos y descargar el archivo de datos. {br}
`,
  termsOfConfidentiality: 'Términos de Confidencialidad',
  curp: 'CURP',
  ineMexico: 'INE',
  stateBirth: 'Estado de Nacimiento',
  firstName: 'Nombre(s)',
  middleName: 'Apellido Materno',
  lastName: 'Apellido Paterno',
  startDate: 'Fecha de registro',
  isReactivation: 'Reinicio',
  division: 'Division',
  sector: 'Sector',
  leaderCode: 'Código de Líder',
  unitCode: 'Grupo',
  zone: 'Sector',
  structure: 'Estructura',
  export: 'Exportar',
  information: 'Información',
  informationContent: 'Seleccione uno o más registro(s) para habilitar la exportación.',
  candidateId: 'Código CB',
  fullName: 'Nombre y Apellido',
  countrySelection: 'Selecciona tu pais',
  login: 'Iniciar sesión',
  BRA: 'Brasil',
  ARG: 'Argentina',
  AVON_ARG: 'Avon Argentina',
  AVON_BRA: 'Avon Brasil',
  BOL: 'Bolivia',
  CHL: 'Chile',
  COL: 'Colombia',
  MEX: 'Mexico',
  PER: 'Perú',
  MYS: 'Malasia',
  cnZone: 'Sector',
  phoneMobile: 'Teléfono (Móvil)',
  whatsappNumber: 'Whatsapp',
  introducerName: 'Nombre y apellido de indicadora',
  introducerSellerId: 'Número de cuenta de indicadora',
  reference: 'Referencia',
  timeLeftLessThanHour: 'menos de una hora',
  oneHourLeft: '1 hora',
  hoursLeft: '{hours}h',
  oneDayLeft: '1 día',
  daysLeft: '{days} días',
  cedulaIdentidad: 'CEDULA_IDENTIDAD',
  registrationManagement: 'Gestión de inscripciones',
  changeGroup: 'Cambiar grupo',
  changeConsultantGroup: 'Grupo de consultores de cambio',
  changeGroupLabel: 'Grupo',
  changeGroupFeedback: '¡El cambio de grupo se completó con éxito!',
  changeGroupFeedbackError: '¡Ups! No se puede cambiar de grupo, inténtalo de nuevo más tarde.',
  changeGroupNotify: '*El cambio de grupo puede tardar unos minutos en reflejarse en la pantalla.',
  ...statusMessages,
  ...substatusMessages,
  ...nationalityMessages,
  ...digitalStoreMessages,
}
