import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Grid from '@material-ui/core/Grid'
import * as XLSX from 'xlsx'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { snakeToCamel } from '../../helpers/common'

import { useTenantConfigs } from '../../hooks/useTenantConfigs/useTenantConfigs'
import { peopleDownload } from '../../services/people-download'
import { useMapExcelToggles } from '../../hooks/useMapExcelToggles'
import useGlobal from '../../hooks/globalState/useGlobal'

import { hasContactMadeService } from '../../services/people'
import { useResponsiveness } from '../../hooks/responsive'
import FilterButton from '../../components/Header/FilterWithButton'

import { PeopleTableContactMadeButton } from './components'

import { getContactMadeButtonText } from './strategies/get-contact-made-button-text'
import { getHasContactedFilterParam } from './strategies/get-has-contacted-filter-param'

import Button from '../../components/Buttons/Button'
import { DialogConfirm } from './components/dialog-confirm'
import { DialogErrorDownload } from './components/dialog-error'

const useStyles = makeStyles({
  drawerButtonChip: {
    marginLeft: '10px',
    backgroundColor: 'white',
  },
  desktopButton: {
    right: 0,
    position: 'absolute',
    zIndex: 1100,
    display: 'flex',
    margin: '-3.5rem 20px',
    alignItems: 'center',
  },
  documentSearch: {
    width: 500,
    display: 'flex',
  },
  iconButtons: {
    height: 40,
  },
  mobileContactButton: {
    margin: '20px',
  },
  dialogInfo: {
    backgroundColor: '#d0ebff',
    position: 'absolute',
    border: '1px solid  #3084c3',
    width: '240px',
    right: '0',
    padding: '8px',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    top: '50px',
  },
  dialogInfoTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    fontWeight: '400',
    margin: '8px 0',
  },
  dialogInfoTitleIcon: {
    marginRight: '8px',
  },
  dialogInfoContent: {
    marginLeft: '32px',
    paddingBottom: '8px',
    lineHeight: '24px',
  },
  exportButton: {
    borderColor: '#e3e3e3',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: '#7c7c7c',
      borderColor: '#e3e3e3',
    },
  },
})

export const PeopleTableHeader = ({
  selectedRows,
  afterHandleMadeContact,
  filteredBy,
  allRowsSelect,
  sort,
}) => {
  const classes = useStyles()
  const [loading, isLoading] = useState(false)
  const { isMobile } = useResponsiveness()

  const { filterCounter } = useSelector(({ prospectTable }) => prospectTable)
  const [globalState] = useGlobal()
  const [showDialogConfirm, setShowDialogConfirm] = useState(false)
  const [showModalInfo, setShowModalInfo] = useState(false)
  const { prospectStatusScreen } = globalState.filters
  const { configs } = useTenantConfigs()
  const { mapExcelToggles } = useMapExcelToggles()
  const intl = useIntl()
  const [showDialogErrorDownload, setShowDialogErrorDownload] = useState(false)

  const handleMouseDown = () => {
    setShowModalInfo(false)
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  const deleteProperties = (array, keys) => {
    array.map((obj) => {
      keys.forEach((key) => delete obj[key])
      return obj
    })
  }

  const downloadPeople = (data) => {
    const { showColumnsRegistrantAndIntroducerSeller } = configs

    const newData = data
    ? data.map((item) => ({ ...item, registerStatus: snakeToCamel(`sub_status_${item.registerStatus}`) }))
    : []

     const dataTranslate = newData.map((item) => ({
      ...item,
      registerStatus: translateExcelColumn(item.registerStatus),
      registrant:
        item?.registrant?.toUpperCase() === 'SELF_REGISTRATION'
          ? translateExcelColumn(snakeToCamel(item.registrant))
          : item?.registrant,
    }))

    if (!showColumnsRegistrantAndIntroducerSeller) {
      deleteProperties(dataTranslate, ['introducerSeller'])
      deleteProperties(dataTranslate, ['registrant'])
    }

    const transformedArray = renameKeys(dataTranslate, {
      name: translateExcelColumn('fullName'),
      startDate: translateExcelColumn('startDate'),
      registerStatus: translateExcelColumn('registerStatus'),
      unitCode: translateExcelColumn('unitCode'),
      cnZone: translateExcelColumn('zone'),
      candidateId: translateExcelColumn('candidateId'),
      phoneMobile: translateExcelColumn('phoneMobile'),
      email: translateExcelColumn('email'),
      city: translateExcelColumn('city'),
      state: translateExcelColumn('state'),
      division: translateExcelColumn('division'),
      zipcode: translateExcelColumn('zipCode'),
      region: translateExcelColumn('region'),
      department: translateExcelColumn('department'),
      province: translateExcelColumn('province'),
      district: translateExcelColumn('district'),
      street: translateExcelColumn('street'),
      neighborhood: translateExcelColumn('neighborhood'),
      number: translateExcelColumn('number'),
      complement: translateExcelColumn('complement'),
      references: translateExcelColumn('references'),
      reference: translateExcelColumn('reference'),
      introducerName: translateExcelColumn('introducerName'),
      introducerSellerId: translateExcelColumn('introducerSellerId'),
      country: translateExcelColumn('country'),
      ...(showColumnsRegistrantAndIntroducerSeller ? { introducerSeller: translateExcelColumn('introducerSeller') } : {}),
      ...(showColumnsRegistrantAndIntroducerSeller ? { registrant: translateExcelColumn('registrant') } : {}),
    })

    const worksheet = XLSX.utils.json_to_sheet(transformedArray)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'reports.xlsx')
    onCloseDialogConfirm()
  }

  const handleContactMade = async () => {
    try {
      isLoading(true)
      const currentHasContactedState = getHasContactedFilterParam(filteredBy)
      const selectedRowsWithHasContactedProperty = selectedRows.map((person) => ({
        ...person,
        hasContacted: !currentHasContactedState,
      }))
      await hasContactMadeService(selectedRowsWithHasContactedProperty)
      await afterHandleMadeContact({ selectedPeople: selectedRowsWithHasContactedProperty })
      isLoading(false)
    } catch (e) {
      isLoading(false)
    }
  }

  const onCloseDialogConfirm = () => {
    setShowDialogConfirm(false)
  }

  const onCloseDialogErrorDownload = () => {
    setShowDialogErrorDownload(false)
  }

  const onClickButtonExport = () => {
    if (selectedRows.length > 0) {
      setShowDialogConfirm(true)
    } else {
      setShowModalInfo(true)
    }
  }

  const translateExcelColumn = (key) => intl.formatMessage({ id: key })

  function renameKeys(array, keyMap) {
    return array.map((item) => {
      const newItem = {}
      Object.keys(item).forEach((key) => {
        const newKey = keyMap[key] || key
        newItem[newKey] = item[key]
      })
      return newItem
    })
  }

  const downloadExcel = () => {
    const personIds = []
    const hasContacted = filteredBy === 'hasContacted'
    const orderBy = sort === 'createdRoleAt_asc' ? 'createdAt_asc' : 'createdAt_desc'
    selectedRows.map((item) => personIds.push(item.personId))
    try {
      peopleDownload({
        personIds: allRowsSelect ? [] : personIds, status: prospectStatusScreen, hasContacted, sort: orderBy,
      }).then((item) => {
        setShowDialogConfirm(false)
        downloadPeople(item.data)
      }).catch(() => {
        setShowDialogErrorDownload(true)
        setShowDialogConfirm(false)
      })
    } catch (e) {
      setShowDialogErrorDownload(true)
      setShowDialogConfirm(false)
    }
  }

const shouldShowExportButton = (tenantId) => !!mapExcelToggles?.[tenantId]

const renderExportButton = () => {
  if (shouldShowExportButton(configs?.tenantId)) {
      return (
        <>
          <Button
            data-testid="export"
            className={classes.exportButton}
            onClick={() => onClickButtonExport()}
            color="primary"
            variant="outlined"
          >
            {intl.formatMessage({
              id: 'export',
              defaultMessage: 'Export',
            })}
          </Button>
          {
            showModalInfo && (
              <Grid className={classes.dialogInfo} data-testid="exportDialogInfo">
                <h3 className={classes.dialogInfoTitle}>
                  <InfoOutlinedIcon className={classes.dialogInfoTitleIcon} />
                  <FormattedMessage id="information" />
                </h3>
                <div className={classes.dialogInfoContent}>
                  <FormattedMessage id="informationContent" />
                </div>
              </Grid>
            )
          }
        </>
      )
    }
  }

  const renderContactMadeButton = () => {
    if (selectedRows.length) {
      return (
        <PeopleTableContactMadeButton
          onClick={handleContactMade}
          isLoading={loading}
          buttonText={getContactMadeButtonText(filteredBy)}
          ButtonProps={{
            classes: {
              root: isMobile && classes.mobileContactButton,
            },
          }}
          ChipProps={{
            classes: {
              root: classes.drawerButtonChip,
            },
            label: selectedRows.length,
          }}
        />
      )
    }
  }

  if (isMobile) {
    return (
      <Drawer
        data-testid="contactMadeButtonMobile"
        elevation={3}
        anchor="bottom"
        variant="persistent"
        open={selectedRows.length > 0}
      >
        {renderContactMadeButton()}
      </Drawer>
    )
  }

  return (
    <>
      <div className={classes.desktopButton}>
        <div className={classes.iconsContainer}>
          <FilterButton filterCounter={filterCounter} />
        </div>
        {renderContactMadeButton()}
        {renderExportButton()}
      </div>
      {
        showDialogErrorDownload && (<DialogErrorDownload open={showDialogErrorDownload} onClose={onCloseDialogErrorDownload} />)
      }
      {
        showDialogConfirm && (
          <DialogConfirm open={showDialogConfirm} onClose={onCloseDialogConfirm} onSubmit={downloadExcel} />
        )
      }
    </>
  )
}

PeopleTableHeader.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  afterHandleMadeContact: PropTypes.func.isRequired,
}
