import substatusMessages from './substatusMessages'
import nationalityMessages from './nationalityMessages'
import statusMessages from './statusMessages'
import digitalStoreMessages from './digitalStoreMessages'

export default {
  admissionDate: 'Data de admissão',
  age: 'Idade',
  allRegisters: 'Todos os cadastros',
  alreadyGotInTouch: 'Já entrei em contato',
  alreadyMadeContact: 'Já fiz contato',
  alternativeAddress: 'Endereço Alternativo',
  applyFilter: 'Filtrar',
  approve: 'Aprovar',
  approved: 'Aprovado',
  approveRegistrationDialogTitle: 'Aprovar Cadastro',
  approveRegistrationDialogMessage: 'Tem certeza de que deseja aprovar o cadastro de <strong>{name}</strong>?',
  attachments: 'Anexos',
  attachmentsAddress: 'Prova de endereço',
  attachmentsCelulaCiudadaniaBack: '{doc} - Verso',
  attachmentsCelulaCiudadaniaFront: '{doc} - Frente',
  attachmentsCelulaExtranjeriaBack: '{doc} - Verso',
  attachmentsCelulaExtranjeriaFront: '{doc} - Frente',
  attachmentsDocumentBack: '{doc} - Verso',
  attachmentsDocumentForeignBack: '{doc} - Verso',
  attachmentsDocumentForeignFront: '{doc} - Frente',
  attachmentsDocumentFront: '{doc} - Frente',
  attachmentsIntention: 'Foto da intenção',
  attachmentsProofEconomicLife: 'Prova financeira',
  attachmentsTerms: 'Termos e Condições',
  beginnings: 'Inícios',
  birthday: 'Data de Nascimiento',
  cancel: 'Cancel',
  callToPerson: 'Ligar para {name}',
  cellphone: 'Celular',
  city: 'Cidade',
  citizenCard: 'Cédula de Cidadania',
  cpf: 'CPF',
  commercialStructure: 'Estrutura comercial',
  complement: 'Complemento',
  consultantCode: 'Código CN',
  consultantRegistration: 'Registro de Consultor',
  additionalData: 'Dados adicionais',
  select: 'Selecione',
  registrationManagement: 'Gestão de cadastro',
  changeGroup: 'Alterar grupo',
  changeGroupFeedback: 'Mudança de grupo concluída com sucesso!',
  changeGroupFeedbackError: 'Ops! Não foi possivel mudar o grupo, tente novamente mais tarde.',
  changeGroupNotify: '*A mudança de grupo pode levar alguns minutos para refletir na tela.',
  changeConsultantGroup: 'Alterar Grupo da Consultora',
  changeGroupLabel: 'Grupo',
  contact: 'Contato',
  contactNumber: 'Número para contato',
  country: 'País',
  credit: 'Pós-pago',
  creditLinePostPaid: 'Pós-pago',
  dateOfBirth: 'Data de Nascimento',
  deliveryAddress: 'Endereço de Entrega',
  department: 'Cidade',
  dropouts: 'Reprovados / Desistência',
  district: 'Distrito',
  decideLater: 'Decidir Depois',
  documentNumber: 'Nº do Documento',
  document: 'Documento',
  documents: 'Documentos',
  edit: 'Continuar cadastro',
  editButton: 'Editar',
  myBusiness: 'Meu negócio',
  emailButton: 'EMAIL',
  emailButtonDisabled: 'E-mail não cadastrado',
  email: 'E-mail',
  errorTitle: 'Desculpe, mas estamos com problema em nosso sistema…',
  errorSubtitle: 'Por favor, tente novamente mais tarde.',
  establishment: 'Estabelecimento',
  femaleGender: 'Feminino',
  filterByCreditLine: 'Modalidade de Pagamento',
  filterByOrigin: 'Origem',
  status: 'Status',
  filters: 'Filtros',
  foreignRegistry: 'RNE',
  foreignCard: 'Cédula Estrangeira',
  gender: 'Gênero',
  group: 'Grupo',
  helloStr: 'Olá! Este é o Reports app',
  homeTime: 'Tempo de Casa',
  indicator: 'Indicante',
  leader: 'Líder',
  lineNumber: 'Telefone Fixo',
  logout: 'Sair',
  mailingAddress: 'Endereço Residencial',
  maleGender: 'Masculino',
  mobileNumber: 'Celular',
  moreDetails: 'Mais detalhes',
  moveToNewRegisters: 'Mover para novos registros',
  myRegisters: 'Meus Cadastros',
  myCad: 'MyCad',
  name: 'Nome',
  firstName: 'Nome',
  middleName: 'Nome do meio',
  lastName: 'Sobrenome',
  nationalRegistry: 'Registro Nacional',
  nationality: 'Nacionalidade',
  naturaBrand: 'Imagem com a Marca da Natura',
  avonBrand: 'Imagem com a Marca da Avon',
  beautyConsulting: 'Imagem com a Marca da Consultoria de Beleza',
  naturaReports: 'Relatórios Natura',
  avonReports: 'Relatórios Avon',
  neighborhood: 'Bairro',
  newRegisters: 'Novos Cadastros',
  no: 'não',
  noRegisters: 'Não há cadastros',
  noNewRegisters: 'Não há novos cadastros',
  whatsappNumber: 'Whatsapp',
  noRegisteredAddress: 'Não há dados registrados',
  noRegistersContacted: 'Não há cadastros já contactados',
  noResultsOnCommercialStructure: 'Nenhum resultado encontrado em sua estrutura comercial',
  number: 'Número',
  origin: 'Origem',
  otherGender: 'Outro',
  paymentPlan: 'Forma de Pagamento',
  passport: 'Passaporte',
  passportMalasya: 'Passaporte Malaio',
  personCode: 'Código de Pessoa',
  sellerId: 'Codigo',
  personalData: 'Dados Pessoais',
  personalContact: 'Contato',
  personalContactLastUpdateTitle: 'Última atualização',
  personalContactLastUpdateDate: 'Data:',
  personalContactLastUpdateDatetime: 'Horário:',
  personalContactLastUpdateBy: 'Atualizado via:',
  labelLogBackoffice: 'Atendimento Backoffice',
  labelLogDigitalSpace: 'Espaço Digital',
  labelLogNatbotDialmyapp: 'NatBot/ Dialmyapp',
  labelLogOther: 'Outros',
  labelLogMyPerfilCbWeb: 'Meu Perfil CB Web',
  labelLogReportsFvWeb: 'Reports FV Web',
  labelLogMyPerfilCbApp: 'Meu Perfil CB App',
  labelLogReportsFvApp: 'Reports FV App',
  prePaid: 'Pré-pago',
  province: 'Provincia',
  references: 'Referências',
  region: 'Estado',
  registerOn: 'Cadastro em',
  registerReports: 'Relatório de cadastros',
  registerStatus: 'Status do Registro',
  registrant: 'Cadastrante',
  registrationDenied: 'Registro Negado',
  registerAllocatedOnCommercialStructure: 'Cadastro alocado na estrutura comercial: ',
  registerOriginGlobal: 'Cadastro Global',
  registerOriginMinegocio: 'MiNegocio',
  registerOriginUndefined: 'Indefinido',
  registerOriginAvonMigration: 'Avon',
  registerButtonLinkLabel: 'Novo Cadastro',
  reject: 'Recusar',
  rejectRegistration: 'Recusar Cadastro',
  rejectRegistrationDialogTitle: 'Recusar Cadastro',
  rejectRegistrationDialogMessage: 'Tem certeza de que deseja recusar o cadastro de <strong>{name}</strong>?',
  rejectionReason: 'Motivo da rejeição',
  selectPlaceHolderCancelRegister: 'Selecione',
  cancelRegister: 'Cancelar Cadastro',
  cancelRegistration: 'Cancelar Cadastro',
  cancelReasonCandidate: 'Candidata desistiu do cadastro',
  cancelReasonNatura: 'Cancelamento pela Natura',
  cancelRegistrationDialogTitle: 'Cancelar Cadastro',
  cancelRegistrationDialogMessage:
    'Tem certeza de que deseja cancelar o cadastro de <strong>{name}</strong>?',
  cancelReason: 'Motivo do cancelamento',
  labelSelectCancelReason: 'Qual o motivo do cancelamento?',
  cancelActionButton: 'Cancelar',
  restart: 'Reinício',
  startAndRestart: 'Início e reinício',
  selectStartAndRestart: 'Todos',
  selectRestart: 'Apenas reinício',
  selectStart: 'Apenas início',
  resultsOutsideStructure: 'Resultados fora da estrutura comercial',
  rg: 'RG',
  searchByDocument: 'Busque pelo documento',
  selectDocument: 'Selecione o documento',
  selectGroup: 'Selecione o Grupo',
  selfRegistration: 'Auto Cadastro',
  signUpStatus: 'Substatus do cadastro',
  state: 'Estado',
  street: 'Rua',
  streetAddressLine1: 'Endereço linha 1',
  streetAddressLine2: 'Endereço linha 2',
  suggestedForStart: 'Sugeridas para ínicio',
  onProcess: 'Em Processo',
  termsAndConditionsRemark: 'Termos e Condições(observação)',
  unknownDocumentType: 'Documento',
  waitingFirstOrder: 'Aguardando 1º pedido',
  years: '{age} anos',
  yes: 'sim',
  yesImSure: 'Sim, tenho certeza',
  zipcode: 'CEP',
  zipCode: 'CEP',
  reprocessButtonLabel: 'Reprocessar',
  reprocessDialogTitle: 'Tem certeza que deseja reprocessar?',
  reprocessDialogText: 'Isto fará com que o cadastro seja reprocessado, tem certeza que deseja continuar?',
  reprocessDialogBack: 'Cancelar',
  reprocessDialogConfirm: 'Sim, tenho certeza',
  accepted: 'Aceito',
  noAccepted: 'Não aceito',
  dialogExcelBody:
    'Você é responsável pelos dados baixados e deve utilizá-los apenas para sua atividade na Consultoria Natura e  Avon. Obrigado por sua cooperação na manutenção a segurança.',
  termsOfConfidentiality: 'Termos de Confidencialidade',
  stateBirth: 'Estado de Nascimento',
  startDate: 'Data de Início',
  isReactivation: 'Reinício',
  division: 'Divisão',
  sector: 'Setor',
  leaderCode: 'Código de Empresaria',
  unitCode: 'Unidade/Grupo',
  export: 'Exportar',
  information: 'Informação',
  informationContent: 'Selecione um ou mais cadastro(s) para habilitar a exportação.',
  zone: 'Zona',
  structure: 'Estrutura',
  candidateId: 'Número da conta da Representante',
  countrySelection: 'Selecione seu país',
  login: 'Entrar',
  BRA: 'Brasil',
  ARG: 'Argentina',
  AVON_ARG: 'Avon Argentina',
  AVON_BRA: 'Avon Brasil',
  BOL: 'Bolivia',
  CHL: 'Chile',
  COL: 'Colômbia',
  MEX: 'México',
  PER: 'Perú',
  MYS: 'Malásia',
  ECU: 'Equador',
  cnZone: 'Zona',
  phoneMobile: 'Telefone Movel',
  streetName: 'Rua',
  placeNumbering: 'Número',
  reference: 'Referência',
  introducerName: 'Nome e apelido de indicadora',
  introducerSellerId: 'Número de conta de indicadora',
  timeLeftLessThanHour: 'menos de uma hora',
  oneHourLeft: '1 hora',
  hoursLeft: '{hours}h',
  oneDayLeft: '1 dia',
  daysLeft: '{days} dias',
  ...statusMessages,
  ...substatusMessages,
  ...nationalityMessages,
  ...digitalStoreMessages,
}
