import { LOCAL_STORAGE_PREFIX } from '../Constants'

export default {
  USER: {
    GET_FILTER_GROUPS: `${LOCAL_STORAGE_PREFIX}:USER_GET_GROUPS`,
    SET_FILTER_GROUPS: `${LOCAL_STORAGE_PREFIX}:USER_SET_GROUPS`,
    GET_USER_START: 'GET_USER_START',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAIL: 'GET_USER_FAIL',
  },
  PROSPECT_TABLE: {
    SET_FILTERS: `${LOCAL_STORAGE_PREFIX}:PROSPECT_TABLE_SET_FILTERS`,
  },
  AUTH: {
    GET_ACCESS_TOKEN_START: 'GET_ACCESS_TOKEN_START',
    GET_ACCESS_TOKEN_SUCCESS: 'GET_ACCESS_TOKEN_SUCCESS',
    GET_ACCESS_TOKEN_FAIL: 'GET_ACCESS_TOKEN_FAIL',
  },
}
