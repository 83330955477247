import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import { Spacing } from '@naturacosmeticos/natds-web'
import { FormattedMessage } from 'react-intl'

import CustomCard from './Card'
import CustomListItem from './CustomListItem'
import { snakeToCamel } from '../../../helpers/common'
import SendLinkButton from './SendLink/SendLinkButton'
import { useResponsiveness } from '../../../hooks/responsive'
import { useTenantConfigs } from '../../../hooks/useTenantConfigs'
import { hasRoleCeased } from '../../../helpers/hasRole'

const CollaboratorRegistrationCard = ({ userData }) => {
  const {
    nationality,
    status,
    substatus,
    startDate,
    leaderCode,
    roles,
  } = userData
  const isReactivation = roles ? hasRoleCeased({ roles, functionId: 1, roleId: 1 }) : false
  const { configs } = useTenantConfigs()
  const userIsMexican = nationality === 'nationality_mexican'
  const fields = [
    {
      fieldId: 'commercialStructure',
      fieldDefaultMessage: 'Commercial Structure',
      dataIndex: 'commercialStructure',
    },
    {
      fieldId: 'status',
      fieldDefaultMessage: 'Status',
      render: () => {
        if (!status) {
          return ' - '
        }
        const fullSnakeCaseMessageId = `STATUS_${status}`
        const fullCamelCaseMessageId = snakeToCamel(fullSnakeCaseMessageId)
        return <FormattedMessage id={fullCamelCaseMessageId} defaultMessage={fullCamelCaseMessageId} />
      },
    },
    {
      fieldId: userIsMexican ? 'registerStatusCollaborador' : 'registerStatus',
      fieldDefaultMessage: 'Registration Status',
      render: () => {
        if (!substatus) {
          return ' - '
        }
        const fullSnakeCaseMessageId = `SUB_STATUS_${substatus}`
        const fullCamelCaseMessageId = snakeToCamel(fullSnakeCaseMessageId)
        return <FormattedMessage id={fullCamelCaseMessageId} defaultMessage={fullCamelCaseMessageId} />
      },
    },
  ]

  fields.push({
    fieldId: 'startDate',
    fieldDefaultMessage: 'Start Date',
    render: () => {
      if (!startDate) {
        return ' - '
      }

      return startDate
    },
  })

  fields.push({
    fieldId: 'isReactivation',
    fieldDefaultMessage: 'Reactivation',
    render: () => <FormattedMessage id={isReactivation ? 'yes' : 'no'} defaultMessage={isReactivation} />,
  })

  fields.push({
    fieldId: 'leaderCode',
    fieldDefaultMessage: 'Leader Code',
    render: () => {
      if (leaderCode === null || leaderCode === undefined) {
        return ' - '
      }

      return leaderCode
    },
  })

  if (configs?.shouldDisplayStructureCodeLevels) {
    const { structureCodeLevels } = configs
    structureCodeLevels.map((structureLevel) => {
      const value = userData?.structureCodeLevels?.find((value) => value.level === structureLevel.level)?.value

      return fields.push({
        fieldId: structureLevel.label,
        fieldDefaultMessage: structureLevel.defaultLabel,
        render: () => {
          if (!value) {
            return ' - '
          }

          return value
        },
      })
    })
  }

  const { isMobile } = useResponsiveness()
  return (
    <Grid item xs={12} sm={6}>
      <CustomCard titleMessageId="consultantRegistration" titleDefaultMessage="Consultant Registration">
        <List dense>
          {fields.map(({
 fieldId, fieldDefaultMessage, render, dataIndex,
}) => (
  <CustomListItem
    key={fieldId}
    fieldId={fieldId}
    fieldDefaultMessage={fieldDefaultMessage}
    data={render ? render() : userData[dataIndex]}
  />
          ))}
        </List>

        {isMobile && (
          <Spacing mb="small" mr="tiny" style={{ float: 'right' }}>
            <SendLinkButton subStatus={userData?.substatus} />
          </Spacing>
        )}
      </CustomCard>
    </Grid>
  )
}

export default CollaboratorRegistrationCard
