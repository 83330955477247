export const getDefault = () => ({
  header: {
    hasRegisterButtonLink: false,
    registerButtonLinkHref: null,
    hasLogout: false,
  },
  profile: {
    hasNationalityState: false,
    shouldDisplayThreeNames: false,
    shouldDisplaySendLinkButton: false,
    shouldDisplayContactEditButton: false,
    editContactWithV3: false,
    logoModel: 'a',
  },
  digitalStore: {
    shouldDisplayRFC: false,
  },
  filter: {
    origin: false,
    restart: false,
  },
  showCancelRegistrationCard: false,
  roles: {
    gn: {
      functionId: 17,
      roleId: 6,
    },
    leader: {
      functionId: 6,
      roleId: 4,
    },
  },
  showChangeGroupButton: false,
  canChangeGroup: [],
})
