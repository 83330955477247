import React from 'react'
import PropTypes from 'prop-types'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'

import IconButton from './Buttons/IconButton'
import { useStyles } from './Dialog.styles'

const Dialog = ({
  children,
  open,
  onClose,
  title,
  actions,
  dividers = true,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <MuiDialog
      classes={{ paper: classes.containerPaper }}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <MuiDialogTitle className={classes.title} disableTypography>
        <div>{title}</div>
        {onClose ? (
          <IconButton
            className={classes.iconButton}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
      {children && (
        <MuiDialogContent
          classes={{
            dividers: classes.content.dividers,
          }}
          dividers={dividers}
        >
          {children}
        </MuiDialogContent>
      )}

      <MuiDialogActions className={classes.actions}>{actions}</MuiDialogActions>
    </MuiDialog>
  )
}

Dialog.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  onClose: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  // eslint-disable-next-line react/require-default-props
  actions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
}

Dialog.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  open: false,
}

export default Dialog
