/* eslint-disable react/no-danger */
import { useTheme } from '@material-ui/core'
import { Icon } from '@naturacosmeticos/natds-web'
import React from 'react'
import { useStyles } from './NotesCard.styles'

function applyOpacityToHex(hex: string, opacity: number): string {
  if (!/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    return hex
  }

  const hexWithoutHash = hex.replace('#', '')
  const hexWithOpacity = hexWithoutHash + Math.floor(opacity * 255).toString(16)

  return `#${hexWithOpacity}`
}

export function NotesCard({
 title = 'Importante', description, customType, type = 'info', icon = 'outlined-alert-info', withoutMarginTop = false,
}) {
  const theme = useTheme()

  const hasBorder = (type === 'custom' && Boolean(customType?.borderColor))
    || (type !== 'success' && type !== 'custom')

  function getMainColorByType() {
    let mainColor = 'info'

    switch (type) {
      case 'info':
        mainColor = 'info'
        break

      case 'success':
        mainColor = 'primary'
        break

      case 'error':
        mainColor = 'error'
        break

      case 'warning':
        mainColor = 'warning'
        break
      default:
        break
    }

    return mainColor
  }

  const colors = (() => {
    if (customType) {
      return {
        borderColor: customType.borderColor,
        backgroundColor: customType.backgroundColor,
        textColor: customType.textColor,
      }
    }

    const mainColor = getMainColorByType()
    const mainColorFromTheme = theme.palette[mainColor].main

    return {
      borderColor: mainColorFromTheme,
      backgroundColor: applyOpacityToHex(mainColorFromTheme, 0.3),
      textColor: theme.palette.text.primary,
    }
  })()

  const classes = useStyles({ ...colors, hasBorder, hasMarginTop: !withoutMarginTop })

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Icon name={icon} color="inherit" size="tiny" />
        <h3>{title}</h3>
      </div>

      <span dangerouslySetInnerHTML={{ __html: description }} className={classes.description} />
    </div>
  )
}
