import React from 'react'
import {
  Checkbox,
  FormControl,
  Typography,
  CircularProgress,
} from '@naturacosmeticos/natds-web'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SectionTitle from '../../SectionTitle'
import { useStyles } from './FilterMultipleOptions.styles'

export default ({
  title,
  options,
  checkedOptions = {},
  handleCheck,
  loading = false,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.filterGroupContainer}>
      <SectionTitle title={title} />
      <div className={classes.filterCheckboxesContainer}>
        {loading ? (
          <CircularProgress className={classes.loading} />
        ) : (
          <FormControl fullWidth>
            {options.map(({ id, name }) => (
              <FormControlLabel
                className={classes.formControlLabel}
                key={id}
                control={<Checkbox color="primary" name={name} value={id} />}
                name={name}
                label={<Typography variant="body2">{name}</Typography>}
                checked={!!checkedOptions[id]}
                onChange={(event) => handleCheck({
                  ...checkedOptions,
                  [event.target.value]: !checkedOptions[id],
                })}
              />
            ))}
          </FormControl>
        )}
      </div>
    </div>
  )
}
