import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'
import RegisterReportList from './RegisterReportList'
import { useResponsiveness } from '../../hooks/responsive'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  paper: {
    width: drawerWidth,
    borderRight: 'none',
  },
  drawerDesktopContainer: theme.mixins.toolbar,
}))

export default () => {
  const classes = useStyles()
  const { isDesktop } = useResponsiveness()

  if (isDesktop) {
    return (
      <>
        <Drawer
          variant="permanent"
          data-testid="drawerLeftMenu"
          PaperProps={{ elevation: 4 }}
          classes={{ paper: classes.paper }}
          className={classes.drawer}
        >
          <div>
            <div className={classes.drawerDesktopContainer} />
            <RegisterReportList />
          </div>
        </Drawer>
      </>
    )
  }
  return null
}
