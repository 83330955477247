import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  anchor: {
    textDecoration: 'none',
    marginLeft: '1rem',
  },
  editButton: {
    width: '190px',
  },
  editIcon: {
    paddingRight: '5px',
  },
})

export default useStyles
