import React from 'react'
import { FormattedMessage } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './Error.styles'
import { Icon } from '../Icon'

const ErrorInfo = () => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      classes={{ root: classes.error, container: classes.errorContainer }}
    >
      <Icon name="astonished" width={64} height={64} />
      <Typography
        color="textPrimary"
        variant="subtitle2"
        component="h2"
        classes={{ root: classes.title }}
      >
        <FormattedMessage
          id="errorTitle"
          defaultMessage="Sorry, we have a problem in our system…"
        />
      </Typography>
      <Typography
        color="textPrimary"
        variant="subtitle1"
        component="p"
        classes={{ root: classes.subtitle }}
      >
        <FormattedMessage
          id="errorSubtitle"
          defaultMessage="Please try again later."
        />
      </Typography>
    </Grid>
  )
}

export default ErrorInfo
