/* eslint-disable no-console */
import { takeLatest, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  GetPersonIdFromPeopleContext,
  createUserInfoByCognito,
  updateUserInfo,
  STORAGE_EXPIRESIN_KEY,
  STORAGE_ACCESS_TOKEN_KEY,
  STORAGE_ID_TOKEN_KEY,
} from '../../helpers/auth'
import { recoverSession } from '../../services/auth'
import { getAccessTokenSuccess, getAccessTokenFail } from './actions'
import { actions as userActions } from '../user/actionCreators'
import TYPES from '../types'
import { LOGOUT_ROUTE } from '../../Routes'

const { AUTH } = TYPES

export function* authenticate(action) {
  try {
    const { payload: { ssoToken, target } } = action
    const result = yield call(recoverSession, ssoToken)

    validateAcessToken(result.data)
    const { accessToken, expiresIn, idToken } = result.data

    const userInfo = createUserInfoByCognito(accessToken, idToken)
    updateUserInfo(userInfo)

    if (userInfo.country === 'BR' && userInfo.company === 2) {
      GetPersonIdFromPeopleContext(userInfo)
    }

    yield put(getAccessTokenSuccess({ accessToken, expiresIn, ssoToken }))
    yield saveAuthTokens({
      accessToken,
      expiresIn,
      idToken,
    })
    yield put(userActions.getUserStart(userInfo.personId, target))
  } catch (error) {
    yield put(getAccessTokenFail(error))
    yield put(push(LOGOUT_ROUTE))
  }
}

function saveAuthTokens({
  accessToken,
  expiresIn,
  idToken,
}) {
  localStorage.setItem(STORAGE_ACCESS_TOKEN_KEY, accessToken)
  localStorage.setItem(STORAGE_EXPIRESIN_KEY, expiresIn)

  if (idToken) {
    localStorage.setItem(STORAGE_ID_TOKEN_KEY, idToken)
  }
}

function validateAcessToken(result) {
  if (!result || (!result.accessToken && !result.expiresIn)) {
    throw new Error('access token is invalid')
  }
}

export default function* watchSaga() {
  yield takeLatest(AUTH.GET_ACCESS_TOKEN_START, authenticate)
}
