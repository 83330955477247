import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { CompanyId } from '../../../models/company-id'
import { CountryId } from '../../../models/country-id'
import { TENANT_ID } from '../tenantId'
import { getDefault } from './defaultConfigs'
import { statusOptions } from '../../../models/filterOptions'

export const getNaturaPeru = () => ({
  ...getDefault(),
  tenantId: TENANT_ID.NATURA_PERU,
  country: {
    alpha2Code: CountryId.PERU,
    alpha3Code: 'PER',
    language: 'es',
  },
  company: {
    name: 'NATURA',
    id: CompanyId.NATURA,
    logout: {
      buttonLinkHref: '/login?country=PER&company=NATURA',
      externalLink: false,
    },
  },
  redirectUrlToLogin: {
    url: async () => getConfigsMFEFirebase('HOSTED_UI_AUTHENTICATION_URL'),
    clientId: async () => getConfigsMFEFirebase('CLIENT_ID_COGNITO'),
  },
  filter: {
    origin: true,
  },
  shouldDisplayStructureCodeLevels: true,
  shouldDisplayDocumentAttachments: true,
  showEditButtonDetails: false,
  showChangeGroupButton: true,
  showColumnsRegistrantAndIntroducerSeller: false,
  canChangeGroup: [statusOptions.suggestedForStart, statusOptions.waitingFirstOrder],
  roles: {
    gn: {
      functionId: 17,
      roleId: 6,
    },
    leader: {
      functionId: 4,
      roleId: 6,
    },
    cb: {
      functionId: 1,
      roleId: 1,
    },
  },
  structureCodeLevels: [
    {
      level: 2,
      label: 'zone',
      defaultLabel: 'Zone',
    },
    {
      level: 3,
      label: 'unitCode',
      defaultLabel: 'Unit/Group Code',
    },
  ],
})
