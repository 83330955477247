import useSWR from 'swr'
import { getBffApiGatewayConnection } from '../../services/config'

export const makeGetProspectDetailsKey = (personId) => `/accounts/register-reports/v1/people/${personId}`

const swrCallback = async (url) => {
  const connection = await getBffApiGatewayConnection()
  const response = await connection.get(url)
  return response.data
}

export function useGetProspectDetailsSWR(personId) {
  const { data, error, revalidate } = useSWR(
    makeGetProspectDetailsKey(personId),
    swrCallback,
  )

  const reload = () => revalidate()

  return {
    userData: data,
    isLoading: !error && !data,
    hasError: Boolean(error),
    reload,
  }
}

export const exportToTest = {
  swrCallback,
}
