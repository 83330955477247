import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => {
  const title = {
    fontWeight: '500',
    flexGrow: 1,
    '@media (min-width: 769px)': {
      paddingLeft: '20px',
      borderLeft: '1px solid #ccc',
    },
  }

  return {
    icon: {
      paddingBottom: 0,
    },
    iconsContainer: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      margin: (props) => (props.isMobile ? '10px 0' : '0'),
    },
    title,
    titleMobileContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    titleMobile: {
      ...title,
      fontSize: '1rem',
      textAlign: 'center',
    },
    rightContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    avatarContainer: {
      marginLeft: theme.spacing(2),
    },
    centeredTitle: {
      ...title,
      textAlign: 'center',
    },
    naturaLogoContainer: {
      marginRight: '20px',
      width: 'auto',
      display: 'flex',
      justifyContent: 'center',
      '@media (min-width: 769px)': {
        width: '200px',
      },
    },
    eloLogoContainer: {
      margin: '10px -1px 10px 4px',
    },
    newRegisterButton: {
      padding: '6px 16px',
      borderRadius: '4px',
      fontWeight: 400,
      backgroundColor: theme.color.secondaryDark,
      color: theme.color.onSecondaryDarkest,
      '&:hover': {
        background: theme.color.secondaryDarkest,
      },
      '&:active': {
        backgroundColor: theme.color.secondaryDark,
      },
    },
    newRegisterAvonButton: {
      padding: '6px 16px',
      borderRadius: '4px',
      fontWeight: 400,
      backgroundColor: theme.color.primary,
      color: theme.color.onPrimaryDarkest,
      '&:hover': {
        background: theme.color.primaryDarkest,
      },
      '&:active': {
        backgroundColor: theme.color.primaryDark,
      },
    },
  }
})
