import axios from 'axios'
import { useSWRInfinite, cache } from 'swr'
import { getPeopleByProspectStatusService } from '../../../services/people'
import { getHasContactedFilterParam } from '../strategies/get-has-contacted-filter-param'

export const useGetPeoplePaginated = ({
  peopleStatus,
  peopleFilteredBy,
  filters,
  sort,
  limit,
  cancelToken,
}) => {
  const {
    data, error, size, setSize, mutate,
  } = useSWRInfinite(
    getKey({
      peopleStatus, peopleFilteredBy, filters, sort, limit,
    }),
    fetcher({ cancelToken }),
    {
      persistSize: true,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  )

  const people = data ? [].concat(...data) : []

  const getNextPage = () => {
    setSize(size + 1)
  }

  const resetPageSize = () => {
    setSize(1)
  }

  const clearAllCache = () => {
    cache.clear()
  }

  const isDataValid = () => data && data.length > 0

  const isDataOnLimit = () => data[data.length - 1].length === limit

  const hasSize = () => size > 0

  const isLastDataEmpty = () => typeof data[size - 1] === 'undefined'

  const hasError = error && !axios.isCancel(error)
  const hasNextPage = isDataValid() && isDataOnLimit()
  const isLoadingInitialData = !data && !hasError
  const isLoadingMore = isLoadingInitialData || (hasSize() && isDataValid() && isLastDataEmpty() && isDataOnLimit())

  return {
    people, hasError, hasNextPage, isLoading: isLoadingInitialData, isLoadingMore, clearAllCache, getNextPage, resetPageSize, mutate,
  }
}

function getKey({
  peopleStatus, peopleFilteredBy, filters, sort, limit,
}) {
  return (index, previousData) => {
    if (previousData?.length < limit) return null

    const stringifiedFilters = JSON.stringify(filters)

    return [index, peopleFilteredBy, peopleStatus, stringifiedFilters, sort, limit]
  }
}

function fetcher({ cancelToken }) {
  return async (...params) => {
    // eslint-disable-next-line
    const [index, peopleFilteredBy, peopleStatus, stringifiedFilters, sort, limit] = params
    const filters = JSON.parse(stringifiedFilters)

    const peopleData = await getPeopleByProspectStatusService({
      prospectStatus: peopleStatus,
      hasContacted: getHasContactedFilterParam(peopleFilteredBy),
      filters,
      sort,
      limit,
      offset: index,
      cancelToken,
    })

    return peopleData
  }
}
