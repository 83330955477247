import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: `${theme.spacing(2)}px`,
    borderRadius: `${theme.shape.borderRadius}px`,
    boxSizing: 'border-box',
    border: ({ hasBorder, borderColor, backgroundColor }) => `1px solid ${
      hasBorder ? borderColor : backgroundColor
    }`,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ textColor }) => (textColor.startsWith('#') || textColor.startsWith('rgb') ? textColor : theme.palette[textColor].main),

    '& *': {
      fontFamily: "'HelveticaNowText', sans-serif",
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),

    '& h3': {
      letterSpacing: '0.24px',
      fontSize: '20px',
      fontWeight: '500',
      margin: '0',
      padding: '0',
      marginLeft: theme.spacing(1),
    },
  },
  description: {
    display: 'inline-block',
    marginLeft: theme.spacing(3),
    fontSize: `${theme.typography.body1.fontSize}px`,
    letterSpacing: '0.51px',

    '& button, a': {
      color: 'inherit',
      textDecoration: 'underline',
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'inherit',
      outline: 'inherit',
    },
  },
}))
