import TYPES from '../types'

const { AUTH } = TYPES

export const getAccessTokenStart = (payload) => ({
  type: AUTH.GET_ACCESS_TOKEN_START,
  payload,
})

export const getAccessTokenSuccess = ({ accessToken, expiresIn, ssoToken }) => ({
  type: AUTH.GET_ACCESS_TOKEN_SUCCESS,
  payload: { accessToken, expiresIn, ssoToken },
})

export const getAccessTokenFail = (error) => ({
  type: AUTH.GET_ACCESS_TOKEN_FAIL,
  payload: { error },
})
