import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  anchor: {
    textDecoration: 'none',
  },
  editButton: {
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
  },
  editButtonMobile: {
    marginTop: '5px',
    width: (props) => (props.display === 'block' ? '100%' : 'auto'),
  },
  editIcon: {
    paddingRight: '5px',
  },
})

export default useStyles
