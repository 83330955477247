import React from 'react'
import { FormattedMessage } from 'react-intl'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { MenuItem } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'
import SectionTitle from '../../SectionTitle'
import { useStyles } from './FilterByRestart.styles'
import { Icon } from '../../Icon'

export const FilterByRestartOptions = [
  {
    id: 1,
    name: <FormattedMessage id="selectStartAndRestart" defaultMessage="All" />,
    value: 'ALL',
  },
  {
    id: 2,
    name: <FormattedMessage id="selectStart" defaultMessage="Just start" />,
    value: 'NEW',
  },
  {
    id: 3,
    name: <FormattedMessage id="selectRestart" defaultMessage="Restart only" />,
    value: 'RESTART',
  },
]

export const FilterByRestartOptionEnum = {
  ALL: 0,
  NEW: 1,
  RESTART: 2,
}

export const StyledIcon = styled(Icon)`
  height: 90%;
  display: flex;
  align-items: center;
`

export default ({
  canFilterByRestart, selectedRestart, onSelectRestart,
}) => {
  const classes = useStyles()

  if (!canFilterByRestart) {
    return null
  }

  return (
    <div className={classes.filterGroupContainer}>
      <SectionTitle
        title={<FormattedMessage id="startAndRestart" defaultMessage="Start and restart" />}
      />
      <div className={classes.filterSelectorContainer} id="group-filter">
        <FormControl fullWidth>
          <TextField
            value={selectedRestart.name}
            onChange={(e) => onSelectRestart(e.target.value)}
            SelectProps={{
              disableUnderline: true,
              renderValue: (value) => value,
              IconComponent: ({ className }) => (
                <StyledIcon
                  className={`${className} ${classes.filterSelectIcon}`}
                  name={
                    className === 'MuiSelect-iconOpen'
                      ? 'arrow_up'
                      : 'arrow_down'
                  }
                  width={24}
                  height={24}
                />
              ),
            }}
            select
          >
            {FilterByRestartOptions.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
    </div>
  )
}
