import implement from 'implement-js'
import { MainModeInterface } from '../../interfaces/main_mode'

import builtInAdapteePaths from '../builtin/paths'

// eslint-disable-next-line import/no-cycle
import builtInAdapteeLayout from '../builtin/layout'
import builtInAdapteeProviders from '../builtin/providers'
import adapteeStyles from './styles'

const BuiltInMode = {
  paths: builtInAdapteePaths,
  layout: builtInAdapteeLayout,
  providers: builtInAdapteeProviders,
  styles: adapteeStyles,
}

export default implement(MainModeInterface)(BuiltInMode)
