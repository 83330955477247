import React from 'react'

import { useParams } from 'react-router-dom'
import { useStyles } from './ActionButtons.styles'
import { RejectionButtonWithDialog } from './RejectionButtonWithDialog'
import { ApprovalButtonWithDialog } from './ApprovalButtonWithDialog'

export const ActionButtons = ({ name, personRoleId }) => {
  const classes = useStyles()
  const { id: personId } = useParams()

  return (
    <div className={classes.contentContainer}>
      <ApprovalButtonWithDialog
        name={name}
        personId={personId}
        personRoleId={personRoleId}
      />
      <RejectionButtonWithDialog
        name={name}
        personId={personId}
        personRoleId={personRoleId}
      />
    </div>
  )
}
