import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  icon: {
    width: '5rem',
    flex: '0 0 auto',
    height: '3.5rem',
    marginRight: '2rem',
    alignItems: 'center',
    display: 'flex',
  },
  label: {
    flex: '1 1 auto',
    fontWeight: 'normal',
  },
  checkbox: {
    flex: '0 0 auto',
      fontSize: '2.5rem',
  },
}))

export default useStyles
