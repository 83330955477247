import { nanoid } from 'nanoid'
import { AUTH_ROUTE } from '../../../Routes'
import { STORAGE_COMPANY_KEY, STORAGE_COUNTRY_KEY, STORAGE_DEVICE_ID_KEY } from '../../../helpers/auth'
import { getConfigsMFEFirebase } from '../../../helpers/mfeConfisFirebase'
import { getCountryConfiguration } from '../../../hooks/useTenantConfigs'

const getConfigs = () => {
  const country = localStorage.getItem(STORAGE_COUNTRY_KEY)
  const company = Number(localStorage.getItem(STORAGE_COMPANY_KEY))

  return getCountryConfiguration(company, country)
}

export const getDeviceIdParam = (location, configs) => {
  if (!configs?.auth?.useDeviceId) return

  const searchParams = new URLSearchParams(location.search)
  const newDeviceId = searchParams.get(STORAGE_DEVICE_ID_KEY) || localStorage.getItem(STORAGE_DEVICE_ID_KEY) || nanoid()
  localStorage.setItem(STORAGE_DEVICE_ID_KEY, newDeviceId)
  return newDeviceId
}

export const redirectToHostedUi = async (location, configs) => {
  try {
    const clientId = getConfigsMFEFirebase('CLIENT_ID_COGNITO') || process.env.REACT_APP_CLIENT_ID_COGNITO

    const {
      country: { language, alpha2Code },
      company: { name },
    } = getConfigs() || configs
    const countryName = alpha2Code.toLowerCase()
    const companyName = name.toLowerCase()

    const redirectUri = `${window.location.origin}${AUTH_ROUTE}`

    const urlFirebase = getConfigsMFEFirebase('AUTH_URL') || process.env.REACT_APP_HOSTED_UI_AUTHENTICATION_URL

    const url = new URL(urlFirebase)

    url.searchParams.set('client_id', clientId)
    url.searchParams.set('country', countryName)
    url.searchParams.set('language', language)
    url.searchParams.set('company', companyName)
    url.searchParams.set('redirect_uri', redirectUri)
    if (configs?.auth?.useDeviceId) {
      const deviceIdParam = getDeviceIdParam(location, configs)
      url.searchParams.set(STORAGE_DEVICE_ID_KEY, deviceIdParam)
      localStorage.setItem(STORAGE_DEVICE_ID_KEY, deviceIdParam)
    }

    window.location.href = url.href
  } catch (error) {
    window.location.href = process.env.REACT_APP_GLOBAL_ACCOUNT_URL
  }
}
