import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  filterGroupContainer: {
    paddingTop: theme.spacing(3),
  },
  filterCheckboxesContainer: {
    padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
    display: 'flex',
    justifyContent: 'center',
  },
  formControlLabel: {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  loading: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}))
