import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon } from '@naturacosmeticos/natds-web'
import Button from '../../../../components/Buttons/Button'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import useStyles from './EmailButton.styles'

export function EmailButton({ email }) {
  const classes = useStyles()
  const { configs } = useTenantConfigs()
  const intl = useIntl()

  if (!configs?.showEmailButton) return null

  return (
    <a
      className={classes.anchor}
      href={email ? `mailto:${email}` : ''}
      rel="noopener noreferrer"
      aria-label="open email button"
      title={email ? undefined : intl.formatMessage({ id: 'emailButtonDisabled', defaultMessage: 'Email is not registered' })}
    >
      <Button
        variant="outlined"
        color="primary"
        id="email"
        size="medium"
        type="button"
        startIcon={<Icon name="outlined-communication-email" />}
        disabled={!email}
      >
        <FormattedMessage id="emailButton" defaultMessage="EMAIL" />
      </Button>
    </a>
  )
}
