import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useResponsiveness } from '../../hooks/responsive'
import { isStandAloneLayout } from '../../helpers/mfeConfig'
import { appMode } from '../../adapter'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  childrenContainer: {
    flexGrow: 1,
    '@media (min-width: 769px)': {
      padding: (props) => (props.withoutPadding ? '0' : '24px 16px'),
    },
  },
  drawerDesktopContainer: theme.mixins.toolbar,
}))

const ScreenContainer = ({
 hasDrawer, title, children, headerButtons, withoutPadding, mobileTitle,
}) => {
  const classes = useStyles({ withoutPadding })
  const { isMobile, isDesktop } = useResponsiveness()

  const { Header, Drawer } = appMode().layout

  return (
    <>
      <Header title={title} mobileTitle={mobileTitle}>{isMobile && headerButtons}</Header>
      {isStandAloneLayout() && (
        <div className={isDesktop ? classes.drawerDesktopContainer : ''} />
      )}
      <div className={classes.container}>
        {hasDrawer && <Drawer />}
        <div className={classes.childrenContainer}>{children}</div>
      </div>
    </>
  )
}

ScreenContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  title: PropTypes.any,
  // eslint-disable-next-line react/require-default-props
  hasDrawer: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  headerButtons: PropTypes.any,
}

ScreenContainer.defaultValues = {
  hasDrawer: false,
  headerButtons: null,
}

export default ScreenContainer
