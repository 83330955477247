const setShowStatusFilter = (store, showStatus) => {
  store.state.filters.showStatus = showStatus
  store.setState(store.state)
}

const setProspectStatusScreen = (store, prospectScreen) => {
  store.state.filters.prospectStatusScreen = prospectScreen
  store.setState(store.state)
}

export default {
  setProspectStatusScreen,
  setShowStatusFilter,
}
