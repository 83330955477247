import React, { useState } from 'react'
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
   } from '@naturacosmeticos/natds-web'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'
import Button from '../../../components/Buttons/Button'

const useStyles = makeStyles({
    dialogContent: {
      maxWidth: '600px',
    },
    dialogText: {
        color: '#000',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
  })

export const DialogConfirm = ({ open, onClose, onSubmit }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  return (
    <Dialog open={open} data-testid="dialogConfirm">
      <DialogTitle>
        <FormattedMessage id="termsOfConfidentiality" defaultMessage="Terms Of Confidentiality" />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogText}>
          <FormattedMessage
            id="dialogExcelBody"
            defaultMessage="Terms Of Confidentiality"
            values={{
              br: <br />,
            }}
          />

        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button
          data-testid="dialogConfirmOnclose"
          onClick={() => onClose()}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          <FormattedMessage id="noAccepted" defaultMessage="Back" />
        </Button>
        <Button
          data-testid="dialogConfirmSubmit"
          onClick={() => {
            setLoading(true)
            onSubmit()
          }}
          color="primary"
          variant="contained"
          loading={loading}
          disabled={loading}
        >
          <FormattedMessage id="accepted" defaultMessage="accepted" />

        </Button>
      </DialogActions>
    </Dialog>
  )
}
