import React, { useEffect, useState } from 'react'
import { Badge } from '@naturacosmeticos/natds-web'
import { useDispatch, useSelector } from 'react-redux'

import IconButton from '../Buttons/IconButton'
import Filter from '../Filter/Filter'
import { ProspectTableActions } from '../../redux/combinedActions'
import { Icon } from '../Icon'

export default () => {
  const [isFilterOpen, setFilterOpen] = useState(false)
  const dispatch = useDispatch()

  const onOpenFilter = () => setFilterOpen(true)
  const onCloseFilter = () => setFilterOpen(false)
  const { filterCounter } = useSelector(({ prospectTable }) => prospectTable)

  useEffect(() => () => {
        dispatch(ProspectTableActions.setFilters({}))
      }, [dispatch])

  return (
    <>
      <IconButton
        id="filter-button"
        aria-label="Filter"
        onClick={onOpenFilter}
      >
        <Badge badgeContent={filterCounter} color="error" aria-label="badge">
          <Icon name="filter" width={24} height={24} />
        </Badge>
      </IconButton>
      <Filter open={isFilterOpen} onClose={onCloseFilter} />
    </>
  )
}
