import { FilterPeopleTableOptions } from '../people-table-options'

const strategies = {
  [FilterPeopleTableOptions.ALL]: () => undefined,
  [FilterPeopleTableOptions.HAS_CONTACTED]: () => true,
  [FilterPeopleTableOptions.NEW_REGISTERS]: () => false,
}

export const getHasContactedFilterParam = (filterPeopleTableOption) => {
  const strategy = strategies[filterPeopleTableOption]

  if (strategy) {
    return strategy()
  }

  throw new Error('You must implement getHasContactedFilterParam strategy')
}
