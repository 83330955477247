export default {
  subStatusActiveCnPendingCsc: 'Papel CBN Activo',
  subStatusActiveDebt: 'Rechazado por deuda',
  subStatusActiveSeller: 'Consultora Activa',
  subStatusAddressIncomplete: 'Dirección incompleta',
  subStatusAddressValidationFail: 'Dirección inválida',
  subStatusAllocationStructureError: 'Error al alocar estructura commercial',
  subStatusApprovedByGr: 'Aprobada por GR',
  subStatusApprovedByGv: 'Aprobada por GV',
  subStatusApprovedSeller: 'Aprobada',
  subStatusAttachmentMissing: 'Falta de envío de documentos',
  subStatusAttachmentValidationFail: 'Error en la validación de documentos',
  subStatusAttachmentValidationSuccess: 'Validación de documentos con éxito',
  subStatusBrmBadParameters: 'Parámetros BRM incorrectos',
  subStatusCeased: 'Cesado',
  subStatusCepValidationFail: 'Código postal no encontrado',
  subStatusClearSaleValidationFail: 'Servicio no disponible',
  subStatusCnActive: 'Papel CBN Activo',
  subStatusCreatingPerson: 'Creación del candidato falló',
  subStatusCreditRatingError: 'Registro en análisis crediticio',
  subStatusCreditValidation: 'Rechazado Políticas de Natura',
  subStatusDocumentPosted: 'Documento registrado',
  subStatusEmailExistent: 'Email existente',
  subStatusErrorCompleteDataOnBrm: 'Registro en proceso en BRM',
  subStatusEstablishmentNotFound: 'Establecimiento no encontrado',
  subStatusExistentEmailOnGera: 'Email existente en GERA',
  subStatusExistentRole: 'Papel ya existe',
  subStatusFailureCeaseGera: 'Error al cesar en GERA',
  subStatusFinalDataValidation: 'Validación final de datos',
  subStatusGeraDebtServiceError: 'Error al verificar deuda',
  subStatusIntegrationErrorWithGera: 'Registro en proceso en GERA',
  subStatusManuallyApproved: 'Aprobado manualmente',
  subStatusNameValidation: 'Restricción de nombre',
  subStatusOpenTitle: 'Rechazados en Valores',
  subStatusPendingAddressBlocklistReview: 'Pendiente análisis blocklist',
  subStatusPendingAddressReview: 'Pendiente Análisis: Dirección',
  subStatusPendingAllocate: 'Asignación pendiente',
  subStatusPendingApproval: 'Pendiente aprobación',
  subStatusPendingCommercialStructureNotCovered: 'No encuentra estructura comercial',
  subStatusPendingCreditAnalysis: 'Análisis de crédito pendiente',
  subStatusPendingCscApproval: 'Pendiente aprobación CSC',
  subStatusPendingData: 'Datos Pendientes',
  subStatusPendingDigitalSpace: 'Espacio digital pendiente',
  subStatusPendingDocumentation: 'Documentación pendiente',
  subStatusPendingEmailVerification: 'Pendiente validar contactos',
  subStatusPendingGrApproval: 'Pendiente aprobación GR',
  subStatusPendingGvApproval: 'Pendiente aprobación GV',
  subStatusPendingManualAssignment: 'Asignación manual pendiente',
  subStatusPendingPrepaid: 'Pendiente Pre Pago',
  subStatusPendingRegistrationApproval: 'Análisis de Registro Pendiente',
  subStatusPendingRestartReview: 'Pendiente Análisis Reinicio',
  subStatusProcessing: 'En Proceso',
  subStatusQuarantine: 'Cuarentena',
  subStatusRejectedByBlocklist: 'Rechazado por blocklist',
  subStatusRejectedByCsc: 'Rechazado por CSC',
  subStatusRejectedByGr: 'Rechazado por GR',
  subStatusRejectedByGv: 'Rechazado por GV',
  subStatusRejectedByRegisterTeam: 'Rechazado por Equipo de Registro',
  subStatusRejectedByMinimalPeriodAsCeased: 'Rechazado por período mínimo como cesado',
  subStatusRejectedWithReason: 'Rechazado con motivo',
  subStatusRejectedByEquifax: 'Rechazado por Equifax',
  subStatusRejectedByAgs: 'Rechazado por AGS',
  subStatusRenunciationCandidate: 'Desistencia Candidato',
  subStatusRenunciationNatura: 'Desistencia Natura',
  subStatusRestrictedAddress: 'Dirección sin cobertura',
  subStatusRestrictedRole: 'Papel restringido',
  subStatusRestrictionEmail: 'Restricción de email',
  subStatusRestrictionTelephone: 'Teléfono restringido',
  subStatusRetrieveCycleError: 'Error al recuperar el ciclo',
  subStatusSystemFail: 'Fallo de sistema',
  subStatusTermOfAcceptance: 'Plazo de aceptación',
  subStatusWithoutAddress: 'Sin dirección',
  subStatusWorkflowExecutionError: 'Error de ejecución de workflow',
  subStatusAgreementsServiceError: 'Error al aceptar los terminos',
  subStatusInvalidAddresses: 'Direcciones erradas',
  subStatusInvalidDeliveryAddress: 'Dirección de entrega errada',
  subStatusInvalidMailingAddress: 'Dirección residencial errada',
  subStatusMissingConfirmationCode: 'Pendiente autorización de términos y condiciones',
  subStatusSitidataServiceError: 'Erro Sitidata',
  subStatusPendingDigitalSignature: 'Sin firma digital',
  subStatusPendingIdentityValidation: 'Pendiente de validación de identidad',
  subStatusPendingName: 'Nombre pendiente',
  subStatusPendingCellphone: 'Validación de celular pendiente',
  subStatusPendingCep: 'Pendiente CEP',
  subStatusPendingEmail: 'Pendiente e-mail',
  subStatusPendingBirthday: 'Fecha de nacimiento pendiente',
  subStatusPendingGender: 'Género pendiente',
  subStatusPendingAddressDetails: 'Detalles de la dirección pendiente',
  subStatusPendingAcceptDs: 'Pendiente de aceptación loja digital',
  subStatusPendingCreateDs: 'Pendiente crear loja digital',
  subStatusPendingUploadDoc: 'Carga de documento pendiente',
  subStatusPendingPasswordCreation: 'Creación de contraseña pendiente',
  subStatusPendingWorkflow: 'Workflow pendiente',
  subStatusRejectedDocs: 'Documentos rechazados',
  subStatusPendingEquifaxValidation: 'Validación Equifax Pendiente',
  subStatusPendingMaritalStatus: 'Estado Civil Pendiente',
  subStatusPendingSourceOfFunds: 'Origen de Fundos Pendiente',
  subStatusPendingNomination: 'Indicación pendiente',
  subStatusPendingToken: 'Pendiente de validación del token SMS',
}
