/* eslint-disable react/jsx-one-expression-per-line */
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useStyles } from './IntroducerSellerCell.styles'

export const IntroducerSellerCell = ({ introducerSeller }) => {
  const classes = useStyles()
  if (!introducerSeller) {
    return (
      <Typography variant="body1" classes={{ root: classes.name }}>
        -
      </Typography>
    )
  }
  const containsId = introducerSeller.includes(' - ')
  if (containsId) {
    const [id, name] = introducerSeller.split(' - ')
    return (
      <>
        <Typography variant="body1" classes={{ root: classes.name }}>
          {name}
        </Typography>
        <Typography classes={{ root: classes.id }} variant="overline">
          <strong>ID:</strong>
          {id}
        </Typography>
      </>
    )
  }
  return (
    <Typography variant="body1" classes={{ root: classes.name }}>
      {introducerSeller}
    </Typography>
  )
}
