import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/en-ie'
import { CompanyId } from '../models/company-id'

export const COUNTRY_TO_LANGUAGE_TAG = {
  pe: 'es-pe',
  co: 'es-co',
  us: 'en',
  br: 'pt-br',
  my: 'en',
}

export const COMPANY_TO_LANGUAGE_TAG = {
  [CompanyId.NATURA]: {
    pe: true,
  },
}

export const snakeToCamel = (str) => {
  if (str && typeof str === 'string') {
    return str
      .toLowerCase()
      .replace(/([-_][\d\w])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
  }

  return ''
}

export const calcFromNow = (date, language = 'en') => {
  moment.locale(language)
  // eslint-disable-next-line new-cap
  const momentDate = new moment(date)
  return momentDate.fromNow(true)
}

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))

  return JSON.parse(jsonPayload)
}
