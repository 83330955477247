export const KEYCLOAK_CONFIG = {
  'auth-server-url': process.env.REACT_APP_AUTHENTICATION_URL,
  'ssl-required': 'none',
  'public-client': true,
  'confidential-port': 0,
  url: process.env.REACT_APP_AUTHENTICATION_URL,
  realm: 'Natura',
  clientId: 'Accounts',
  clientSecret: '8a0022d7-9942-404f-b9d4-01d911fdca05',
  onLoad: 'login-required',
  resource: 'Accounts',
}
export const KEYCLOAK_INIT_CONFIG = {
  checkLoginIframe: false,
}

export const LOCAL_STORAGE_PREFIX = '@naturaReports:'
