import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { NavLink, useLocation } from 'react-router-dom'

import { useStyles } from './RegisterReportList.styles'
import { useResponsiveness } from '../../hooks/responsive'
import { useGetMenuItemsSWR } from '../../hooks/swrServices/use-get-menu-items-swr'
import Loading from '../Loading'

function FormattedListItem(props) {
  const {
    messageId, defaultMessage, pathName, classes,
  } = props
  const location = useLocation()
  const { isMobile } = useResponsiveness()

  return (
    <ListItem
      button
      classes={{ root: classes.listItemRoot }}
      activeClassName={classes.selected}
      component={NavLink}
      to={{ pathname: pathName, search: location.search }}
    >
      <ListItemText
        primary={(
          <Typography color="textPrimary" variant="body2" noWrap>
            <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
          </Typography>
        )}
      />
      {isMobile && (
        <ChevronRightIcon
          data-testid="chevronRightIcon"
          classes={{ root: classes.chevron }}
        />
      )}
    </ListItem>
  )
}

const RegisterReportList = () => {
  const classes = useStyles()
  const { menuItems, isLoading } = useGetMenuItemsSWR()

  if (isLoading) return <Loading />

  return (
    <List
      className={classes.list}
      component="nav"
      aria-label="main mailbox folders"
    >
      {menuItems ? menuItems.map((menuItem) => (
        <FormattedListItem
          key={menuItem.pathName}
          pathName={menuItem.pathUrl}
          messageId={menuItem.messageId}
          defaultMessage={menuItem.defaultMessage}
          classes={classes}
        />
      )) : null}
    </List>
  )
}

export default RegisterReportList
