import React, { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'
import { useCognito } from '../../hooks/useCognito'
import { AUTH_ROUTE } from '../../Routes'
import { ScreenLoader } from '../../components/ScreenLoader/ScreenLoader'
import { useTenantConfigs } from '../../hooks/useTenantConfigs'

export const Logout = () => {
  const { keycloak } = useKeycloak()
  const { isEnable: isCognitoEnabled, loading: isCognitoLoading, signOutUser } = useCognito()
  const { isLoading: isTenantConfigsLoading, configs, error: tenantConfigsError } = useTenantConfigs()
  const history = useHistory()
  const loading = isCognitoLoading || isTenantConfigsLoading

  useEffect(() => {
    const logoutWithKeycloak = () => {
      const { country: { alpha2Code } } = configs
      const currentLocationOrigin = window.location.origin
      const redirectUri = `${currentLocationOrigin}${AUTH_ROUTE}?country=${alpha2Code}`

      keycloak.logout({ redirectUri })
    }

    const logoutWithCognito = () => {
      signOutUser().then(() => {
        const { company: { logout: { buttonLinkHref, externalLink } } } = configs
        if (externalLink) window.location.href = buttonLinkHref
        else history.push(buttonLinkHref)
      })
    }

    const logout = () => {
      if (loading || tenantConfigsError) {
        return
      }
      if (isCognitoEnabled) {
        logoutWithCognito()
        return
      }
      logoutWithKeycloak()
    }

    logout()
  }, [loading, isCognitoEnabled, history, tenantConfigsError])
  return <ScreenLoader />
}
