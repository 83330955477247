import React, { useState, useEffect } from 'react'
import {
  Typography,
  GridContainer,
  GridItem,
  Icon,
} from '@naturacosmeticos/natds-web'

const Countdown = ({
  timerMessage,
  countdown = 30,
  open = false,
  onClose,
}) => {
  const countdownCodeMessage = () => timerMessage.replace('{countdown}', updatedCountdown.toString())

  const [updatedCountdown, setUpdatedCountdown] = useState(countdown)
  const [showCountdown, setShowCountdown] = useState(false)

  useEffect(() => {
    if (open) startCountdown()
  }, [open])

  useEffect(() => {
    let timer
    if (showCountdown) {
      timer = setTimeout(() => {
        setUpdatedCountdown(updatedCountdown - 1)
      }, 1000)

      if (updatedCountdown === 0) {
        setShowCountdown(false)
        onClose()
      }
    }

    return () => clearTimeout(timer)
  }, [showCountdown, updatedCountdown])

  const startCountdown = () => {
    setUpdatedCountdown(countdown)
    setShowCountdown(true)
  }

  return (
    <>
      { showCountdown && (
        <GridContainer data-testid="prospect-countdown" alignItems="center" spacing={1} justify="center">
          <GridItem>
            <Icon
              name="outlined-action-clock"
              size="tiny"
              color="primary"
            />
          </GridItem>

          <GridItem>
            <Typography data-testid="timer-text-message" variant="body2">
              {countdownCodeMessage()}
            </Typography>
          </GridItem>
        </GridContainer>
      )}
    </>
  )
}

export default Countdown
