import { createReducer } from 'reduxsauce'
import TYPES from '../types'

const INITIAL_STATE = {
  filters: {},
  filterCounter: 0,
}

const setFiltersActionHandler = (state = INITIAL_STATE, action) => {
  const { filters = {} } = action

  const filterCounter = Object.values(filters).reduce((counter, filter) => {
    if (filter && typeof filter === 'string') {
      return counter + filter.split(',').length
    }
    if (filter && filter.name) {
      return counter + 1
    }
    return counter
  }, 0)

  return ({
    ...state,
    filters,
    filterCounter,
  })
}

export default createReducer(INITIAL_STATE, {
  [TYPES.PROSPECT_TABLE.SET_FILTERS]: setFiltersActionHandler,
})
