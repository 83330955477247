import React from 'react'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './SectionTitle.styles'

export default ({ title }) => {
  const classes = useStyles()
  return (
    <div className={classes.filterTitleContainer}>
      <div className={classes.filterDecorator} />
      <Typography>{title}</Typography>
    </div>
  )
}
