import implement from 'implement-js'
import { PathsInterface } from '../../interfaces/paths'
import { LOCAL_STORAGE_PREFIX } from '../../../Constants'

const getScopedPath = () => '/webfv/my-registers'

const consultantDetailsPath = (location, personId, isUnderApproval, candidateId) => {
  if (isUnderApproval) {
    return `/show/${personId}`
  }
  localStorage.setItem(`${LOCAL_STORAGE_PREFIX}consultantCode`, candidateId)
  return '/loading/person'
}

const Paths = {
  getScopedPath,
  consultantDetailsPath,
}

export default implement(PathsInterface)(Paths)
