import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  anchor: {
    textDecoration: 'none',
  },
  editIcon: {
    paddingRight: '5px',
  },
})

export default useStyles
