import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  anchorsContainer: {
    flex: ' 1 1 100%',
    display: ' flex',
    flexFlow: ' row wrap',
    marginBottom: ' 2rem',
  },
  anchor: {
    backgroundColor: '#f8f8f8',
    border: '#f8f8f8 0.5rem solid',
    width: '100%',
    maxWidth: '9rem',
    minHeight: '5rem',
    boxSizing: 'border-box',
    flex: '1 1 auto',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.1s ease-in',
    padding: '1rem',
    margin: '1rem 1rem 0 0',
    wordBreak: 'break-word',
    '&:hover': {
      borderColor: 'rgba(110,90,167,0.05)',
    },
  },
})

export default useStyles
