import React from 'react'
import {
    Dialog, DialogContent, DialogActions,
   } from '@naturacosmeticos/natds-web'
import { makeStyles } from '@material-ui/core/styles'
import ErrorInfo from '../../../components/Error/ErrorInfo'

import Button from '../../../components/Buttons/Button'

const useStyles = makeStyles({
    dialogContent: {
      maxWidth: '600px',
      '&>div': {
        position: 'unset',
      },
    },
    dialogActions: {
        display: 'content',
        justifyContent: 'center',
    },
  })

export const DialogErrorDownload = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog open={open} data-testid="dialogErrorDownload">
      <DialogContent className={classes.dialogContent} data-testid="content">
        <ErrorInfo />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          data-testid="dialogErrorDownloadOnclose"
          onClick={() => onClose()}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>

    </Dialog>
  )
}
