import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { useStyles } from './TableList.styles'
import CustomTableHead from './TableHead'
import TableResults from './TableResults'
import SearchResultList from './SearchResultList'

const TableList = ({
  columns,
  data,
  disableCheckboxes = false,
  emptyListText,
  hasNextPage,
  loading,
  loadingMore,
  onLoadMoreItems,
  onOrder,
  onRowClick,
  onSelectAll,
  onSelectRow,
  orderBy,
  orderDirection = 'desc',
  id,
}) => {
  const { filters } = useSelector(({ prospectTable }) => prospectTable)
  const classes = useStyles()

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    )
  }

  if (data && data.length <= 0) {
    return (
      <div className={classes.emptyListContainer}>
        <Typography variant="subtitle1">
          {emptyListText}
        </Typography>
      </div>
    )
  }

  const tableResultProps = {
    columns,
    data,
    disableCheckboxes,
    hasNextPage,
    loadingMore,
    onLoadMoreItems,
    onRowClick,
    onSelectRow,
  }

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
        id={id}
      >
        <CustomTableHead
          onOrder={onOrder}
          onSelectAll={onSelectAll}
          orderBy={orderBy}
          orderDirection={orderDirection}
          columns={columns}
          data={data}
          disableCheckboxes={disableCheckboxes}
        />
        {data && data.length > 0 && !filters.document ? (
          <TableResults
            key="table-results"
            {...tableResultProps}
          />
        ) : (
          <SearchResultList
            key="search-results"
            {...tableResultProps}
          />
          )}
      </Table>
    </TableContainer>
  )
}

TableList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      labelId: PropTypes.string,
      labelDefaultMessage: PropTypes.string,
      dataIndex: PropTypes.string,
      width: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  orderDirection: PropTypes.string.isRequired,
  onOrder: PropTypes.func.isRequired,
  emptyListText: PropTypes.node,
}

TableList.defaultProps = {
  emptyListText: undefined,
}

export default TableList
