import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  chevron: {
    fill: theme.palette.grey[400],
  },
  list: {
    padding: '8px 8px 16px',
    '@media (min-width: 769px)': {
      paddingTop: '16px',
    },
  },
  listItemRoot: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.sizes.tiny,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selected: {
    backgroundColor: theme.palette.action.hover,
  },
}))
