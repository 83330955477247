import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '16px 8px',
  },
  infoContainer: {
    gridColumn: 'span 1',
  },
  infoContainerFullWidth: {
    gridColumn: 'span 2',
  },
  infoTitle: {
    fontWeight: 'bold',
  },
  actionsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: 16,
    padding: '8px 0 16px',
    width: '100%',
  },
  textButtonLabel: {
    color: theme.palette.grey[500],
    fontWeight: 'normal',
    textTransform: 'none',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    textAlign: 'center',
  },
}))
