import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import { GridContainer, GridItem, Tag } from '@naturacosmeticos/natds-web'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { useResponsiveness } from '../../../hooks/responsive'

export const SourceSystemLabelsLog = {
  10: 'labelLogBackoffice',
  100: 'labelLogDigitalSpace',
  0: 'labelLogNatbotDialmyapp',
  98: 'labelLogOther',
  7: 'labelLogOther',
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: 'inherit',
  },
  listItemWithMargin: {
    cursor: 'inherit',
    marginBottom: '10px',
  },
  customTag: {
    borderRadius: '0 100px 100px 0',
    padding: '0px 8px 0px 5px',
    backgroundColor: theme.color.primaryLightest,
  },
  gridItemFlex: {
    display: 'flex',
  },
  typographyWithMarginLeft: {
    marginRight: '5px',
  },
}))

const CustomCardLog = ({ fieldId, dataCardLog }) => {
  const { isMobile } = useResponsiveness()
  const { updatedAt = '', lastUpdatedFrom = '', updatedBy = '' } = dataCardLog.dataLog || {}

  const personId = dataCardLog.personId || ''

  const classes = useStyles()

  const findLabelLog = () => {
    if (!lastUpdatedFrom) {
      return ''
    }

    const labelOther = <FormattedMessage defaultMessage="Outros" id="labelLogOther" />
    const labelLogMyPerfilCbWeb = <FormattedMessage defaultMessage="Meu Perfil CB Web" id="labelLogMyPerfilCbWeb" />
    const labelLogReportsFvApp = <FormattedMessage defaultMessage="Reports FV App" id="labelLogReportsFvApp" />
    const labelLogReportsFvWeb = <FormattedMessage defaultMessage="Reports FV Web" id="labelLogReportsFvWeb" />
    const labelLogMyPerfilCbApp = <FormattedMessage defaultMessage="Meu Perfil CB App" id="labelLogMyPerfilCbApp" />

    const match = updatedBy ? updatedBy.match(/MANUTEND-(.*)/) : ''
    const valuePersonId = match ? match[1] : ''

    if (lastUpdatedFrom.toString() === '102') {
      if (match) {
        if (valuePersonId === personId) {
          return labelLogMyPerfilCbWeb
        }
          return labelLogReportsFvWeb
      }
        return labelOther
    }

    if (lastUpdatedFrom.toString() === '101') {
      if (match) {
        if (valuePersonId === personId) {
          return labelLogMyPerfilCbApp
        }
          return labelLogReportsFvApp
      }
        return labelOther
    }
    const idElemnt = SourceSystemLabelsLog[lastUpdatedFrom]
    return idElemnt ? <FormattedMessage defaultMessage="" id={idElemnt} /> : labelOther
  }

  const formatDateTime = (dateTime) => {
    if (!dateTime) {
      return {
        formattedDate: ' - ',
        formattedTime: ' - ',
      }
    }

    const date = moment(dateTime)

    // Formatando a data como DD/MM/YYYY
    const formattedDate = dateTime ? date.format('DD/MM/YYYY') : ' - '

    // Formatando a hora como hh:mm
    const formattedTime = dateTime ? date.format('HH:mm') : ' - '

    return { formattedDate, formattedTime }
  }

  const { formattedDate, formattedTime } = formatDateTime(updatedAt)
  const lastUpdatedFromLabel = findLabelLog()

  return (
    <>
      {isMobile ? (
        <>
          <ListItem className={classes.listItem} data-testid={`${fieldId}-custom-card-log-tile`}>
            <Tag color="warning" className={classes.customTag}>
              <FormattedMessage defaultMessage="Última atualização" id="personalContactLastUpdateTitle" />
            </Tag>
          </ListItem>
          <ListItem className={classes.listItem} data-testid={`${fieldId}-custom-card-log-itens`}>
            <GridContainer direction="row" wrap="nowrap" justify={isMobile ? 'left' : 'center'}>
              <GridItem md={12} sm={12} className={classes.gridItemFlex}>
                <Typography
                  className={classes.typographyWithMarginLeft}
                  variant="body2"
                  color="textSecondary"
                  data-testid={`${fieldId}-custom-card-log-last-update-date`}
                >
                  <FormattedMessage defaultMessage="Data:" id="personalContactLastUpdateDate" />
                </Typography>
                <Typography variant="body2" data-testid={`${fieldId}-custom-card-log-last-update-date`}>
                  {formattedDate}
                </Typography>
              </GridItem>
            </GridContainer>
          </ListItem>
          <ListItem className={classes.listItem} data-testid={`${fieldId}-custom-card-log-itens`}>
            <GridContainer direction="row" wrap="nowrap" justify={isMobile ? 'left' : 'center'}>
              <GridItem md={12} sm={12} className={classes.gridItemFlex}>
                <Typography
                  className={classes.typographyWithMarginLeft}
                  variant="body2"
                  color="textSecondary"
                  data-testid={`${fieldId}-custom-card-log-last-update-datetime`}
                >
                  <FormattedMessage defaultMessage="Horário:" id="personalContactLastUpdateDatetime" />
                </Typography>
                <Typography variant="body2" data-testid={`${fieldId}-custom-card-log-last-update-date`}>
                  {formattedTime}
                </Typography>
              </GridItem>
            </GridContainer>
          </ListItem>
        </>
      ) : (
        <>
          <ListItem className={classes.listItem} data-testid={`${fieldId}-custom-card-log-tile`}>
            <Tag color="warning" className={classes.customTag}>
              <FormattedMessage defaultMessage="Última atualização" id="personalContactLastUpdateTitle" />
            </Tag>
          </ListItem>
          <ListItem className={classes.listItem} data-testid={`${fieldId}-custom-card-log-itens`}>
            <GridContainer direction="row" wrap="nowrap" justify={isMobile ? 'left' : 'center'}>
              <GridItem md={12} sm={12} className={classes.gridItemFlex}>
                <Typography
                  className={classes.typographyWithMarginLeft}
                  variant="body2"
                  color="textSecondary"
                  data-testid={`${fieldId}-custom-card-log-last-update-date`}
                >
                  <FormattedMessage defaultMessage="Data:" id="personalContactLastUpdateDate" />
                </Typography>
                <Typography variant="body2" data-testid={`${fieldId}-custom-card-log-last-update-date`}>
                  {formattedDate}
                </Typography>
              </GridItem>
              <GridItem md={12} sm={12} className={classes.gridItemFlex}>
                <Typography
                  className={classes.typographyWithMarginLeft}
                  variant="body2"
                  color="textSecondary"
                  data-testid={`${fieldId}-custom-card-log-last-update-datetime`}
                >
                  <FormattedMessage defaultMessage="Horário:" id="personalContactLastUpdateDatetime" />
                </Typography>
                <Typography variant="body2" data-testid={`${fieldId}-custom-card-log-last-update-date`}>
                  {formattedTime}
                </Typography>
              </GridItem>
            </GridContainer>
          </ListItem>
        </>
      )}
      {lastUpdatedFrom && lastUpdatedFromLabel && (
        <ListItem className={classes.listItemWithMargin} data-testid={`${fieldId}-custom-card-log-item`}>
          <GridContainer direction="row" wrap="nowrap" justify={isMobile ? 'left' : 'center'}>
            <GridItem md={12} sm={12} className={classes.gridItemFlex}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typographyWithMarginLeft}
                data-testid={`${fieldId}-custom-card-log-last-update-by`}
              >
                <FormattedMessage defaultMessage="Atualizado via:" id="personalContactLastUpdateBy" />
              </Typography>
              <Typography variant="body2" data-testid={`${fieldId}-custom-card-log-last-update-date`}>
                {lastUpdatedFromLabel}
              </Typography>
            </GridItem>
          </GridContainer>
        </ListItem>
      )}
    </>
  )
}

export default CustomCardLog
