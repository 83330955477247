import { useEffect, useState } from 'react'
import { getAllToggles } from '../services/remote-config'

export const useToggle = () => {
  const [stateToggle, setStateToggle] = useState({})

  const startToggle = () => {
    const remoteToggles = getAllToggles()
    setStateToggle(remoteToggles)
  }

  const stopToggle = () => setStateToggle({})

  useEffect(() => {
    startToggle()
    return stopToggle
    // eslint-disable-next-line
  }, []);

  return stateToggle
}
