import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(({ palette }) => ({
  middleTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  middleTextBorder: {
    borderBottom: `1px solid ${palette.divider}`,
    width: '100%',
  },
  middleTextContent: {
    padding: '0 10px 0 10px',
  },
}))
