import { Interface, type } from 'implement-js'

export const MainModeInterface = Interface('MainMode')(
  {
    paths: type('object'),
    layout: type('object'),
    providers: type('object'),
    styles: type('function'),
  },
  { error: true },
)
