export default {
  digitalStoreName: 'Tienda online',
  digitalStoreStatus: 'Status de tienda online',
  ownDigitalStore: 'Mi espacio digital',
  digitalStoreUrl: 'www.natura.com.pe/consultoria/',
  active: 'Activa',
  ceased: 'Inactiva',
  deniedBankAccount: 'Registro en Mercado Pago rechazado',
  deniedPaymentAccount: 'Registro en Mercado Pago rechazado',
  notStarted: 'Apertura de tienda no solicitada',
  pending: 'Solicitud en proceso',
  pendingAgreements: 'Pendiente aceptar términos de Venta online',
  pendingBankAccount: 'Pendiente activación de Mercado Pago',
  pendingFranchiseAgreement: 'Activa',
  pendingPaymentAccount: 'Pendiente activación de Mercado Pago',
  suspended: 'Bloqueada',
  unableToSell: 'Pendiente primer pedido en venta presencial',
  withSale: 'Activa',
}
