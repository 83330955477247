import { makeStyles } from '@naturacosmeticos/natds-web'

export const useStyles = makeStyles(() => ({
  sendLinkButtonContainer: {
    minWidth: '165px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
  progressorIndicatorContainer: {
    alignSelf: 'center',
  },
}))
