import { getBffApiGatewayConnection } from './config'
import { statusOptions } from '../models/filterOptions'

export const getSubStatusService = async (status, query, cancelToken) => {
  if (!status) {
    throw new Error('Invalid Status')
  }

  const connection = await getBffApiGatewayConnection()

  const mapQueryParams = (status, filter) => {
    if (status === statusOptions.allRegisters && filter) {
      return `?filter=${filter}`
    }
    return ''
  }

  const response = await connection.get(
    `/accounts/register-reports/v1/status/${status}${mapQueryParams(
      status,
      query,
    )}`,
    { cancelToken },
  )

  return response.data
}
