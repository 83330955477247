import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  loadingWrapper: {
    width: '100%',
    height: '1vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
  },
}))
