import React from 'react'
import {
  Checkbox as DSCheckBox,
  ListItem as DSListItem,
  Spacing as DSSpacing,
  Typography as DSTypography,
} from '@naturacosmeticos/natds-web'
import { Icon } from '../../../components/Icon'
import { useStyles } from './CountrySelectionItem.styles'

const CountrySelectionItem = ({
 onSelect, locale, label, isChecked,
}) => {
  const classes = useStyles()
  const flag = `flags_${locale}`

    return (
      <DSSpacing marginBottom="small" marginTop="small">
        <DSListItem onClick={onSelect} data-testid="list-item-id">
          <Icon name={flag} className={classes.icon} width={50} height={50} data-testid="icon" />
          <DSTypography className={classes.label}>{label}</DSTypography>
          <DSCheckBox className={classes.checkbox} checked={isChecked} color="primary" data-testid="checkbox" />
        </DSListItem>
      </DSSpacing>
    )
  }

export default CountrySelectionItem
