const topOfSet = '70px'

export default (theme) => ({
  prospectTableListLoadingContainer: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  prospectTableListEmptyListContainer: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.grey[400],
  },
  prospectTableListError: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  drawerFiltersPaper: {
    top: topOfSet,
    height: `calc(100% - ${topOfSet})`,
  },
})
