import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export default useStyles
