export function setObjectInStorage(key, value) {
    const formatValue = JSON.stringify(value)
    localStorage.setItem(key, formatValue)
}

export function setStringInStorage(key, value) {
    localStorage.setItem(key, value)
}

export function getObjectInStorage(key) {
    const value = localStorage.getItem(key)
    if (value !== undefined && value !== '' && value !== 'undefined') {
        return JSON.parse(value)
    }
    return {}
}

export function getStringInStorage(key) {
    return localStorage.getItem(key)
}

export function removeItemInStorage(key) {
    localStorage.removeItem(key)
}
